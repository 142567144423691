import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../api_conf/BaseUrl";
import { addCard } from "../../../store/designCardSlice";
import CheckSecondary from "../../../assets/images/check_secondary.svg";

const ChooseCard = ({ mutliSelect = false }) => {
  const dispatch = useDispatch();
  const [card, setCard] = useState([]);
  const newCard = useSelector((state) => state.designCard.newCard);

  const handleAddCard = (item) => {
    console.log(item)
    if (!card.some((currElem) => currElem.id === item.id)) {
      if (!mutliSelect) {
        setCard([item]);
        dispatch(addCard(item));
      } else if (mutliSelect) {
        setCard([...card, item]);
      }
    } else {
      let cardAfterRemoval = card;
      cardAfterRemoval = cardAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );

      setCard([...cardAfterRemoval]);
      dispatch(addCard(...cardAfterRemoval));
    }
  };

  const cardSelected = (item) => {
    if (card.find((currElem) => currElem.id === item.id)) {
      return true;
    }
    return false;
  };

  return (
    <div className="choose_card_container">
      {newCard.map((currElem) => (
        <div className="choose_card_images_container">
          <div
            className="choose_card_images"
            key={currElem.id}
            onClick={() => handleAddCard(currElem)}
          >
            {cardSelected(currElem) && (
              <div className="choose_card_btn">
                <img
                  className="choose_card_btn_img"
                  src={CheckSecondary}
                  alt="Choose card"
                />
              </div>
            )}

            <img
              className="choose_card_image"
              src={axios.defaults.devMediaUrl + currElem.image}
              alt={currElem.category}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChooseCard;
