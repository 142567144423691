import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

import { styles } from "./CampaignStartUtil/selectStyles";
import { validateCustomizeSettings } from "./CampaignStartUtil/ValidateCustomizeSettings";
import { helperIconStyles } from "./CampaignStartUtil/helperIconStyles";
import Info from "../../../assets/images/info.svg";

const CampaignStart = ({
  FormTitles,
  page,
  subTypeIdOptions,
  formData,
  setFormData,
  setIsDisabled,
  showEditCampaign,
  campaign
}) => {
  const [campainNameHelp, setCampaignNameHelp] = useState(false);
  const [endDateHelp, setEndDateHelp] = useState(false);
  const [subTypeHelp, setSubTypeHelp] = useState(false);
  const [giftExpirationHelp, setGiftExpirationHelp] = useState(false);
  const [frequencylReminderHelp, setfrequencyReminderHelp] = useState(false);
  const [estimatedBudgetHelp, setEstimatedBudgetHelp] = useState(false);
  const [showEndIcon, setShowEndIcon] = useState(true);
  const [clearEndDisabled, setClearEndDisabled] = useState(false);

  useEffect(() => {
    if (!showEditCampaign) {
      validateCustomizeSettings(formData, setIsDisabled,);
    }
  }, [formData]);

  const onCampaignNameHover = () => {
    setCampaignNameHelp(true);
  };

  const onCampaignNameLeave = () => {
    setCampaignNameHelp(false);
  };

  const onEndDateHover = () => {
    setEndDateHelp(true);
  };

  const onEndDateLeave = () => {
    setEndDateHelp(false);
  };

  const onGiftExpirationHover = () => {
    setGiftExpirationHelp(true);
  };

  const onGiftExpirationLeave = () => {
    setGiftExpirationHelp(false);
  };

  const onReminderHover = () => {
    setfrequencyReminderHelp(true);
  };

  const onReminderLeave = () => {
    setfrequencyReminderHelp(false);
  };

  const onSubTypeIdHover = () => {
    setSubTypeHelp(true);
  };
  const onSubTypeIdLeave = () => {
    setSubTypeHelp(false);
  };

  const onEstimatedBudgetHover = () => {
    setEstimatedBudgetHelp(true);
  };
  const onEstimatedBudgetLeave = () => {
    setEstimatedBudgetHelp(false);
  };

  const handleEndDate = (date) => {
    if (date?.length !== 0) {
      setClearEndDisabled(true);
    }
    setFormData({ ...formData, campaignEndDate: date });
  };

  const handleGiftExpirationDays = (e) => {
    if (e.target.value < 0 || e.target.value === "-") return;

    setFormData({
      ...formData,
      giftExpirationDays: e.target.value
    });
  };

  const handleEmailFrequencyReminder = (e) => {
    if (e.target.value < 0 || e.target.value === "-") return;

    setFormData({
      ...formData,
      emailFrequencyReminder: e.target.value
    });
  };

  const handleEstimatedCost = (e) => {
    if (e.target.value < 0 || e.target.value === "-") return;
    setFormData({
      ...formData,
      estimatedCost: e.target.value
    });
  };

  return (
    <React.Fragment>
      <header className="add_campaign_header">
        <span className="add_campaign_title">
          <h5>{FormTitles[page]}</h5>
        </span>
      </header>
      <div className="add_campaign_details">
        <div className="customize_campaign_container">
          <div className="add_campaign_group">
            <div className="add_campaign_group_container">
              <label className="add_campaign_label __campaign_name_label">
                Name your campaign:
              </label>
              <input
                type="text"
                className="add_campaign_control __campaign_name_input"
                placeholder="Example Birthdays, 2-Year Anniversary, Just Because"
                value={formData.campaignName}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    campaignName: event.target.value
                  });
                }}
                required
              />
            </div>
            <img
              onMouseEnter={onCampaignNameHover}
              onMouseLeave={onCampaignNameLeave}
              className="personalize_helper_icon"
              style={helperIconStyles}
              src={Info}
              alt="Helper text"
            />
            <div className="customize_settings_helper">
              {campainNameHelp && (
                <div className="customize_helper_container">
                  <p className="customize_helper_text">
                    Birthdays, 2-Year Anniversary...
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="add_campaign_group">
            <div className="add_campaign_group_container">
              <label className="add_campaign_label __campaign_scheduleGift_label">
                Enable scheduled gift sends for this campaign?
              </label>
              <div className="campaign_select_group">
                <Select
                  className="campaign_select_input __campaign_scheduleGift_input"
                  styles={styles}
                  options={subTypeIdOptions}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  value={formData.subTypeId}
                  placeholder=""
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      subTypeId: event
                    });
                  }}
                  isClearable
                />
              </div>
            </div>
            <img
              onMouseEnter={onSubTypeIdHover}
              onMouseLeave={onSubTypeIdLeave}
              className="personalize_helper_icon"
              style={helperIconStyles}
              src={Info}
              alt="Helper text"
            />
            <div className="customize_settings_helper">
              {subTypeHelp && (
                <div className="customize_helper_container">
                  <p className="customize_helper_text">
                    Scheduled gifts means that gifts can be sent at a future
                    date in an automated and recurring fashion. For example,
                    birthday campaigns will automatically send a gift on a
                    user’s birth date. Work anniversary campaigns will
                    automatically send a gift on a user’s anniversary. If you
                    are doing a one-off send with no trigger dates, no need for
                    scheduled gifts
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="add_campaign_group">
            <div className="add_campaign_group_container">
              <label className="add_campaign_label __campaign_expiration_label">
                Desired gift expiration:
              </label>
              <input
                type="number"
                className="add_campaign_control __campaign_expiration_input"
                placeholder="# of days a recepient will have to choose a gift"
                value={formData.giftExpirationDays}
                onChange={handleGiftExpirationDays}
              />
            </div>
            <img
              onMouseEnter={onGiftExpirationHover}
              onMouseLeave={onGiftExpirationLeave}
              className="personalize_helper_icon"
              style={helperIconStyles}
              src={Info}
              alt="Helper text"
            />

            <div className="customize_settings_helper">
              {giftExpirationHelp && (
                <div className="customize_helper_container">
                  <div className="customize_helper_text">
                    After expiration, reminder emails will stop going out and
                    your recipient will no longer be able to access their
                    landing page to claim a gift.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="add_campaign_group">
            <div className="add_campaign_group_container">
              <label className="add_campaign_label __campaign_frequency_label">
                How often do you want reminder emails to go out?
              </label>
              <input
                type="number"
                className="add_campaign_control __campaign_frequency_input"
                placeholder="Every X # of days"
                value={formData.emailFrequencyReminder}
                onChange={handleEmailFrequencyReminder}
              />
            </div>
            <img
              onMouseEnter={onReminderHover}
              onMouseLeave={onReminderLeave}
              className="personalize_helper_icon"
              style={helperIconStyles}
              src={Info}
              alt="Helper text"
            />
            <div className="customize_settings_helper">
              {frequencylReminderHelp && (
                <div className="customize_helper_container">
                  <div className="customize_helper_text">
                    Recipients who haven’t chosen their gift within the
                    expiration timeframe will receive a reminder email at the
                    interval you specify.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="add_campaign_group">
            <div className="add_campaign_group_container">
              <label className="add_campaign_label __campaign_expiry_label">
                {" "}
                When do you want this gift campaign to expire?
              </label>
              <DatePicker
                selected={formData.campaignEndDate}
                onChange={handleEndDate}
                className="add_campaign_control __campaign_expiry_input"
                placeholderText="Gift can't be sent out after this date"
                dateFormat="MM/dd/yyyy"
                minDate={new Date()}
                showYearDropdown
                scrollableMonthYearDropdown
                isClearable={clearEndDisabled}
                onCalendarOpen={() => setShowEndIcon(false)}
                onCalendarClose={() => setShowEndIcon(true)}
              />
            </div>
            {showEndIcon && (
              <img
                onMouseEnter={onEndDateHover}
                onMouseLeave={onEndDateLeave}
                className="personalize_helper_icon"
                style={helperIconStyles}
                src={Info}
                alt="Helper text"
              />
            )}
            <div className="customize_settings_helper">
              {endDateHelp && (
                <div className="customize_helper_container">
                  <div className="customize_helper_text">
                    You won’t be able to send gifts from an expired campaign. We
                    recommend 1 year at least for recurring campaigns
                    (birthdays, anniversaries) and at least 1 month for
                    one-offs.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="add_campaign_group">
            <div
              className="add_campaign_group_container"
              style={{ width: "100%" }}
            >
              <label className="add_campaign_label __campaign_budget_label">
                Estimated budget for this campaign:
              </label>
              <span className="estimated_budget_wrap __campaign_budget_input">
                <span className="estimated_budget_currency">
                  $ {showEditCampaign && campaign.total_budget}
                </span>
                <input
                  type="number"
                  className="add_campaign_control "
                  placeholder="Example 5000 or 10000"
                  value={formData.estimatedCost}
                  onChange={handleEstimatedCost}
                />
              </span>
            </div>
            <img
              onMouseEnter={onEstimatedBudgetHover}
              onMouseLeave={onEstimatedBudgetLeave}
              className="personalize_helper_icon"
              style={helperIconStyles}
              src={Info}
              alt="Helper text"
            />

            <div className="customize_settings_helper">
              {estimatedBudgetHelp && (
                <div className="customize_helper_container">
                  <div className="customize_helper_text">
                    Estimated budget: This is the total budget you want to
                    allocate for this campaign i.e. estimated # of recipients x
                    budget per person. You can always increase this from your
                    dashboard.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignStart;
