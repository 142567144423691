import React, { createContext, useEffect, useState } from "react";
import dayjs from "dayjs";

export const GlobalContext = createContext({
  monthIndex: 1,
  setMonthIndex: (index) => {},
  yearIndex: 1,
  setYearIndex: (index) => {},
  smallCalendarMonth: 0,
  setSmallCalendarMonth: (index) => {},
  daySelected: null,
  setDaySelected: (day) => {},
  showEventModal: false,
  setShowEventModal: () => {},
  selectedEvent: null,
  setSelectedEvent: () => {},
  scheduledDate: null,
  setScheduledDate: () => {},
  scheduledResponse: "",
  setScheduledResponse: () => {},
  totalCostResponse: 0,
  setTotalCostResponse: () => {},
  id: null,
  setId: () => {},
  showDeleteModal: false,
  setShowDeleteModal: () => {},

  giftTransitStatus: [],
  setgiftTransitStatus: () => {},
  filteredArray: [],
  setFilteredArray: () => {},
  selectedtabId: 1,
  setselectedtabId: () => {},
  loading: false,
  setLoading: () => {}
});

export const GlobalProvider = ({ children }) => {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [yearIndex, setYearIndex] = useState(dayjs().year());

  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledResponse, setScheduledResponse] = useState("");
  const [totalCostResponse, setTotalCostResponse] = useState(0);
  const [id, setId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [giftTransitStatus, setgiftTransitStatus] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [selectedtabId, setselectedtabId] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
    setMonthIndex(smallCalendarMonth);
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        yearIndex,
        setYearIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        selectedEvent,
        setSelectedEvent,
        scheduledDate,
        setScheduledDate,
        scheduledResponse,
        setScheduledResponse,
        totalCostResponse,
        setTotalCostResponse,
        id,
        setId,
        showDeleteModal,
        setShowDeleteModal,

        giftTransitStatus,
        setgiftTransitStatus,
        filteredArray,
        setFilteredArray,
        selectedtabId,
        setselectedtabId,
        loading,
        setLoading
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
