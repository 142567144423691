import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./assets/fonts/Aji_Hand.ttf";
import "./App.scss";

import Login from "./Pages/Login/Login";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "./Reusables/CustomModal/CustomModal";

import axios from "./api_conf/BaseUrl";
import API from "./api_conf/ApiUrls";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { handleSignOut } from "./store/toolkit";
import Users from "./Pages/Users/Users";
import SideBar from "./Pages/SideBar/sideBar";
import Campaign from "./Pages/Campaign/Campaign";
import Home from "./Pages/Home/Home";
import Profile from "./Pages/Profile/Profile";
import OutBoundEmail from "./Pages/OutboundEmail/OutBoundEmail";
import NotFound from "./Pages/NotFound/NotFound";
import ShowGift from "./Pages/ShowGift/ShowGift";

export default function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [showRegistration, setshowRegistration] = useState(null);
  const [showForgotPasswordModal, setshowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setforgotPasswordEmail] = useState("");
  const [registrationName, setregistrationName] = useState("");
  const [registrationEmail, setregistrationEmail] = useState("");
  const [registrationContact, setregistrationContact] = useState("");
  const [registrationEmpStrength, setregistrationEmpStrength] = useState("");
  const LoggedIn = useSelector((state) => state.user.loggedIn);
  const [userInfo, setUserInfo] = useState({
    organization_name: "Loadin ...",
    organization_email: "",
    organization_contact: "",
    organization_strength: "",
    monthly_gift_claimed: "",
    monthly_gift_sent: "",
    monthly_gift_notes: "",
    total_gift_claimed: "",
    total_gift_notes: "",
    total_gift_sent: ""
  });

  const [giftInfo, setGiftInfo] = useState({
    monthlyGiftClaimed: "",
    monthlyGiftSent: "",
    monthlyGiftNotes: "",
    totalGiftClaimed: "",
    totalGiftNotes: "",
    totalGiftSent: "",
    spentMonth:"",
    totalSpent:""
  });

  // useEffect(() => {}, [state]);

  useEffect(() => {
    axios
      .get(API.DASHBOARD)
      .then((result) => {
        if (result.data.success) {
          const user = {
            organization_name: result.data.response[0].organization_name,
            organization_email: result.data.response[0].organization_email,
            organization_contact: result.data.response[0].organization_contact,
            organization_strength: result.data.response[0].emp_strength
          };
          setGiftInfo({
            monthlyGiftClaimed: result.data.gift_claimed_month,
            monthlyGiftSent: result.data.gift_sent_month,
            monthlyGiftNotes: result.data.this_month_gift_notes,
            totalGiftClaimed: result.data.total_gift_claimed,
            totalGiftNotes: result.data.total_gift_notes,
            totalGiftSent: result.data.total_gift_sent,
            spentMonth:result.data.spent_month,
            totalSpent:result.data.Total_spent
          });
          setUserInfo(user);
          // setMeetingLink(result.data.response[0].meeting_link);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.detail === "Signature has expired.") {
            localStorage.clear();
          }
          if (error.response.status === 401) {
            dispatch(handleSignOut());
            localStorage.clear();
            <Navigate to="/login" />;
          }
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  }, [LoggedIn]);

  useEffect(() => {
    if (LoggedIn) {
      setisLoggedIn(true);
    } else {
      setisLoggedIn(false);
    }
  }, [LoggedIn]);

  const openRegistrationFormHandler = (view) => {
    setshowRegistration(view);
    setshowForgotPasswordModal(false);
  };

  const openForgotPasswordFormhandler = (view) => {
    setshowRegistration(false);
    setshowForgotPasswordModal(view);
  };

  const closeModalHandler = () => {
    setshowRegistration(false);
    setshowForgotPasswordModal(false);
    setforgotPasswordEmail("");
    setregistrationName("");
    setregistrationContact("");
    setregistrationEmpStrength(0);
  };

  const okButtonActionHandler = () => {
    if (showRegistration) {
      registrationHandler();
    } else if (showForgotPasswordModal) {
      forgotPasswordHandler();
    }
  };

  const registrationHandler = () => {
    if (!registrationName || !registrationEmail) {
      toast.error("All fields are mandatory!");
      return;
    }
    axios
      .post(API.REQUEST_SIGNUP, {
        name: registrationName,
        email: registrationEmail,
        contact: registrationContact,
        emp_strength: registrationEmpStrength
      })
      .then((result) => {
        toast.success(result.data.message);
        if (result.data.success) {
          closeModalHandler();
        }
      })
      .catch((error) => {
        localStorage.removeItem("flex_id");
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  const forgotPasswordHandler = () => {
    if (!forgotPasswordEmail) {
      toast.error("Please provide a valid email.");
      return;
    }
    axios
      .post(API.FORGOT_PASSWORD, {
        email: forgotPasswordEmail
      })
      .then((result) => {
        toast.success(result.data.message);
        if (result.data.success) {
          closeModalHandler();
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
        // closeModalHandler();
      });
  };

  let modalTitle = "Send request for your organization";
  let modalSubTitle =
    "Please provide following information in order to send request";
  let modalContent = (
    <div>
      <form>
        <div className="form_control ">
          <label className="required-field"> Name </label>
          <input
            type="text"
            onChange={(event) => {
              setregistrationName(event.target.value);
            }}
            required
          />
        </div>

        <div className="form_control">
          <label className="required-field"> Email </label>
          <input
            type="text"
            onChange={(event) => {
              setregistrationEmail(event.target.value);
            }}
            required
          />
        </div>

        <div className="form_control">
          <label> Contact Number </label>
          <input
            type="text"
            onChange={(event) => {
              setregistrationContact(event.target.value);
            }}
          />
        </div>
      </form>
    </div>
  );
  let modalOkButtonTitle = "Send Request";
  if (showForgotPasswordModal) {
    modalTitle = "Forgot your password ?";
    modalSubTitle =
      "Drop in your email so we can send your password as a reminder.";
    modalContent = (
      <div>
        <form>
          <div className="form_control">
            <input
              style={{border:"1px solid #A8B8BF",color: "#A8B8BF",fontWeight:"400",fontSize:"14px"}}
              type="text"
              placeholder="Type in the email associated with your account"
              onChange={(event) => {
                setforgotPasswordEmail(event.target.value);
              }}
            />
          </div>
        </form>
      </div>
    );
    modalOkButtonTitle = "Recover Account";
  }

  return (
    <div className="App">
      <CustomModal
        show={showRegistration || showForgotPasswordModal}
        title={modalTitle}
        subTitle={modalSubTitle}
        escapeModal={closeModalHandler}
        okButtonTitle={modalOkButtonTitle}
        okButtonAction={okButtonActionHandler}
      >
        {modalContent}
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {isLoggedIn ? (
        <>
          <Routes>
            {["/", "/dashboard"].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={
                  <div className="Dashboard_Container" key={index}>
                    <div className="layout_wrapper">
                      <SideBar />
                      <div className="main_home_container">
                        <Home loggedInUser={userInfo} giftInfo={giftInfo} />
                      </div>
                    </div>
                  </div>
                }
              />
            ))}

            <Route
              path="/dashboard/campaign/:id"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <Campaign />
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              path="/dashboard/campaign/:id/outboundTemplate"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <OutBoundEmail />
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              path="/users"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <Users />
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              path="/dashboard/campaign/:id/show-gift"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <ShowGift />
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              path="/profile"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <Profile />
                    </div>
                  </div>
                </div>
              }
            />

            <Route
              path="*"
              element={
                <div className="Dashboard_Container">
                  <div className="layout_wrapper">
                    <SideBar />
                    <div className="main_conatiner">
                      <NotFound />
                    </div>
                  </div>
                </div>
              }
            />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            {["/", "/login"].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={
                  <Login
                    onRegister={openRegistrationFormHandler}
                    onForgotPassword={openForgotPasswordFormhandler}
                  />
                }
              />
            ))}

            {localStorage.getItem("token") ? null : (
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
        </>
      )}
    </div>
  );
}
