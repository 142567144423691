import React, { useState } from "react";
import DesignFilter from "./DesignFilter";

import ChooseCard from "./ChooseCard";
import CardMessage from "./CardMessage";
import ChooseBackground from "./ChooseBackground";
import ChooseEnvelope from "./ChooseEnvelope";

import CloseIcon from "../../../assets/images/chevron-up-secondary.svg";
import OpenIcon from "../../../assets/images/chevron-down-secondary.svg";
import TickPrimary from "../../../assets/images/check_primary.svg";
import TickGray from "../../../assets/images/check_gray.svg";
import ToggleButton from "./ToggleButton";

const imageStyles = {
  height: "20px",
  width: "20px"
};

const DesignCardFilter = ({ cardMessage, setCardMessage, messageRef }) => {
  const designCardFilters = [
    {
      id: 1,
      filter: "Card",
      isOpened: <img style={imageStyles} src={CloseIcon} alt="Close filter" />,
      isClosed: <img style={imageStyles} src={OpenIcon} alt="Open filter" />,
      component: <ChooseCard />
    },
    {
      id: 2,
      filter: "Background",
      isOpened: <img style={imageStyles} src={CloseIcon} alt="Close filter" />,
      isClosed: <img style={imageStyles} src={OpenIcon} alt="Open filter" />,
      component: <ChooseBackground />
    },
    {
      id: 3,
      filter: "Envelope Color",
      isOpened: <img style={imageStyles} src={CloseIcon} alt="Close filter" />,
      isClosed: <img style={imageStyles} src={OpenIcon} alt="Open filter" />,
      component: <ChooseEnvelope />
    },
    {
      id: 4,
      filter: "Message",
      isOpened: <img style={imageStyles} src={CloseIcon} alt="Close filter" />,
      isClosed: <img style={imageStyles} src={OpenIcon} alt="Open filter" />,
      component: (
        <CardMessage
          cardMessage={cardMessage}
          setCardMessage={setCardMessage}
          messageRef={messageRef}
        />
      )
    },
    {
      id: 5,
      filter: "Logo",
      toggle: <ToggleButton />,
      isSelected: (
        <img style={imageStyles} src={TickPrimary} alt="Logo selected" />
      ),
      isNotSelected: (
        <img style={imageStyles} src={TickGray} alt="Logo not selected" />
      )
    }
  ];

  const [data, setData] = useState(designCardFilters);
  console.log("hello",data.component);
  return (
    <React.Fragment>
      <div className="design_main_filter">
        {data.map((item) => {
          const {
            id,
            filter,
            isOpened,
            isClosed,
            isSelected,
            isNotSelected,
            component,
            toggle
          } = item;
          return (
            <DesignFilter
              key={id}
              id={id}
              filter={filter}
              isOpened={isOpened}
              isClosed={isClosed}
              isSelected={isSelected}
              isNotSelected={isNotSelected}
              component={component}
              toggle={toggle}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DesignCardFilter;
