import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { clearGifts } from "../../store/giftSlice";
import { clearPersonalizeEmail } from "../../store/personalizeEmailSlice";
import { clearDesignCard } from "../../store/designCardSlice";

import Container from "react-bootstrap/Container";
import AddCampaign from "./AddCampaign";
import AddIcon from "../../assets/images/add.svg";
import "./AddCampaign.scss";

export default function AddCampaignButton({ getCampaignsApiCall }) {
  const dispatch = useDispatch();
  const [showAddCampaign, setShowAddCampaign] = useState(false);

  // To clear each steps data
  const clearCurateGiftsData = () => dispatch(clearGifts());
  const clearPersonalizedEmailData = () => dispatch(clearPersonalizeEmail());
  const clearDesignCardData = () => dispatch(clearDesignCard());
  const handleShow = () => {
    setShowAddCampaign(true);
    clearCurateGiftsData();
    clearPersonalizedEmailData();
    clearDesignCardData();
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      <Container className="add_campaign_container">
        <button className="add_campaign_button" onClick={handleShow}>
          <img
            src={AddIcon}
            alt="Add Campaign"
            className="add_campaign_button_img"
          />
          Create Campaign
        </button>
      </Container>
      {showAddCampaign ? (
        <AddCampaign
          getCampaignsApiCall={getCampaignsApiCall}
          handleClose={() => {
            setShowAddCampaign(false);
            clearCurateGiftsData();
            clearPersonalizedEmailData();
            clearDesignCardData();
            document.body.style.overflow = "visible";
          }}
        />
      ) : null}
    </>
  );
}
