import React from "react";
import Error404 from "../../assets/images/error-404.svg";

function NotFound() {
  return (
    <div>
      <img
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10rem auto",
          alignItems: "center",
          height: "600px",
          width: "600px",
        }}
        src={Error404}
        alt="Error 404 | Page not found"
      />
    </div>
  );
}

export default NotFound;
