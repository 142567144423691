import React,{useEffect,useState,useRef} from 'react'
import { toast } from 'react-toastify';
import axios from "../../api_conf/BaseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Edit from "../../assets/images/edit.svg";
import './Frequency.css';
const EmailFrequencyCard = (props) => {

    const [seven,setSeven] = useState(false);
     const [fortin,setFortin] = useState(false);
     const [thirty,setThirty] = useState(false);
     const [custom,setCustom] = useState(false);

     const [customValue,setCustomValue] = useState("");

     const [saveicon,setSaveIcon] = useState(true);
     const [editable,setEditable] = useState(false);

     const UseFocus = () => {
      const htmlElRef = useRef(null)
      const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()
        setEditable(true);
      }
      return [ htmlElRef,  setFocus ] 
    }

     const [inputRef, setInputFocus] = UseFocus()

    const handleEmailExpirationS = () => {
        setSeven(true);
        setFortin(false);
        setThirty(false);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,email_reminder_frequency:"7"}).then((res) => {
          setCustomValue("07");
          toast.success("Saved Email Reminder Successfully")
          //toast.success(res.data.message);
        })
    }

    const handleEmailExpirationM = () => {
        setSeven(false);
        setFortin(true);
        setThirty(false);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,email_reminder_frequency:"14"}).then((res) => {
          setCustomValue("14");
          toast.success("Saved Email Reminder Successfully")
         // toast.success(res.data.message);
        })
    }
    const handleEmailExpirationL = () => {
        setSeven(false);
        setFortin(false);
        setThirty(true);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,email_reminder_frequency:"30"}).then((res) => {
          setCustomValue("30");
          toast.success("Saved Email Reminder Successfully")
          //toast.success(res.data.message);
        })
    }
    const handleEmailExpirationCu = () => {
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,email_reminder_frequency:customValue}).then((res) => {
          toast.success("Saved Email Reminder Successfully")
          setSaveIcon(false);
          setEditable(false);
          //toast.success(res.data.message);
        })
    }

    const handleEmailExpirationXl = () => {
        setSeven(false);
        setFortin(false);
        setThirty(false);
        setCustom(true);
        setSaveIcon(false);
    }
     
    useEffect(() => {
        if(props.emailFrequency=="7"){
          setSeven(true);
          setCustomValue("07");
        }else if(props.emailFrequency=="14"){
          setFortin(true);
          setCustomValue("14");
        }else if(props.emailFrequency=="30"){
          setThirty(true);
          setCustomValue("30");
        }else{
          setCustom(true);
          setSaveIcon(false);
          setCustomValue(props.emailFrequency);
        }
    },[])
  return (
    <div>
         <div>
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#023047",
                  }}
                >
                  Email Reminder Frequency
                </div>

                <div
                  style={seven?{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    background: "#219EBC",
                    color: "#FFFFFF",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }:{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    color: "#219EBC",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }}

                  onClick={handleEmailExpirationS}
                >
                  Every 7 Days
                </div>

                <div
                  style={fortin?{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    background: "#219EBC",
                    color: "#FFFFFF",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }:{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    color: "#219EBC",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }}
                  onClick={handleEmailExpirationM}
                >
                  Every 14 Days
                </div>

                <div
                  style={thirty?{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    background: "#219EBC",
                    color: "#FFFFFF",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }:{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    color: "#219EBC",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }}
                  onClick={handleEmailExpirationL}
                >
                  Every 30 Days
                </div>
              {custom?<div style={{display:"flex",width:'60%',height:"20px",margin:"auto",marginTop:"25px",justifyContent:"center",alignItems:'center',gap:"3px"}}>
            <input ref={inputRef} readOnly={editable?false:true} style={{width:"100px",fontWeight: "700",fontSize: "32px",lineHeight: "32px", color: "#219EBC",outline:"none",border:"none",textAlign:"right"}} type="number" placeholder='Days' value={customValue}  onChange={(e)=>{setCustomValue(e.target.value.toString())
            setSaveIcon(true);}} />
            <p style={{fontWeight: "400",
            fontSize: "12px",
            lineHeight: "14px",
            color: "#219EBC",
            marginTop:"20px"
           }}>days</p>
            {saveicon?<FontAwesomeIcon style={{cursor:"pointer",margin:"0px 0px 0px 5px",color:"#219EBC",width:"20px",height:"20px"}} onClick={handleEmailExpirationCu} icon={faSave} />:<img style={{cursor:"pointer",marginLeft:"5px",color:"#219EBC",width:"20px",height:"20px"}} onClick={setInputFocus} src={Edit} alt="Edit Icon" />}
        </div>:<div
                  style={custom?{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    background: "#219EBC",
                    color: "#FFFFFF",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }:{
                    fontWeight: "500",
                    fontSize: "11px",
                    lineHeight: "14px",
                    color: "#219EBC",
                    border: "1px solid #219EBC",
                    padding: "8px 42px",
                    width: "90%",
                    margin: "12px auto 12px auto",
                    cursor:"pointer"
                  }}
                  onClick={handleEmailExpirationXl}
                >
                  Custom
                </div>}
              {/* {custom?
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#219EBC",
                    margin:"12px auto 12px auto",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "32px",
                      lineHeight: "32px",
                      color: "#219EBC",
                      margin: "0px 0px 0px 5px",
                    }}
                  >
                    {customValue}
                  </span>
                  {customValue!==""?"Days":""}
                </div>:""} */}
              </div>
    </div>
  )
}

export default EmailFrequencyCard