import React from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { handleSignOut } from "../../../store/toolkit";

import GiftIMG from "../../../assets/images/gift.svg";
import CampaignHeaderIcon from "../../../assets/images/campaign_header_chevron.svg";

const Header = (props) => {
  const navigate = useNavigate();
  const { handleShowModal, campaign_name, campaign_type, campaign_sub_type } =
    props;

  const dispatch = useDispatch();

  const styles = {
    opacity: "0",
    pointerEvent: "none"
  };

  return (
    <React.Fragment>
      <div className="header__flex-container ">
        <div
          className="header__flex-item header__flex-item-mobile"
          style={styles}
        >
          <button className="gift_btn" onClick={handleShowModal}>
            <img
              src={GiftIMG}
              alt=""
              style={{ boxShadow: "inset 1px 0px 3px #FFB7B7" }}
            />
            Send Gift
          </button>
        </div>

        <div
          className="header__flex-item header__flex-item-mobile"
          style={styles}
        >
          <h5 className="header_content_heading  header_content_heading_mobile">
            {campaign_name}
          </h5>
          <h5 className="header_content_heading  header_content_heading_mobile">
            <img src={CampaignHeaderIcon} alt="Campaign Type" />
            Type - {campaign_type}
          </h5>
          <h5 className="header_content_heading  header_content_heading_mobile">
            <img src={CampaignHeaderIcon} alt="Campaign Type" /> Sub Type -{" "}
            {campaign_sub_type}
          </h5>
        </div>
        <div className="header__flex-item header__flex-item-mobile">
          <button
            className="sign"
            onClick={() => {
              dispatch(handleSignOut());
              localStorage.clear();
              navigate("/");
            }}
          >
            sign out
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
