import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./TemplatePreview.module.scss";
import axios from "../../../../api_conf/BaseUrl";

import close from "../../../../assets/images/close_envelope_secondary.svg";

const TemplatePreview = ({
  image,
  setShowPreview,
  cardItem,
  envelopeColor,
  backgroundColor,
  textColor,
  textSize,
  letterSpacing,
  lineHeight,
  alignment,
  letterCasing,
  cardFontFamily,
  cardMessage
}) => {
  const logoSelected = useSelector((state) => state.designCard.logoSelected);
  const previewLogo = useSelector((state) => state.designCard.previewLogo);
  
  const handleShowPreview = () => {
    setShowPreview(false);
  };

  const sampleTemplate =
    "https://images.pexels.com/photos/1037997/pexels-photo-1037997.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940";

  return (
    <div
      className={styles.design_card_main_container}
      style={{
        background: `${backgroundColor} url(${
          axios.defaults.devMediaUrl + image
        }) no-repeat center center/cover`
      }}
    >
      <button
        className={styles.template_preview_close_btn}
        onClick={handleShowPreview}
      >
        <img src={close} alt="Close preview" />
      </button>
      <div className={styles.design_card_envelope_container}>
        <svg
          width="850"
          height="850"
          viewBox="0 0 850 850"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id={styles.designTemplate}>
            <path
              id={styles.lid1}
              d="M304.182 17.5932L60.5962 354.315C53.7081 363.837 50 375.289 50 387.041C50 394.75 56.2496 401 63.9589 401H573C581.837 401 589 393.837 589 385V383.9C589 370.045 584.504 356.565 576.188 345.484L329.942 17.3671C323.472 8.74527 310.5 8.85911 304.182 17.5932Z"
              fill={envelopeColor}
            />

            <path
              id={styles.lid1layer}
              d="M86 401H557L320 74L86 401Z"
              fill="url(#paint0_linear_1_2)"
            />

            <path
              id={styles.envelope_cover}
              d="M557.976 400H87L157.47 500H486.331L557.976 400Z"
              fill="#fff"
            />

            <image
              id={styles.letter1}
              d="M159 221H488V525H159V221Z"
              style={{
                objectFit: "contain",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
              href={
                cardItem?.image !== undefined
                  ? axios.defaults.devMediaUrl + cardItem?.image
                  : sampleTemplate
              }
            />

            <path
              id={styles.envelope}
              d="M41.543 710.859C40.8751 683.364 40.562 603.626 42.1809 416.994C42.2571 408.213 49.3986 401 58.1792 401H88L156.5 499H487L556.5 401H583.052C591.869 401 599.024 408.133 599.052 416.95L599.95 706.771C599.978 715.627 592.806 722.821 583.95 722.821H54.7603C47.4435 722.821 41.7207 718.174 41.543 710.859Z"
              fill={envelopeColor}
            />

            <path
              id={styles.letter2}
              d="M159 221H488V525H159V221Z"
              fill="white"
              stroke-width="0.3"
              stroke="#000"
            />

            <g id={styles.letter_data}>
              <foreignObject
                x="-25"
                y="5"
                width="380"
                height="560"
                style={{ wordBreak: "break-all", display: "inline-block" }}
              >
                <div
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    width: "380px",
                    height: "500px"
                  }}
                >
                  <pre
                    style={{
                      color: textColor,
                      fontSize: textSize,
                      letterSpacing: letterSpacing,
                      lineHeight: lineHeight,
                      textAlign: alignment,
                      fontFamily: cardFontFamily,
                      overflow: "hidden",
                      overflowY: "none"
                    }}
                  >
                    {cardMessage}
                  </pre>
                </div>
                {logoSelected ? (
                  <div id={styles.preview_logo}>
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src={axios.defaults.devMediaUrl + previewLogo}
                      alt="Logo"
                      title="Logo"
                    />
                  </div>
                ) : (
                  ""
                )}
              </foreignObject>
            </g>

            <path
              id={styles.lid2layer}
              d="M86 402H557L320 662L86 402Z"
              fill="url(#paint0_linear_1_2)"
            />

            <path
              id={styles.lid2}
              d="M305.287 706.22L60.4139 436.938C53.7131 429.569 50 419.967 50 410.007C50 404.48 54.4804 400 60.0073 400H577.187C583.711 400 589 405.289 589 411.813C589 423.868 584.392 435.468 576.119 444.237L328.763 706.435C322.368 713.213 311.556 713.114 305.287 706.22Z"
              fill={envelopeColor}
              stroke-width="0.3"
              stroke="#000"
            />
          </g>

          <defs>
            <linearGradient
              id="paint0_linear_1_2"
              x1="333.224"
              y1="83.236"
              x2="333.224"
              y2="418.947"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.848958" stop-color="white" />
              <stop offset="1" stop-color="#D9D9D9" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default TemplatePreview;
