import CloseIcon from "../../../assets/images/chevron-up-primary.svg";
import OpenIcon from "../../../assets/images/chevron-down-primary.svg";

export const filters = [
  {
    id: 1,
    filter: "Price",
    isOpened: <img src={CloseIcon} alt="Close Filter" />,
    isClosed: <img src={OpenIcon} alt="Open Filter" />,
    value: [
      {
        id: 1,
        name: "< $25",
        path: "25/",
        condition: [0, 25]
      },
      {
        id: 2,
        name: "$26-$50",
        path: "26-50/",
        condition: [26, 50]
      },
      {
        id: 3,
        name: "$51-$75",
        path: "76-100/",
        condition: [51, 75]
      },
      {
        id: 4,
        name: "$76-$100",
        path: "76-100/",
        condition: [76, 100]
      },
      {
        id: 5,
        name: "$101+",
        path: "101/",
        condition: [101, 1000000]
      }
    ]
  },
  {
    id: 2,
    filter: "Values",
    isOpened: <img src={CloseIcon} alt="Close Filter" />,
    isClosed: <img src={OpenIcon} alt="Open Filter" />,
    value: [
      {
        id: 6,
        name: "Female-founded",
        path: "female-founded/",
        condition: "#femalefoundedvalue"
      },
      {
        id: 7,
        name: "BIPOC-owned",
        path: "bipoc-owned/",
        condition: "#bipocownedvalue"
      },
      {
        id: 8,
        name: "LGBTQ-owned",
        path: "lgbtqp-owned/",
        condition: "#lgbtqownedvalue"
      },
      {
        id: 9,
        name: "Gives Back",
        path: "gives-back/",
        condition: "#givesbackvalue"
      },
      {
        id: 10,
        name: "Sustainable",
        path: "sustainable/",
        condition: "#sustainablevalue"
      }
    ]
  },
  {
    id: 3,
    filter: "Ship to Country",
    isOpened: <img src={CloseIcon} alt="Close Filter" />,
    isClosed: <img src={OpenIcon} alt="Open Filter" />,
    value: [
      {
        id: 11,
        name: "USA",
        path: "usa/",
        condition: "#shiptoUSA"
      },
      {
        id: 12,
        name: "Canada",
        path: "canada/",
        condition: "#shiptoCA"
      },
      {
        id: 13,
        name: "UK",
        path: "uk/",
        condition: "#shiptoUK"
      },
      {
        id: 14,
        name: "EU",
        path: "eu/",
        condition: "#shiptoEU"
      },
      {
        id: 15,
        name: "Australia",
        path: "australia/",
        condition: "#shiptoAU"
      }
    ]
  },
  {
    id: 4,
    filter: "Collections",
    isOpened: <img src={CloseIcon} alt="Close Filter" />,
    isClosed: <img src={OpenIcon} alt="Open Filter" />,
    value: []
  },
  {
    id: 5,
    filter: "Gift Type",
    isOpened: <img src={CloseIcon} alt="Close Filter" />,
    isClosed: <img src={OpenIcon} alt="Open Filter" />,
    value: [
      {
        id: 16,
        name: "Physical",
        path: "physical/",
        condition: "#physicalgift"
      },
      {
        id: 17,
        name: "Digital Subscription",
        path: "digital-subscription/",
        condition: "#digitalsubscriptiongift"
      },
      {
        id: 18,
        name: "Physical Subscription",
        path: "physical-subscription/",
        condition: "#physicalsubscriptiongift"
      },
      {
        id: 19,
        name: "Gift Card",
        path: "gift-card/",
        condition: "#giftcardgift"
      }
    ]
  }
];
