import { createSlice } from "@reduxjs/toolkit";

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
const initialState = {
  users: {},
  loggedIn: false,
  userData: {},
  campaigns: [],
  currentActiveCampaign: null,
  campaignData: {},
  activeLink: "login",
  groups: [],
  notifications: 0
};
const random_hex_color_code = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
};

const UserSlice = createSlice({
  name: "Kaido",
  initialState,
  reducers: {
    saveUserData(state, action) {
      state.loggedIn = true;
      state.userData = {
        userEmail: action.payload.mail,
        token: action.payload.token
      };
      localStorage.setItem("token", action.payload.token);
    },

    changeActiveLink(state, action) {
      let activePage = action.payload.activePage;
      state.activeLink = activePage;
    },

    saveCampaignData(state, action) {
      state.campaigns = action.payload;
    },

    saveCampaignDataByID(state, action) {
      let campaign = action.payload;
      let arr = [];
      let labels = [];
      let colors = [];
      let giftCount = [];
      let tempArr = [];
      for (let i = 0; i < campaign.gifts.length; i++) {
        let id = campaign.gifts[i];

        let dat = {
          color: random_hex_color_code(),
          name: campaign.gifts[i],
          count: campaign.gift_chosen[id]
        };

        let giftDataArr = {
          labelName: dat.name,
          color: dat.color,
          count: dat.count
        };
        tempArr.push(giftDataArr);
        labels.push(dat.name);
        colors.push(dat.color);
        giftCount.push(dat.count ? dat.count : 0);
        const dataArr = {
          labels: labels,
          datasets: [
            {
              label: "# of Votes",
              data: giftCount,
              backgroundColor: colors,
              borderWidth: 0
            }
          ]
        };

        if (campaign.gifts.length === i + 1) {
          state.chartDataState = dataArr;
        }
        arr.push(dat);
      }
      state.giftListWithCount = arr;
    },

    saveUserListData(state, action) {
      state.users = action.payload;
    },
    handlePagination(state, action) {
      state.users.currentpage = action.payload;
    },
    getCampaignById(state, action) {
      state.currentActiveCampaign = action.payload.id;
    },
    handleSignOut(state) {
      localStorage.clear();
      state.userData = {};
      state.campaigns = [];
      state.activeLink = "login";
      state.loggedIn = false;
    },
    saveSelectedUser(state, action) {
      state.selectedUserList = action.payload;
    },
    handleNotificationsCount(state, action) {
      state.notifications = action.payload;
    }
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const {
  saveUserData,
  changeActiveLink,
  saveCampaignData,
  saveUserListData,
  handlePagination,
  getCampaignById,
  handleSignOut,
  saveSelectedUser,
  saveCampaignDataByID,
  handleNotificationsCount
} = UserSlice.actions;

export default UserSlice.reducer;
