import * as types from "./constants";
import axios from "../api_conf/BaseUrl";
import API from "../api_conf/ApiUrls";
import { toast } from "react-toastify";


export const routeScreen = (name = "", data = {}) => ({
  type: types.ROUTE_SCREEN,
  payload: { name, data },
});

export const fetchAllUsersbyPage = async (pageNumber = 1) => {
  const { data } = await axios.get(API.USERS + "?page=" + pageNumber);
  return data.response;
};

export const fetchGroups = async () => {
  const { data } = await axios.get(API.GROUPS);
  //console.log("hello1",data.response)
  data.response.unshift({id:0,name:"All"})
  return data;
};

export const postGroups = async (listName, refetch) => {
  try {
    const { data } = await axios.post(API.GROUPS, { name: listName });
    toast.success(data.message);
    return refetch();
  } catch (error) {
    console.error(error);
  }
};

export const getGroupListbyName = async (grpName) => {
  try {
    const { data } = await axios.get(
      `${API.USER_BY_LIST}?page=1&group_name=${grpName}`
    );
    const userList = data.response.map((user) => {
      return {
        firstName: user.first_name,
        lastName: user.last_name,
        company: user.organization_name,
        email: user.email,
        post: user.designation_id,
        date: new Date(user.created_at).toLocaleDateString(),
        id: user.id,
      };
    });
    return userList;
  } catch (error) {
    toast.error(error.message);
    console.error(error);
    return [];
  }
};
