import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { envelopeColors } from "./DesignCardUtil/EnvelopeUtil";
import {
  addEnvelopeColor,
  showEnvelopeColorPicker
} from "../../../store/designCardSlice";


const ChooseEnvelope = () => {
  const dispatch = useDispatch();
  const envelopeColor = useSelector((state) => state.designCard.envelopeColor);
  const handleEnvelopeColor = (color) => {
    dispatch(addEnvelopeColor(color));
  };

  const handleShowColorPicker = () => {
    dispatch(showEnvelopeColorPicker());
  };

  return (
    <React.Fragment>
      <div className="choose_envelope_container">
        <div className="choose_envelope_colors_container">
          {envelopeColors.map((currElem) => (
            <div
              className="choose_envelope_colors"
              style={{ backgroundColor: currElem.color }}
              onClick={() => handleEnvelopeColor(currElem.color)}
            >
              {" "}
            </div>
          ))}
        </div>
        <div className="choose_envelope_color_picker">
          Color:{" "}
          <span
            style={{ backgroundColor: envelopeColor }}
            onClick={handleShowColorPicker}
          ></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChooseEnvelope;
