import React, { useState, useEffect, lazy, Suspense } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { handleSignOut, saveCampaignDataByID } from "../../store/toolkit";
import { useLocation } from "react-router-dom";

import API from "../../api_conf/ApiUrls";
import axios from "../../api_conf/BaseUrl";
import { colorsArr } from "../../assets/colorsArr";
import "./Campaign.scss";

import SendGift from "../../assets/images/giftIcon.svg";
import GiftSentIcon from "../../assets/images/giftSent";
import LogoICON from "../../assets/images/Watermark";
import ErrorIcon from "../../assets/images/errorIcon";
import CancelIcon from '../../assets/images/cancelIcon.png';
import GiftIcon from '../../assets/images/gift_white.gif';

import ChartCardComponent from "./components/giftChartCard";
import GiftStatusTable from "./GiftStatusTable";

// Older imports (To be removed)

// New imports
import Header from "./components/Header";
import GiftProgress from "./components/GiftProgress/GiftProgress";
import CalendarCard from "./components/CalendarCard/CalendarCard";
import BudgetSpent from "./components/BudgetSpent/BudgetSpent";
import ThankyouNotes from "./components/ThankyouNotes/ThankyouNotes";
import FrequencyCard from "./GiftFrequencyCard";
import EmailFrequencyCard from "./EmailFrequencyCard";
import { useMediaQuery } from '@mantine/hooks';

const Users = lazy(() => import("../Users/Users"));
const ScheduleGifts = lazy(() => import("../ScheduleGifts/ScheduleGifts"));
const CampaignHeader = lazy(() =>
  import("./components/CampaignHeader/CampaignHeader")
);

function Campaign() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [labels, setlabels] = useState([]);
  const [thankYouList, setthankYouList] = useState([]);
  const [tanhkyouPageNo, settanhkyouPageNo] = useState(1);
  const [chartData, setchartData] = useState([]);
  const [giftStatusChartData, setgiftStatusChartData] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [giftExpiryTime, setgiftExpiryTime] = useState(null);
  const [emailFrequency, setemailFrequency] = useState(null);

  const [sendGiftError, setsendGiftError] = useState(false);
  const [giftSentStatus, setgiftSentStatus] = useState("");
  const [giftSentSucessModal, setgiftSentSucessModal] = useState(false);
  const [sendingGift, setsendingGift] = useState(true);

  const [scheduleGiftError, setScheduleGiftError] = useState(false);
  const [giftScheduleStatus, setGiftScheduleStatus] = useState("");
  const [giftScheduleSuccessModal, setGiftScheduleSuccessModal] =
    useState(false);
  const [schedulingGift, setSchedulingGift] = useState(true);

  const [campaign, setCampaign] = useState({});

  const [manual,setManual] = useState(false);
  const matches_one = useMediaQuery('(min-width: 1650px)');
  const matches_two = useMediaQuery('(max-width: 1450px)');
  const matches_three = useMediaQuery('(max-width: 1650px)');

  const location = useLocation();

  useEffect(() => {
    getDataById(id);
  }, [id]);

  const getDataById = async (id) => {
    try {
      const { data } = await axios.get(API.CAMPAIGN_WITH_ID, {
        params: {
          id: id
        }
      });

      if (data) {
        let arr = [];
        let labels = [];
        let colors = [];
        let giftCount = [];
        dispatch(saveCampaignDataByID(data.campaign));
        let campaign = data.campaign;
        setgiftExpiryTime(campaign?.gift_expiration);
        setemailFrequency(campaign?.email_reminder_frequency);
        if(data.campaign.campaign_type==="Manual"){
          setManual(true);
        }

        for (let i = 0; i < campaign?.gifts?.length; i++) {
          let id = campaign.gifts[i];
          let dat = {
            color: colorsArr[i],
            name: campaign?.gifts[i].gift_name,
            count: campaign?.gifts[i].count
          };

          labels.push(dat.name);
          colors.push(dat.color);
          giftCount.push(dat.count ? dat.count : 0);
          const dataArr = {
            labels: labels,
            datasets: [
              {
                label: "# of Votes",
                data: giftCount,
                backgroundColor: colors,
                borderWidth: 0
              }
            ]
          };

          if (campaign.gifts.length === i + 1) {
            setchartData(dataArr);
          }
          arr.push(dat);
        }

        const giftStatusdataArr = {
          labels: ["Claimed / Delivered", "Emails sent"],
          datasets: [
            {
              label: "Gift Status",
              data: [
                campaign.gift_claimed + campaign.gift_delivered,
                campaign?.gift_sent -
                  (campaign?.gift_claimed + campaign?.gift_delivered)
              ],
              backgroundColor: ["#219EBC", "#F1F1F1"],
              borderWidth: 0
            }
          ]
        };
        setgiftStatusChartData(giftStatusdataArr);
        setlabels([...arr]);
        setCampaign(data?.campaign);
        getThankyouList(data?.campaign.id);
      }
    } catch (error) {
      if (error && error?.response?.status === 401) {
        dispatch(handleSignOut());
        localStorage.clear();
        <Navigate to="/login" />;
      }
    }
  };

  const getThankyouList = async (id) => {
    try {
      let res = await axios.get("gift_thanku_note", {
        params: {
          page: tanhkyouPageNo,
          campaign: id
        }
      });

      if (res) {
        setthankYouList(res.data.response);
      }
    } catch (error) {}
  };

  const handleSendGift = async () => {
    try {
      setsendingGift(false);
      let ids = localStorage.getItem("selectedUser");
      let sendGiftsTo = JSON.parse(ids);
      let res = await axios.post("sendgift/", {
        user_ids: sendGiftsTo,
        campaign_id: campaign.id
      });

      if (res) {
        getDataById(id);
        setsendingGift(true);
        setsendGiftError(false);
        setgiftSentStatus(res.data.message);
        setgiftSentSucessModal(!giftSentSucessModal);
        setshowmodal(!showmodal);
      }
    } catch (error) {
      setsendingGift(true);
      console.log(error.response.data.message);
      setgiftSentStatus(error.response.data.message);
      setgiftSentSucessModal(!giftSentSucessModal);
      setsendGiftError(true);
      setshowmodal(!showmodal);
    }
  };

  const handleScheduleGifts = async () => {
    try {
      setSchedulingGift(false);
      let ids = localStorage.getItem("selectedUser");
      let sendGiftsTo = JSON.parse(ids);
      let res = await axios.post("scheduled_gift/", {
        user_ids: sendGiftsTo,
        campaign_id: campaign.id
      });

      if (res) {
        getDataById(id);
        setSchedulingGift(true);
        setScheduleGiftError(false);
        setGiftScheduleStatus(res.data.message);
        setGiftScheduleSuccessModal(!giftScheduleSuccessModal);
        setshowmodal(!showmodal);
      }
    } catch (error) {
      setSchedulingGift(true);
      console.log(error.response.data.message);
      setGiftScheduleStatus(error.response.data.message);
      setGiftScheduleSuccessModal(!giftScheduleSuccessModal);
      setScheduleGiftError(true);
      setshowmodal(!showmodal);
    }
  };
  
  const sendingGiftButton = <div className="header__flex__modal-container">
  <div className="header__flex-item" style={{ display: "flex" }}>

  <button
      style={{ width: "220px",display:"flex",justifyContent:"center",alignItems:"center",fontWeight:"500",fontSize:"16px",fontFamily:"DM Sans" }}
      className="gift_btn"
      onClick={() => handleSendGift()}
    >
      <img
        src={SendGift}
        alt="Send gifts"
        // style={{ boxShadow: "inset 1px 0px 3px #FFB7B7" }}
      />
      {sendingGift ? (
        "SEND GIFT NOW"
      ) : (
        <>
          <div>Sending Gifts</div>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </>
      )}
    </button>

    {campaign.campaign_type === "Scheduled" && (
      <button
        style={{ marginLeft: "1rem", width: "220px",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#219EBC" ,fontWeight:"500",fontSize:"16px",fontFamily:"DM Sans"}}
        className="gift_btn"
        onClick={() => handleScheduleGifts()}
      >
        <img
          src={SendGift}
          alt="Schedule gifts"
          // style={{ boxShadow: "inset 1px 0px 3px #FFB7B7" }}
        />
        {schedulingGift ? (
          "Schedule Gift"
        ) : (
          <>
            <div>Sending Gifts</div>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </>
        )}
      </button>
    )}

  </div>
</div>
  const OverLay = (showModal) => {
    return (
      <Modal
        show={showModal}
        onHide={() => setshowmodal(!showmodal)}
        className="myModal"
      >
       
       
        {/* <div className="header__flex__modal-container">
          <div className="header__flex-item" style={{ display: "flex" }}>
            {campaign.campaign_type === "Scheduled" && (
              <button
                style={{ marginRight: "1rem", width: "220px",display:"flex",justifyContent:"center",alignItems:"center" }}
                className="gift_btn"
                onClick={() => handleScheduleGifts()}
              >
                <img
                  src={GiftIMG}
                  alt="Schedule gifts"
                  style={{ boxShadow: "inset 1px 0px 3px #FFB7B7" }}
                />
                {schedulingGift ? (
                  "Schedule Gift"
                ) : (
                  <>
                    <div>Sending Gifts</div>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </>
                )}
              </button>
            )}

            <button
              style={{ width: "220px",display:"flex",justifyContent:"center",alignItems:"center" }}
              className="gift_btn"
              onClick={() => handleSendGift()}
            >
              <img
                src={GiftIMG}
                alt="Send gifts"
                style={{ boxShadow: "inset 1px 0px 3px #FFB7B7" }}
              />
              {sendingGift ? (
                "Send Gift"
              ) : (
                <>
                  <div>Sending Gifts</div>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              )}
            </button>
          </div>
        </div> */}
        

        <Suspense fallback={<div>Loading...</div>}>
          <Users campaign_sub_type={campaign.campaign_subtype} sendingGiftButton={sendingGiftButton} handleShowModal={() => setshowmodal(!showmodal)} />
        </Suspense>
      </Modal>
    );
  };

  return campaign.id ? (
    <div>
      <Modal
        show={giftSentSucessModal}
        onHide={() => setgiftSentSucessModal(!giftSentSucessModal)}
        className="giftSucessModal"
      >
        <img style={{marginLeft:"453px",cursor:"pointer"}} onClick={() => {setgiftSentSucessModal(false)}} src={CancelIcon} alt="close_icon" />
        {!sendGiftError?<div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          <div className="gift_status">YES! Gift(s) sent successfully!</div>
          <p className="gift_status_below">You’re about to make somebody really happy.</p>
          {/* <LogoICON size={25} color={"#219EBC"} /> */}
        </div>:<div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          <div className="gift_status">{giftSentStatus}</div>
          {/* <LogoICON size={25} color={"#219EBC"} /> */}
        </div>}

        <div>
          {sendGiftError ? (
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "3px solid #FF2543",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex"
              }}
            >
              <ErrorIcon size={25} color={"#FF2543"} />
            </div>
          ) : (
            // <GiftSentIcon size={60} />
            <img src={GiftIcon} width="60px" height="" alt="gift_icon" />
          )}
        </div>
      </Modal>

      <Modal
        show={giftScheduleSuccessModal}
        onHide={() => setGiftScheduleSuccessModal(!giftScheduleSuccessModal)}
        className="giftSucessModal"
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "30px",
            fontWeight: "500"
          }}
        >
          <div className="">{giftScheduleStatus}</div>
          <LogoICON size={25} color={"#219EBC"} />
        </div> */}

        <img style={{marginLeft:"453px",cursor:"pointer"}} onClick={() => {setGiftScheduleSuccessModal(false)}} src={CancelIcon} alt="close_icon" />
        {!scheduleGiftError?<div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          <div className="gift_status">YES! Gift(s) are scheduled!</div>
          <p className="gift_status_below">You’re about to make somebody really happy.</p>
          {/* <LogoICON size={25} color={"#219EBC"} /> */}
        </div>:<div
          style={{
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "10px",
            fontWeight: "500",
          }}
        >
          <div className="gift_status">{giftScheduleStatus}</div>
          {/* <LogoICON size={25} color={"#219EBC"} /> */}
        </div>}

        <div>
          {scheduleGiftError ? (
            <div
              style={{
                width: "50px",
                height: "50px",
                border: "3px solid #FF2543",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex"
              }}
            >
              <ErrorIcon size={25} color={"#FF2543"} />
            </div>
          ) : (
            // <GiftSentIcon size={60} />
            <img src={GiftIcon} width="60px" height="" alt="gift_icon" />
          )}
        </div>
      </Modal>

      <Header
        handleShowModal={() => setshowmodal(!showmodal)}
        campaign_name={campaign.name}
        campaign_type={campaign.campaign_type}
        campaign_sub_type={campaign.campaign_subtype}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <CampaignHeader
          campaign={campaign}
          getDataById={getDataById}
          handleShowModal={() => setshowmodal(!showmodal)}
        />
      </Suspense>

      <div className="main_campaign_container">
        <div className="section__1">
          <GiftProgress campaign={campaign} />
        </div>

        <div className="section__2">
          <div className="one" style={(matches_one && manual)?{width:"29%"}:{}}>
            <div className="one_inner_first">
              <FrequencyCard
                campaign_id={campaign.id}
                getDataById={getDataById}
                giftFrequency={giftExpiryTime}
              />
            </div>
            <CalendarCard
              campaign={campaign}
              notActive={campaign.campaign_status === "EXPIRED"}
              getDataById={getDataById}
            />
          </div>
          <div className="two" style={(matches_one && manual)?{width:"33%"}:(matches_two && manual)?{width:"30.3%"}:(matches_three && manual)?{width:"30.8%"}:{}}>
            <BudgetSpent
              totalspent={campaign.total_spent}
              totalbudget={campaign.total_budget}
              remaining={campaign.remaining_budget}
              setCampaign={setCampaign}
              campaign={campaign}
              id={id}
              notActive={campaign.campaign_status === "EXPIRED"}
            />
            <div className="two_inner_second">
              <EmailFrequencyCard
                campaign_id={campaign.id}
                emailFrequency={emailFrequency}
              />
            </div>
          </div>
            {campaign.campaign_type === "Scheduled" && (
          <div className="three">
              <Suspense fallback={<div>Loading...</div>}>
                <ScheduleGifts id={campaign.id} campaign_name={campaign.name} />
              </Suspense>
          </div>
            )}
          <ThankyouNotes className="four" thankYouList={thankYouList} manual={manual} />
          <ChartCardComponent
          labels={labels}
          giftslength={campaign.gifts.length}
          chartData={chartData}
          title="Gifts Chosen"
          campaign_id={campaign.id}
          manual={manual}
        />
        </div>

        

        <div className="section_3"></div>

        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <GiftStatusTable id={campaign.id} campaign={campaign} />
          </Suspense>
        </div>

        {showmodal ? <OverLay show={showmodal} /> : null}
      </div>
    </div>
  ) : (
    <React.Fragment>
      <Spinner animation="border" role="status"></Spinner>
    </React.Fragment>
  );
}

export default React.memo(Campaign);
