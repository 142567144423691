import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../../store/toolkit";

import "./Profile.scss"

const Profile = ({ name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 return(
   <div className="profile_main">
      <div className="profile_header">
        <div className="profile_signout">
          <button
            className="signout"
            onClick={() => {
              dispatch(handleSignOut());
              localStorage.clear();
              navigate("/");
            }}
          >
            sign out
          </button>
        </div>
      </div>
      <div className="profile-container">
        <div className="profile">
          <h3>Profile page</h3>
          <h5>Hello {name} !!!</h5>
        </div>
      </div>
   </div>
 ); 
};

Profile.defaultProps = {
  name: "User"
}

export default Profile;