import React, { useState } from "react";
import moment from "moment";
import axios from "../../../api_conf/BaseUrl";
import { toast } from "react-toastify";

const NotificationBar = ({ notifications, getNotifications }) => {
  const formatTime = (item) => {
    return moment(item.Gift_time).format("h:mm a");
  };

  const handleRemoveNotification = async (item) => {
    try {
      const res = await axios.put("Notification_Api/", {
        Notification_id: item.id
      });
      if (res?.data?.success) {
        getNotifications();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  return (
    <div className="notification_bar_container">
       {notifications!==null? notifications.map((currElem) => {
        return (
          <div
            key={currElem.id}
            className="notification_bar"
            onClick={() => handleRemoveNotification(currElem)}
          >
            <div className="notification_mark" />
            <div className="notification_time">{formatTime(currElem)}</div>
            <div className="notification_message">{currElem.Message}</div>
          </div>
        );
      }):<div></div>}
    </div>
  );
};

export default NotificationBar;
