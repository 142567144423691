import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import Select from "react-select";
import axios from "../../api_conf/BaseUrl";
import API from "../../api_conf/ApiUrls";
import { Alert, Form, Spinner } from "react-bootstrap";
import CustomModal from "../CustomModal/CustomModal";
import AppendList from "./AppendList";
import AddUserFormContainer from "./AddUserFormContainer";
import UpdateUserFormContainer from "./UpdateUserFormContainer";
import ActionsContainer from "./ActionsContainer";
import { useNavigate } from "react-router-dom";
import { fetchGroups, postGroups } from "../../store/actions";
import "./CustomList.scss";

import OpenUpdateFormIcon from "../../assets/images/open_update_form.svg";
import SaveUpdateFormIcon from "../../assets/images/save_updated_form.svg";
import CloseIcon from "../../assets/images/closeModal.svg";
import Delete from "../../assets/images/deleteUser.png";
import CancelIcon from "../../assets/images/cancelIcon.png";
import CloseUpdateFormIcon from "../../assets/images/EditIcon.svg";

import userListExample from "../../assets/files/UserList.csv";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import search from "../../assets/images/Vector.png";
import { useDispatch } from "react-redux";
import { handleSignOut } from "../../store/toolkit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "../Dropdown/Dropdown";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "transparent",
    color: "#19D5C6"
  }
}));

const CustomList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [joiningDate, setJoiningDate] = useState("");

  const [_email, _setEmail] = useState("");
  const [_birthdate, _setBirthdate] = useState("");
  const [_joiningDate, _setJoiningDate] = useState("");
  const [_id, _setId] = useState();

  const [modalList, setmodalList] = useState("");
  const addNewListRef = useRef(null);

  const [list, setList] = useState("");
  const [user, setuser] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const [allChecked, setallChecked] = useState(false);
  const [selectedUser, setselectedUser] = useState([]);
  const [specificList, setSpecificList] = useState([]);
  const [allCheckedFlag, setAllCheckedFlag] = useState(true);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [showUserMove, setShowUserMove] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const validateAddress = () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleDeleteAlert = () => setShowDeleteAlert(false);
  const handleClose = () => setShow(false);
  const handleCloseUser = () => setShowUser(false);
  const handleCloseUserEdit = () => setShowUserEdit(false);
  const handleCloseUserMove = () => setShowUserMove(false);
  const handleCloseSpecific = () => {
    setSpecificList([]);
    setShow(false);
  };

  const handleCloseSpecificUser = () => {
    setShowUser(false);
  };

  const handleModalClose = () => setshowModal(false);
  const handleModalOpen = () => setshowModal(true);

  const location = useLocation();

  const classes = useStyles();

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleUserSearch = (event) => {
    const { value } = event.target;

    props.setCurrentPage();
    props.setSearchValue(value);
    props.setGroupName(modalList);

    if (value === "") {
      props.resetCurrentPage();
    }
  };

  const debouncedSearch = useCallback(debounce(handleUserSearch), []);

  const handleAllCheckChange = (e) => {
    if (e.target.checked) {
      const response = props.usersList.map((item) => {
        selectedUser.includes(item.id);
        return item.id;
      });
      let tempSelection = [...selectedUser];
      let tempSelection2 = tempSelection.concat(response);
      let uniq = [...new Set(tempSelection2)];
      setselectedUser(uniq);
    } else {
      const response = props.usersList.map((item) => {
        selectedUser.includes(item.id);
        return item.id;
      });
      let tempSelection = [...selectedUser];
      let itemIndex = -1;

      for (let i = 0; i <= response.length; i++) {
        if (tempSelection.includes(response[i])) {
          itemIndex = tempSelection.findIndex((item) => response[i] === item);
          if (itemIndex > -1) {
            tempSelection.splice(itemIndex, 1);
          }
        }
      }
      let uniq = [...new Set(tempSelection)];
      setselectedUser(uniq);
    }
    setallChecked(e.target.checked);
  };

  const handlepageChangeFunc = (val) => {
    props.handlepageChange(val, modalList);
  };

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    let flag = true;
    if (selectedUser.length > 0) {
      props.usersList.map((item) => {
        if (!selectedUser.includes(item.id)) {
          flag = false;
        }
        setAllCheckedFlag(flag);
      });
    } else {
      flag = false;
      setAllCheckedFlag(flag);
    }
  }, [props.usersList, selectedUser, allChecked]);

  useEffect(() => {}, [allCheckedFlag]);

  useEffect(() => {
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
  }, [selectedUser]);

  useEffect(() => {
    if (allChecked) {
      const response = props.usersList.map((item) => {
        selectedUser.includes(item.id);
        return item.id;
      });
      let tempSelection = [...selectedUser];
      let tempSelection2 = tempSelection.concat(response);
      let uniq = [...new Set(tempSelection2)];
      setselectedUser(uniq);
    } else {
      const response = props.usersList.map((item) => {
        selectedUser.includes(item.id);
        return item.id;
      });

      let tempSelection = [...selectedUser];
      let itemIndex = -1;

      for (let i = 0; i <= response.length; i++) {
        if (tempSelection.includes(response[i])) {
          itemIndex = tempSelection.findIndex((item) => response[i] === item);
          if (itemIndex > -1) {
            tempSelection.splice(itemIndex, 1);
          }
        }
      }

      let uniq = [...new Set(tempSelection)];
      setselectedUser(uniq);
    }
  }, [allChecked]);

  useEffect(() => {
    validateAddress();
  }, [firstname, lastname, email]);

  const userIdSelector = (e, val) => {
    if (selectedUser.includes(val)) {
      let newAyy = [...selectedUser];
      const userIndex = selectedUser.findIndex((user) => user === val);

      if (userIndex > -1) {
        newAyy.splice(userIndex, 1);
      }
      setselectedUser(newAyy);
    } else {
      const newArr = [...selectedUser, val];

      setselectedUser(newArr);
    }
  };

  const { isLoading, error, data, refetch } = useQuery("groups", fetchGroups, {
    refetchOnWindowFocus: false,
    enabled: true
  });

  const findGrpID = (data, name) => {
    if (data) {
      const response = data.response.filter((grp) => grp.name === name);
      if (response.length > 0) {
        return response[0].id;
      }
    }
    return null;
  };

  const listSelectionHandler = (value) => {
    if (value === "All") {
      setmodalList(value);
      setList(value);
      setselectedUser("");
      props.resetCurrentPage();
      props.setCurrentPage(1);
    }
    //console.log("hello",value)
    props.setCurrentPage(1);
    setmodalList(value);
    setList(value);
    setShowForm(false);
    setShowUpdateForm(false);
    setselectedUser("");
    props.resetCurrentPage(value);
  };

  function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function formatMonthDate(date) {
    return moment(date).format("MM-DD");
  }

  const manuallyAddUserHandler = async () => {
    try {
      const user = {
        firstname,
        lastname,
        email,
        company,
        list,
        birthdate: birthdate ? formatMonthDate(birthdate) : "",
        joiningDate: joiningDate ? formatDate(joiningDate) : ""
      };
      const answer = await props.addUserList(user, modalList);
      setmodalList(user.list);
      setList(user.list);
      props.resetCurrentPage(user.list);
      setShowUser(false);
      setFirstname("");
      setLastname("");
      setEmail("");
      setCompany("");
      setList("");
      setBirthdate("");
      setJoiningDate("");
    } catch (error) {}
  };
  const updateIdEmail = (_id, _email) => {
    _setId(_id);
    _setEmail(_email);
  };
  const updateUserHandler = async () => {
    try {
      const user = {
        _email,
        _birthdate: _birthdate ? formatMonthDate(_birthdate) : "",
        _joiningDate: _joiningDate ? formatDate(_joiningDate) : ""
      };
      updateUserForm(_id, user);
      setShowUserEdit(false);
      _setEmail("");
      _setBirthdate("");
      _setJoiningDate("");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSpecificList = (user, group_id) => {
    axios
      .delete("usergroups/", {
        data: {
          user_id: user.id,
          group_id: group_id
        }
      })
      .then((result) => {
        if (result.data.success) {
          handleClose();
          setSpecificList([]);
          toast.success("Deleted successfully!");
          props.allusersList();
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  const deleteUserEntirely = (users) => {
    axios
      .delete("users/", { data: { id: users } })
      .then((result) => {
        if (result.data.success) {
          toast.success("Deleted successfully!");
          props.allusersList(list);
          setselectedUser([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteList = (event) => {
    setSpecificList(event);
  };

  const DeleteByList = () => {
    let newLists = user.listing_with_id;
    return (
      <div>
        <div className="group_data_container">
          <Select
            value={specificList}
            isMulti
            options={newLists}
            onChange={handleDeleteList}
          />
        </div>
      </div>
    );
  };

  const handleModalAction = [
    {
      okButtonTitle: "SPECIFIC LIST",
      // disabledVal: !findGrpID(data, modalList),
      okButtonAction: () => {
        setShow(true);
        setshowDelete(false);
      }
    },
    {
      okButtonTitle: "ALL LISTS",
      okButtonAction: () => {
        props.deleteUser(user);
        listSelectionHandler("");
        setshowDelete(false);
      }
    }
  ];

  let buttonContainer = (
    <button
      className="list_common_button"
      onClick={(event) => setShowForm(true)}
    >
      Add manually
    </button>
  );

  if (showForm) {
    buttonContainer = (
      <button
        className="list_common_button"
        onClick={(event) => manuallyAddUserHandler()}
      >
        Save User
      </button>
    );
  }

  const updateUserForm = (userId, user) => {
    const body = {
      id: userId,
      email: user._email,
      date_of_birth: user._birthdate,
      date_of_joining: user._joiningDate
    };

    axios
      .put(API.USERS, body)
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          props.allusersList(list);
          return result;
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  let updateContainer = (
    <button
      style={{
        outline: "none",
        border: "none",
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        backgroundColor: "transparent"
      }}
      onClick={(event) => setShowUpdateForm(true)}
    >
      <img
        style={{
          height: "40px",
          width: "40px"
        }}
        src={OpenUpdateFormIcon}
        alt="Open user update form"
      />
    </button>
  );

  if (showUpdateForm) {
    updateContainer = (
      <button
        onClick={(event) => updateUserHandler()}
        style={{
          outline: "none",
          border: "none",
          height: "60px",
          width: "60px",
          borderRadius: "50%",
          backgroundColor: "transparent"
        }}
      >
        <img
          style={{
            height: "40px",
            width: "40px"
          }}
          src={SaveUpdateFormIcon}
          alt="Open user update form"
        />
      </button>
    );
  }

  const handleChangeList = async (user_id, to_group, option) => {
    try {
      const { data } = await axios.post(API.USER_ACTIONS, {
        user_id,
        to_group,
        option
      });
      if (data.success) {
        //console.log("hello1",to_group)
        setmodalList(to_group);
        setList(to_group);
        props.resetCurrentPage(to_group);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(data.message);
      console.error(error);
    }
  };

  let listContent = (
    <div className="row">
      <div
        className="col-12 list_content_item"
        style={{ textAlign: "center", minWidth: "970px" }}
      >
        {" "}
        No User Found{" "}
      </div>
    </div>
  );

  if (props.usersList?.length) {
    listContent = props.usersList.map((user, index) => {
      return (
        <AppendList
          user={user}
          key={user.id}
          deleteUser={() => {
            setuser(user);
            setshowDelete(true);
          }}
          usersList={props.usersList}
          changeUserList={userIdSelector}
          selectedUsers={selectedUser}
          setShowUserEdit={() => {
            setShowUserEdit(true);
          }}
          setShowUserMove={() => {
            setShowUserMove(true);
          }}
          updateIdEmail={updateIdEmail}
        />
      );
    });
  }

  let loaderComp = (
    <Spinner animation="border" role="status" className="loader">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );

  let arr = [];
  for (let i = 0; i < props.no_of_pages; i++) {
    arr.push(i);
  }
  if (isLoading) {
    return loaderComp;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div
      className="CustomList_container"
      style={
        location.pathname.includes("campaign")
          ? { backgroundColor: "#F4F8F9", padding: "20px", width: "100%" }
          : {}
      }
    >
      {/* <div className="buttons_container">
        <CustomDropdown
          labelName="testing"
          primaryCls={true}
          options={data.response}
          onSearchChange={(e) => listSelectionHandler(e.target.value)}
          defaultValue={modalList}
          defaultAll="All"
        />
        <button
          className="list_common_button custom_button_width"
          onClick={handleModalOpen}
        >
          {" "}
          +{" "}
        </button>
        <div className="space__merger"> </div>
        <div>
          {showForm ? (
            <button
              className="list_common_button"
              onClick={() => setShowForm(false)}
            >
              Close form
            </button>
          ) : null}
        </div>
        {buttonContainer}
        <div>
          <button
            className="list_common_button"
            style={{ position: "relative" }}
            onClick={() => inputFileRef.current.click()}
          >
            Upload CSV
          </button>

          <div className="downloadCsv">
            Download example{" "}
            <a href={userListExample} download="UserList.csv">
              {" "}
              userList.csv
            </a>
          </div>
        </div>
      </div> */}

      <input
        type="file"
        ref={inputFileRef}
        hidden
        onChange={($event) => props.uploadUsers($event)}
      />

      <div className="list_content" style={{ position: "relative" }}>
        {props.dataUploaded ? (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              zIndex: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress style={{ color: "#55b4d1" }} />
          </div>
        ) : null}
      </div>

      {/* Modal to select and move/copy User */}
      <Modal
        show={showUserMove}
        onHide={handleCloseUserMove}
        backdrop="static"
        keyboard={false}
        className="listModalUser"
        //style={{width:"40%",margin:"auto"}}
      >
        <Modal.Body
          className="specific_list_body border-0"
          style={{ width: "700px", margin: "auto" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItem: "center"
            }}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "700", color: "#023047" }}
            >
              Move/copy user to a list
            </div>

            <div
              style={{ marginLeft: "344px", cursor: "pointer" }}
              onClick={handleCloseUserMove}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>
          </div>

          <ActionsContainer
            data={data}
            list={list}
            setEmail={setEmail}
            setFirstname={setFirstname}
            setLastname={setLastname}
            setList={setList}
            userIDstoChange={selectedUser}
            handleChangeList={handleChangeList}
            location={location.pathname.includes("users")}
            handleCloseUserMove={handleCloseUserMove}
            showUserMove={showUserMove}
          />
        </Modal.Body>
      </Modal>
      {/* Modal to Edit User */}
      <Modal
        show={showUserEdit}
        onHide={handleCloseUserEdit}
        backdrop="static"
        keyboard={false}
        className="listModalUser"
        //style={{width:"40%",margin:"auto"}}
      >
        <Modal.Body
          className="specific_list_body border-0"
          style={{ width: "700px", margin: "auto" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItem: "center"
            }}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "700", color: "#023047" }}
            >
              Edit a user
            </div>

            <div
              style={{ marginLeft: "500px", cursor: "pointer" }}
              onClick={handleCloseUserEdit}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "16px",
                alignItem: "center",
                textAlign: "left",
                marginTop: "24px"
              }}
            >
              <div className="">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#000000"
                  }}
                >
                  Email
                </label>
                <br></br>
                <input
                  type="text"
                  placeholder="Enter Email"
                  value={_email}
                  style={{
                    width: "230px",
                    height: "32px",
                    border: "1px solid #023047",
                    borderRadius: "4px",
                    color: "#5E7D8C",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                    outline: "none"
                  }}
                  onChange={(event) => _setEmail(event.target.value)}
                />
              </div>

              <div className="customDatePickerWidthEdit">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#000000"
                  }}
                >
                  Birthday
                </label>
                <br></br>
                <DatePicker
                  className="date_picker"
                  selected={_birthdate}
                  placeholderText="MM/ DD"
                  onChange={(date) => _setBirthdate(date)}
                  dateFormat="MM/dd"
                  autoComplete="off"
                />
              </div>

              <div className="customDatePickerWidthEdit">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#000000"
                  }}
                >
                  Joining Date
                </label>
                <br></br>
                <DatePicker
                  id="manualJoin"
                  name="manualJoin"
                  selected={_joiningDate}
                  placeholderText="MM/ DD/ YYYY"
                  onChange={(date) => _setJoiningDate(date)}
                  // onChange={(date) => handleJoiningDate(date)}
                  // onChangeRaw={handleOnChangeRaw}
                  dateFormat="MM/dd/yyyy"
                  autoComplete="off"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  disabledKeyboardNavigation
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="specific_list_body border-0">
          <Button
            className=""
            style={{
              background: "#FFFFFF",
              padding: "8px 16px",
              width: "95px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#023047",
              border: "1px solid #023047",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={handleCloseUserEdit}
          >
            CANCEL
          </Button>
          <Button
            className=""
            style={{
              background: "#219EBC",
              padding: "8px 16px",
              width: "133px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#FFFFFF",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={(event) => updateUserHandler()}
          >
            SAVE USER
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to add user */}
      <Modal
        show={showUser}
        onHide={handleCloseUser}
        backdrop="static"
        keyboard={false}
        className="listModalUser"
        //style={{width:"40%",margin:"auto"}}
      >
        <Modal.Body
          className="specific_list_body border-0"
          style={{ width: "700px", margin: "auto" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItem: "center"
            }}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "700", color: "#023047" }}
            >
              Add a user
            </div>

            <div
              style={{ marginLeft: "500px", cursor: "pointer" }}
              onClick={handleCloseSpecificUser}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>
          </div>

          <AddUserFormContainer
            data={data}
            list={list}
            setEmail={setEmail}
            setFirstname={setFirstname}
            setLastname={setLastname}
            setCompany={setCompany}
            setList={setList}
            birthdate={birthdate}
            setBirthdate={setBirthdate}
            joiningDate={joiningDate}
            setJoiningDate={setJoiningDate}
          />
        </Modal.Body>
        <Modal.Footer className="specific_list_body border-0">
          <Button
            className=""
            style={{
              background: "#FFFFFF",
              padding: "8px 16px",
              width: "95px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#023047",
              border: "1px solid #023047",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={handleCloseSpecificUser}
          >
            CANCEL
          </Button>
          <Button
            className=""
            style={{
              background: "#219EBC",
              padding: "8px 16px",
              width: "133px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#FFFFFF",
              border: "none",
              opacity: isDisabled ? "0.5" : "",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            disabled={isDisabled}
            onClick={(event) => manuallyAddUserHandler()}
          >
            + ADD USER
          </Button>
        </Modal.Footer>
      </Modal>

      {/* {showForm ? (
        <AddUserFormContainer
          data={data}
          list={list}
          setEmail={setEmail}
          setFirstname={setFirstname}
          setLastname={setLastname}
          setCompany={setCompany}
          setList={setList}
          birthdate={birthdate}
          setBirthdate={setBirthdate}
          joiningDate={joiningDate}
          setJoiningDate={setJoiningDate}
        />
      ) : null} */}

      <CustomModal
        show={showModal}
        closeModal={handleModalClose}
        okButtonTitle="Save"
        title="Add a new list"
        okButtonAction={async () => {
          await postGroups(modalList, refetch);
          props.resetCurrentPage(addNewListRef.current.value);
          handleModalClose();
        }}
      >
        <div>
          <form>
            <div className="form_control">
              <input
                type="text"
                ref={addNewListRef}
                placeholder="List name"
                onChange={(e) => {
                  setmodalList(e.target.value);
                  // props.resetCurrentPage(e.target.value);
                }}
              />
            </div>
          </form>
        </div>
      </CustomModal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="listModal"
      >
        <h3
          style={{
            padding: "0px 0px 0px 0px",
            fontFamily: "DM Sans",
            color: "#023047",
            fontSize: "24px",
            textAlign: "left",
            fontWeight: "700",
            marginLeft:"8px",
          }}
          className="specific_list_header"
        >
          Choose the lists to delete
        </h3>
        <Modal.Body className="specific_list_body border-0" style={{marginRight:"8px"}}>
          <DeleteByList setSpecificList={setSpecificList} />
        </Modal.Body>
        <Modal.Footer className="specific_list_footer border-0">
          <Button
            className="specific_list_button"
            onClick={handleCloseSpecific}
            style={{
              background: "#FFFFFF",
              padding: "8px 16px",
              width: "95px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#023047",
              border: "1px solid #023047",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Close
          </Button>
          <Button
            className="specific_list_button"
            onClick={() => {
              deleteSpecificList(
                user,
                specificList.map((item) => item.value)
              );
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95px",
              height: "37px",
              borderRadius: "6px",
              fontSize: "16px",
              fontWeight: "500",
              background: "#ff304c",
              border: "none",
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomModal
        show={showDelete}
        closeModal={() => setshowDelete(false)}
        title="Delete user from list"
        handleModalAction={handleModalAction}
      >
        {/* You should confirm that you are really trying to delete. */}
        <p
          style={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#5E7D8C",
            textAlign: "left",
            marginLeft: "20px"
          }}
        >
          Do you want to delete the user from a specific list or from all lists?
        </p>
      </CustomModal>

      {/* <div className="search_delete_container">
        <div className="search_container">
          <input
            type="search"
            placeholder="Search name, company or email"
            className="search_bar"
            onChange={debouncedSearch}
          />
          <button className="search_button">Search</button>
        </div>

        {props.subType?.includes("Work Anniversary") ? (
          <div className="select_years">
            <select
              name="select_years"
              id="select_years"
              onChange={(event) => {
                props.setWorkingYears(event.target.value);
              }}
            >
              <option selected disabled>
                No. of years
              </option>
              <option value="">All</option>
              <option value="0">1 year</option>
              <option value="1">2 years</option>
              <option value="2">3 years</option>
              <option value="3">4 years</option>
              <option value="4">5 years</option>
              <option value="5">6 years</option>
              <option value="6">7 years</option>
              <option value="7">8 years</option>
              <option value="8">9 years</option>
              <option value="9">10 years</option>
            </select>
          </div>
        ) : null}

        {location.pathname.includes("campaign") ? null : (
          <div className="search_container_right">
            <div>
              {showUpdateForm ? (
                <button
                  style={{
                    margin: "0 10px",
                    outline: "none",
                    border: "none",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    backgroundColor: "transparent"
                  }}
                  onClick={() => setShowUpdateForm(false)}
                >
                  <img
                    style={{
                      height: "40px",
                      width: "40px"
                    }}
                    src={CloseUpdateFormIcon}
                    alt="Open user update form"
                  />
                </button>
              ) : null}

              {updateContainer}
            </div>
            <div className="delete_user">
              <button
                className="delete_user_btn"
                onClick={() => {
                  setShowDeleteAlert(true);
                }}
              >
                Delete Users
              </button>
            </div>
          </div>
        )}
      </div> */}

      {showUpdateForm ? (
        <UpdateUserFormContainer
          _setEmail={_setEmail}
          _birthdate={_birthdate}
          _joiningDate={_joiningDate}
          _setBirthdate={_setBirthdate}
          _setJoiningDate={_setJoiningDate}
        />
      ) : null}

      <Modal
        show={showDeleteAlert}
        onHide={handleDeleteAlert}
        backdrop="static"
        keyboard={false}
        className="listModal"
      >
        {selectedUser.length > 0 ? (
          <>
            {/* className="delete_list_header" */}
            <div
              style={{
                width: "100%",
                marginLeft: "15px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end"
              }}
              onClick={handleDeleteAlert}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>

            <h3
              style={{
                padding: "0px 0px 0px 0px",
                fontFamily: "DM Sans",
                color: "#023047",
                fontSize: "24px",
                textAlign: "center"
              }}
            >
              Are you sure you want to delete the selected users?
            </h3>

            <h10
              style={{
                padding: "0px 10px 0px 0px",
                textAlign: "center",
                fontFamily: "DM Sans",
                color: "red"
              }}
            >
              This will delete any scheduled gifts or cancel any open gift links
              associated with the selected users
            </h10>
            <Modal.Footer className="delete_list_footer border-0">
              <Button
                //className="delete_list_button"
                style={{
                  background: "#FFFFFF",
                  padding: "8px 16px",
                  width: "125px",
                  height: "37px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#023047",
                  border: "1px solid #023047",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={handleDeleteAlert}
              >
                Never mind
              </Button>
              <Button
                //className="delete_list_button"
                style={{
                  background: "#219EBC",
                  padding: "8px 16px",
                  width: "133px",
                  height: "37px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#FFFFFF",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => {
                  deleteUserEntirely(selectedUser);
                  setShowDeleteAlert(false);
                }}
              >
                Yes i'm sure
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <div className="empty_delete_list">
            <div
              style={{ alignSelf: "flex-end", cursor: "pointer" }}
              onClick={handleDeleteAlert}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>
            <h3 className="empty_delete_list_title">
              Please select a user first
            </h3>
            <Button className="close_empty_list " onClick={handleDeleteAlert}>
              Close
            </Button>
          </div>
        )}
      </Modal>
      {location.pathname.includes("users") ? (
        ""
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px"
          }}
        >
          <h2
            style={{
              fontFamily: "DM Sans",
              fontStyle: "italic",
              fontWeight: "500",
              fontSize: "38px",
              color: "#023047"
            }}
          >
            Send a gift
          </h2>
          <img
            onClick={props.handleShowModal}
            style={{
              width: "15px",
              height: "15px",
              marginBottom: "15px",
              cursor: "pointer"
            }}
            src={CloseIcon}
            alt="closeIcon"
          />
        </div>
      )}

      <div
        className="navbar"
        style={
          location.pathname.includes("users")
            ? {
                padding: "0px 42px",
                left: "-20px",
                top: "-15px",
                width: "103%",
                background: "#023047"
              }
            : { padding: "0px 15px", width: "100%", margin: "auto" }
        }
      >
        <div
          className="search_con"
          style={location.pathname.includes("users") ? {} : { width: "45%" }}
        >
          <img
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              width: "13px",
              height: "13px"
            }}
            src={search}
            alt="search icon"
          />
          <input
            type="search"
            placeholder="Search name, company or email..."
            className="search_bar"
            onChange={debouncedSearch}
          />
        </div>
        {props.sendingGiftButton}

        {location.pathname.includes("users") ? (
          <div
            className="sign_out"
            onClick={() => {
              dispatch(handleSignOut());
              localStorage.clear();
              navigate("/");
            }}
          >
            Sign Out
          </div>
        ) : (
          ""
        )}
      </div>

      {location.pathname.includes("users") ? (
        <div className="user_text">Users</div>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItem: "center"
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              alignItem: "center",
              marginTop: "8px"
            }}
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "DM Sans",
                color: "#023047",
                marginTop: "18px"
              }}
            >
              Filter By
            </p>
            <CustomDropdown
              labelName="Filter By"
              primaryCls={true}
              options={data.response}
              onSearchChange={(e) => listSelectionHandler(e)}
              defaultValue={modalList}
              defaultAll="All"
            />
          </div>

          {/* {location.pathname.includes("campaign") ? null : (
            <ActionsContainer
              data={data}
              list={list}
              setEmail={setEmail}
              setFirstname={setFirstname}
              setLastname={setLastname}
              setList={setList}
              userIDstoChange={selectedUser}
              handleChangeList={handleChangeList}
              location={location.pathname.includes("users")}
            />
          )} */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            gap: "8px",
            marginTop: "20px"
          }}
        >
          {location.pathname.includes("campaign") ? null : (
            <div>
              <button
                className=""
                style={{
                  width: "118px",
                  height: "34px",
                  background: "#FFFFFF",
                  border: "1px solid #219EBC",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#219EBC",
                  padding: "8px 16px"
                }}
                onClick={() => {
                  inputFileRef.current.value = "";
                  inputFileRef.current.click();
                  //console.log("hello",inputFileRef);
                }}
              >
                Upload CSV
              </button>

              <div className="downloadCsv">
                Download example{" "}
                <a href={userListExample} download="UserList.csv">
                  {" "}
                  userList.csv
                </a>
              </div>
            </div>
          )}
          {location.pathname.includes("campaign") ? null : (
            <button
              className=""
              onClick={handleModalOpen}
              style={{
                width: "118px",
                height: "34px",
                background: "#FFFFFF",
                border: "1px solid #219EBC",
                borderRadius: "4px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#219EBC",
                padding: "8px 16px"
              }}
            >
              <span>+</span> ADD LIST
            </button>
          )}

          <button
            className=""
            style={{
              width: "118px",
              height: "34px",
              background: "#FFFFFF",
              border: "1px solid #219EBC",
              borderRadius: "4px",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18px",
              color: "#219EBC",
              padding: "8px 16px"
            }}
            onClick={(event) => {
              setShowForm(true);
              setShowUser(true);
            }}
          >
            <span>+</span> ADD USER
          </button>
          {location.pathname.includes("campaign") ? null : (
            <button
              className=""
              style={{
                width: "32px",
                height: "32px",
                background: "#FF2543",
                borderRadius: "4px",
                border: "none",
                padding: "4px"
              }}
              onClick={() => {
                setShowDeleteAlert(true);
              }}
            >
              <img src={Delete}></img>
            </button>
          )}
        </div>
      </div>

      {allCheckedFlag ? (
        <h8 className="all_checkbox_checked">
          All {selectedUser.length} users are selected on this page.
        </h8>
      ) : (
        ""
      )}
      <div
        className="list_container"
        style={
          location.pathname.includes("user")
            ? { marginTop: "15px" }
            : { marginTop: "15px" }
        }
      >
        <Form>
          <div className="row list_header">
            <div className="col list_header_heading">
              <div
                className="form-check-list"
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#5E7D8C"
                }}
              >
                <input
                  type="checkbox"
                  onChange={handleAllCheckChange}
                  checked={allCheckedFlag}
                  className="m-1"
                  style={{ transform: "scale(1.3)" }}
                />
                First Name
              </div>
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C"
              }}
            >
              {" "}
              Last Name{" "}
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C"
              }}
            >
              {" "}
              Email{" "}
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C"
              }}
            >
              {" "}
              Lists{" "}
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C"
              }}
            >
              {" "}
              Company{" "}
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C"
              }}
            >
              {" "}
              Birthday{" "}
            </div>
            <div
              className="col list_header_heading"
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#5E7D8C",
                width: "400px !important"
              }}
            >
              {" "}
              Joining Date{" "}
            </div>
          </div>
          <div className="list_content" style={{ position: "relative" }}>
            {isLoading ? loaderComp : listContent}
            {props.isLoaded ? (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  zIndex: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress style={{ color: "#55b4d1" }} />
              </div>
            ) : null}
          </div>
        </Form>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "30px 0 10px 0"
        }}
      >
        <Pagination
          className={classes.root}
          style={{
            backgroundColor: "#fbfbfb",
            borderRadius: "16px",
            padding: "5px 0"
          }}
          count={props.no_of_pages}
          defaultPage={1}
          size="large"
          disabled={props.isLoaded}
          onChange={(event, value) => {
            handlepageChangeFunc(value);
          }}
        />
      </div>
    </div>
  );
};

export default CustomList;
