import React, { memo } from "react";
import { Doughnut } from "react-chartjs-2";

export const colors = {
  color1: "#E850FF",
  color2: "#229EBC",
  color3: "#023047",
  color4: "#FFEFA1",
  color5: "#FFEEDA",
  color6: "#FF2543",
};

// const options = {
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {
//       // mode: "label",
//       // position: "nearest",
//       // callbacks:{
//       //   afterBody:function(context){
//       //     return "====="
//       //   }
//       // }
//     },
//   },
//   animation: false,
//   cutout: "75%",
//   tooltips: {
//     mode: "label",
//     position: "average",
//   },
// };

const options = {
  plugins: {
      tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,

          external: function(context) {
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                  tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                  return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                  const titleLines = tooltipModel.title || [];
                  const bodyLines = tooltipModel.body.map(getBody);

                  let innerHtml = '<thead>';
                  titleLines.forEach(function(title) {
                      innerHtml += '<tr><th>' + title + '</th></tr>';
                  });
                  innerHtml += '</thead><tbody>';

                  bodyLines.forEach(function(body, i) {
                      const colors = tooltipModel.labelColors[i];
                      let style = 'background:' + colors.backgroundColor;
                      style += '; border-color:' + colors.borderColor;
                      style += '; border-width: 2px';
                      const span = '<span style="' + style + '"></span>';
                      innerHtml += '<tr><td>' + span + body + '</td></tr>';
                  });
                  innerHtml += '</tbody>';

                  let tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();
              // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.background="rgba(0,0,0,0.85)";
              tooltipEl.style.color="#fff";
              tooltipEl.style.padding="5px";
              tooltipEl.style.border="1px solid #fff";
              tooltipEl.style.borderRadius="10px";
              tooltipEl.style.height="40px";
          }
      },
      legend: {
        display: false,
      },
  },
  animation: false,
  cutout: "75%",
}


export default memo(function Chart(props) {
  const plugins = [
    {
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 160).toFixed(2);
        ctx.font = `${props.labelName.size || '9px'} ${props.labelName.font || 'DM Sans'}`;
        ctx.textBaseline = "top";
        let text = props.labelName,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  return (
    <>
      <Doughnut data={props.data} options={options} plugins={plugins} />
    </>
  );
});
