import React from "react";

import CheckSecondary from "../../../assets/images/check_secondary.svg";

const ChooseCardFilter = ({ cardItems, filterCard, cardInSelection }) => {
  return (
    <div className="choose_card_container">
      {cardItems?.map((currElem, index) => (
        <div key={index} className="choose_card">
          {cardInSelection(currElem) && (
            <button className="choose_card_button">
              <img
                className="choose_card_check_secondary"
                src={CheckSecondary}
                alt="Add Card Template"
              />
            </button>
          )}
          <div
            key={index}
            className="choose_card_category"
            onClick={() => filterCard(currElem)}
          >
            <h6>{currElem}</h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChooseCardFilter;
