import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "../../../api_conf/BaseUrl";
import DashboardNotification from "../../../assets/images/dashboard_notification.svg";
import NotificationBar from "./NotificationBar";
import "./Notifications.scss";
import { handleNotificationsCount } from "../../../store/toolkit";

const Notifications = ({ showNotifications,notificationHeight }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  let date = new Date();
  date = moment(date).format("Do MMMM");

  useEffect(() => {
    getNotifications();
    const notificationsInterval = setInterval(() => {
      getNotifications();
    }, 60000);

    return () => {
      clearInterval(notificationsInterval);
    };
  }, []);

  const getNotifications = async () => {
    try {
      const res = await axios.get("Notification_Api/");
      if (res?.data?.success) {
        setNotifications(res?.data?.Notification);
        setNotificationCount(res?.data?.count);
        dispatch(handleNotificationsCount(res?.data?.count));
      }else{
        setNotifications(null);
        setNotificationCount(res?.data?.count);
        dispatch(handleNotificationsCount(res?.data?.count));
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  console.log(showNotifications);

  return (
    <div
      className={` ${
        showNotifications
          ? `dashboard_search_notification_sm dashboard_notifications`
          : `dashboard_notifications`
      } `}
      style={{height:`842+${notificationHeight}px`}}
    >
      <div className="dashboard_notifications_container">
        <div className="dashboard_notifications_icon">
          <div className="dashboard_notifications_count">
            {notificationCount}
          </div>
          <img src={DashboardNotification} alt="Dashboard Notification" />
        </div>
        <div className="dashboard_notifications_info">
          <div className="dashboard_notification_info_title">Notifications</div>
          <div className="dahsboard_notifications_info_date">{date}</div>
        </div>
      </div>

      <div className="dashboard_notification_history_container">
        <NotificationBar
          notifications={notifications}
          getNotifications={getNotifications}
        />
      </div>
    </div>
  );
};

export default Notifications;
