import React, { useState } from "react";
import axios from "../../../api_conf/BaseUrl";
import { useDropzone } from "react-dropzone";
import { Spinner } from "react-bootstrap";

import UploadIcon from "../../../assets/images/upload.svg";

const UploadLogo = ({ getLogo }) => {
  const [uploading, setUploading] = useState(false);
  const handleUploadLogo = async (item) => {
    setUploading(true);
    try {
      const res = await axios.post("logo/", item);
      if (res?.data?.success) {
        getLogo();
        console.log("Logo uploaded");
      }
      setUploading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = async (files) => {
    const file = files[0];
    if (file !== undefined) {
      let data = new FormData();
      data.append("logo", file);
      handleUploadLogo(data);
    } else {
      return;
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*"
  });

  return (
    <div
      {...getRootProps()}
      className="personalize_upload_container __personalize_upload_logo_container"
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <div className="personalize_upload">
          {uploading ? (
            <div
              style={{
                position: "absolute",
                top: "45%"
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              <p>Drag & Drop or Upload</p>
              <img src={UploadIcon} alt="Upload" />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadLogo;
