import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";

import {
  addEnvelopeColor,
  hideEnvelopeColorPicker
} from "../../../../store/designCardSlice";

import useClickOutside from "../../custom/useClickOutside";

function EnvelopeColors() {
  const popover = useRef();
  const dispatch = useDispatch();
  const envelopeColor = useSelector((state) => state.designCard.envelopeColor);
  const [color, setColor] = useState(envelopeColor);

  const close = useCallback(() => dispatch(hideEnvelopeColorPicker()), []);
  useClickOutside(popover, close);
  return (
    <div className="envelope_color_picker" ref={popover}>
      <SketchPicker
        color={color}
        onChange={(updatedColor) =>
          dispatch(addEnvelopeColor(updatedColor.hex))
        }
      />
    </div>
  );
}

export default EnvelopeColors;
