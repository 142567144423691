import React, { useState } from "react";
import CloseIcon from "../../assets/images/close_secondary.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SoldOut from "../../assets/images/sold-out.svg";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const GiftShowcase = ({ showcaseItem, setShowGiftInfo }) => {
  const [currentImage, setCurrentImage] = useState(showcaseItem.img_url);
  const handleShowcaseImage = (item) => {
    setCurrentImage(item);
  };

  let variants = [
    "female-founded",
    "bipoc-owned",
    "lgbtq-owned",
    "gives back",
    "sustainable"
  ];

  return (
    <div className="gift_showcase_container">
      <div className="gift_showcase">
        <div className="gift_showcase_left">
          <div className="gift_showcase_left_image">
            <div className="gift_showcase_left_image_slider_container">
              {showcaseItem?.images_url.length > 1 ? (
                <Swiper spaceBetween={-20} slidesPerView={4} navigation>
                  {showcaseItem?.images_url?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="gift_showcase_left_image_slider">
                          <img
                            src={item}
                            alt="Slider images"
                            onClick={() => handleShowcaseImage(item)}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="gift_showcase_image_main">
            {showcaseItem.sold_out && (
              <div className="gift_showcase_image_main_sold-out">
                <img src={SoldOut} alt="Gift sold out" />
              </div>
            )}
            <img src={currentImage} alt={showcaseItem.name}/>
          </div>
        </div>
        <div className="gift_showcase_right">
          <div className="gift_showcase_right_close_btn">
            <img
              src={CloseIcon}
              onClick={() => {
                setShowGiftInfo(false);
                document.body.style.overflow = "visible";
              }}
              alt="Close button"
            />
          </div>
          <div className="gift_showcase_right_info">
            <div className="gift_showcase_product_name">
              <h3>{showcaseItem.name}</h3>
            </div>
            <div className="gift_showcase_brand_name">
              <h3>
                By <span>{showcaseItem.vendor}</span>
              </h3>
            </div>
            <div className="gift_showcase_tags_container">
              {showcaseItem?.tages?.map((el) => {
                const variantData = variants.includes(el.toLowerCase());
                return el.length && variantData ? (
                  <div className="gift_showcase_tags">{el}</div>
                ) : (
                  ""
                );
              })}
            </div>

            <div className="gift_showcase_product_description">
              <p className="gift_showcase_product_description_content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showcaseItem?.description || ""
                  }}
                ></div>
              </p>
            </div>

            {showcaseItem.variant_details.length > 1 ? (
              <div className="gift_showcase_product_info">
                <h3>Variants:</h3>
                {/* <p>
                  Please choose your preferred variant. We’ll do our best to
                  fulfill it!
                </p> */}
              </div>
            ) : (
              ""
            )}
            <div
              className="gift_showcase_product_variant"
              style={{
                display: showcaseItem?.variant_details.length <= 1 && "none"
              }}
            >
              {showcaseItem?.variant_details.map((currElem) => {
                return (
                  <span>
                    <p>{currElem.title}</p>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftShowcase;
