import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./../api_conf/BaseUrl";

const initialState = {
  giftsData: [] /* Initial data from API is stored here  */,
  giftAdded: [],
  addMultiSelect: true,

  status: "idle",
  totalGifts: 0,
  giftId: null,
  erorr: null,

  /* FIlter states */
  filtersTag: [],
  valuesTag: [],
  countryTag: [],
  collectionsTag: [],
  giftTypeTag: [],
  giftSearch: "",
  giftCollection: []
};

export const fetchCollections = createAsyncThunk(
  "gifts/fetchCollections",
  async () => {
    try {
      const res = await axios.get("collections/");
      return res.data.response.smart_collections;
    } catch (error) {
      return error.message;
    }
  }
);

const giftSlice = createSlice({
  name: "gifts",
  initialState,
  reducers: {
    handleGiftsData: (state, action) => {
      state.giftsData = action.payload;
    },
    handleGifts: (state, action) => {
      state.giftAdded = action.payload;
    },
    addExisitingData: (state, action) => { 
      state.giftAdded = action.payload
      // const giftId =  action.payload.map((el) => el.id);
      // const newGifts = state.giftsData.filter((el) => el.id === giftId[0]);
      // state.giftAdded = newGifts
    },
    changeFilter: (state, action) => {
      state.filtersTag = action.payload;
    },

    handleValueTag: (state, action) => {
      state.valuesTag = action.payload;
    },
    handleCountryTag: (state, action) => {
      state.countryTag = action.payload;
    },
    handleCollectionTag: (state, action) => {
      state.collectionsTag = action.payload;
    },
    handleGiftTypeTag: (state, action) => {
      state.giftTypeTag = action.payload;
    },
    addGiftId: (state, action) => {
      state.giftId = action.payload;
    },
    addingGift: (state, action) => {
      const giftIndex = state.giftsData.findIndex(
        (item) => item.id === action.payload.id
      );
      state.giftsData[giftIndex].selected = true;
    },
    removingGift: (state, action) => {
      const giftIndex = state.giftsData.findIndex(
        (item) => item.id === action.payload.id
      );

      state.giftsData[giftIndex].selected = false;
    },

    clearGifts: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.status = "Success";
        state.giftCollection = action.payload;
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

export const getGiftStatus = (state) => state.gifts.status;
export const getGiftErrors = (state) => state.gifts.erorr;

export const {
  addExisitingData,
  handleGifts,
  addGiftId,
  clearGifts,
  setShowGift,
  addingGift,
  removingGift,
  changeFilter,
  handleGiftsData,
  handleValueTag,
  handleCountryTag,
  handleCollectionTag,
  handleGiftTypeTag,
  handleCountry
} = giftSlice.actions;
export default giftSlice.reducer;
