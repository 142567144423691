import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import useClickOutside from "./useClickOutside";
import { addFontFamily } from "../../../store/personalizeEmailSlice";
import { imgStyle } from "./customStyles";
import UpIcon from "../../../assets/images/chevron-up-secondary.svg";
import DownIcon from "../../../assets/images/chevron-down-secondary.svg";
import CheckPrimary from "../../../assets/images/check_primary.svg";

function FontFamilyDropdown({ title, items = [], mutliSelect = false }) {
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const dispatch = useDispatch();

  const toggle = () => setOpen(!open);

  const closeDropdown = useCallback(() => setOpen(false), []);
  useClickOutside(dropdownRef, closeDropdown);

  useEffect(() => {
    dispatch(addFontFamily(selection));
  }, [selection]);

  const handleAddItem = (item) => {
    if (!selection.some((current) => current.id === item.id)) {
      if (!mutliSelect) {
        setSelection([item]);
        setOpen(false);
      } else if (mutliSelect) {
        setSelection([...selection, item]);
      }
    } else {
      let resultAfterRemoval = selection;
      resultAfterRemoval = resultAfterRemoval.filter(
        (current) => current.id !== item.id
      );
      setSelection([...resultAfterRemoval]);
    }
  };

  const isItemInSelection = (item) => {
    if (selection.find((current) => current.id === item.id)) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="live_bottom_menu">
        <div
          tabIndex={0}
          onKeyPress={toggle}
          onClick={toggle}
          className="live_bottom_menu_container"
        >
          <div className="live_bottom_menu_title">{title}</div>
          {open ? (
            <img src={UpIcon} alt="Chevron Up" />
          ) : (
            <img src={DownIcon} alt="Chevron Down" />
          )}
        </div>

        {open && (
          <div className="live_menu_list_container" ref={dropdownRef}>
            <div className="live_menu_inner_list">
              <ul className="live_menu_list">
                {items.map((item) => (
                  <li key={item.id}>
                    <button
                      className="live_menu_list_button"
                      onClick={() => handleAddItem(item)}
                    >
                      <span className="live_menu_checkmark">
                        {isItemInSelection(item) && (
                          <img
                            src={CheckPrimary}
                            alt="Check mark"
                            style={imgStyle}
                          />
                        )}
                      </span>
                      <span className="live_menu_value">
                        <p style={{ fontFamily: `${item.font}` }}>
                          {item.name}
                        </p>
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default FontFamilyDropdown;

