import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalProvider } from "./context/GlobalState";
import { QueryClient, QueryClientProvider } from "react-query";
import { configureStore } from "@reduxjs/toolkit";
import { BrowserRouter as Router } from "react-router-dom";

import { combineReducers } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import UserSlice from "./store/toolkit";
import GiftSlice from "./store/giftSlice";
import PersonalizeEmailSlice from "./store/personalizeEmailSlice";
import DesignCardSlice from "./store/designCardSlice";
import CurateGiftSlice from "./store/curateGiftSlice";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    "gifts",
    "personalizeEmail",
    "designCard",
    "curateGift",
  ],
};

const reducers = combineReducers({
  user: UserSlice,
  gifts: GiftSlice,
  personalizeEmail: PersonalizeEmailSlice,
  designCard: DesignCardSlice,
  curateGift: CurateGiftSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let persistor = persistStore(store);
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalProvider>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <App />
            </Router>
          </QueryClientProvider>
        </PersistGate>
      </GlobalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
