import React, { useState, useEffect } from "react";
import axios from "../../../api_conf/BaseUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import AddIcon from "../../../assets/images/outbound_template_add_circle.svg";
import UploadAnimation from "./UploadAnimation";
import PostLoader from "../custom/PostLoader";
import Info from "../../../assets/images/info.svg";
import CheckSecondary from "../../../assets/images/check_secondary.svg";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import UploadLogo from "./UploadLogo";
import insertTextAtCursor from 'insert-text-at-cursor';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const PersonalizedEmail = ({
  getLogo,
  getAnimation,
  logoImage,
  base64Image,
  mutliSelect = false,
  logoLoading,
  animationLoading,
  persEmailData,
  setPersEmailData,
  setPersEmailDisabled,
  showEditCampaign,
  setBtnTextEmpty,
  setEmailHeaderEmpty
}) => {
  const [showNameHelp, setShowNameHelp] = useState(false);
  const [showEmailHelp, setShowEmailHelp] = useState(false);
  const [showSubjectLineReminder, setShowSubjectLineReminder] = useState(false);
  const [showLogoHelp, setShowLogoHelp] = useState(false);
  const [showAnimationHelp, setShowAnimationHelp] = useState(false);

  useEffect(() => {
    validatePersonalizeEmail();
  }, [persEmailData]);

  const validatePersonalizeEmail = () => {
    if (
      persEmailData?.sender?.length > 0 &&
      persEmailData?.subjectLine?.length > 0 &&
      persEmailData?.subjectLineReminder?.length > 0 &&
      persEmailData?.emailHeader?.length > 0 &&
      persEmailData?.btnText?.length > 0 &&
      persEmailData?.emailAnimation.length > 0
    ) {
      setPersEmailDisabled(false);
    }

    if (
      persEmailData?.sender?.trim() === "" ||
      persEmailData?.subjectLine?.trim() === "" ||
      persEmailData?.subjectLineReminder?.trim() === "" ||
      persEmailData?.emailHeader?.trim() === "" ||
      persEmailData?.btnText?.trim() === "" ||
      persEmailData?.emailAnimation.length === 0
    ) {
      setPersEmailDisabled(true);
    }
  };

  const handleFrom = (e) => {
    setPersEmailData({
      ...persEmailData,
      sender: e.target.value
    });
  };

  const handleSubjectLine = (e) => {
    setPersEmailData({
      ...persEmailData,
      subjectLine: e.target.value
    });
  };

  const handleSubjectLineReminder = (e) => {
    setPersEmailData({
      ...persEmailData,
      subjectLineReminder: e.target.value
    });
  };

  const handleEmailHeader = (e) => {
    if (showEditCampaign) {
      setEmailHeaderEmpty(false);
    }
    setPersEmailData({
      ...persEmailData,
      emailHeader: e.target.value
    });
  };

  const handleBtnText = (e) => {
    if (showEditCampaign) {
      setBtnTextEmpty(false);
    }
    setPersEmailData({
      ...persEmailData,
      btnText: e.target.value
    });
  };

  const handleAddAnimations = (item) => {
    if (
      !persEmailData.emailAnimation.some((current) => current.id === item.id)
    ) {
      if (!mutliSelect) {
        setPersEmailData({
          ...persEmailData,
          emailAnimation: [item]
        });
      } else if (mutliSelect) {
        setPersEmailData({
          ...persEmailData,
          emailAnimation: [...persEmailData.emailAnimation, item]
        });
      }
    } else {
      let animationAfterRemoval = persEmailData.emailAnimation;
      animationAfterRemoval = animationAfterRemoval.filter(
        (current) => current.id !== item.id
      );
      setPersEmailData({
        ...persEmailData,
        emailAnimation: [...animationAfterRemoval]
      });
    }
  };

  const animationInSelection = (item) => {
    if (
      persEmailData.emailAnimation.find((current) => current.id === item.id)
    ) {
      return true;
    }
    return false;
  };

  const handleAddLogo = (item) => {
    if (!persEmailData.emailLogo.some((currElem) => currElem.id === item.id)) {
      if (!mutliSelect) {
        setPersEmailData({
          ...persEmailData,
          emailLogo: [item]
        });
      } else if (mutliSelect) {
        setPersEmailData({
          ...persEmailData,
          emailAnimation: [...persEmailData.emailLogo, item]
        });
      }
    } else {
      let logoAfterRemoval = persEmailData.emailLogo;
      logoAfterRemoval = logoAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      setPersEmailData({
        ...persEmailData,
        emailLogo: [...logoAfterRemoval]
      });
    }
  };

  const logoInSelection = (item) => {
    if (persEmailData.emailLogo.find((currElem) => currElem.id === item.id)) {
      return true;
    }
    return false;
  };

  const onNameHover = () => {
    setShowNameHelp(true);
  };
  const onNameLeave = () => {
    setShowNameHelp(false);
  };

  const onEmailHover = () => {
    setShowEmailHelp(true);
  };
  const onEmailLeave = () => {
    setShowEmailHelp(false);
  };

  const onSubjectLineReminderHover = () => {
    setShowSubjectLineReminder(true);
  };

  const onSubjectLineReminderLeave = () => {
    setShowSubjectLineReminder(false);
  };

  const onLogoHover = () => {
    setShowLogoHelp(true);
  };

  const onLogoLeave = () => {
    setShowLogoHelp(false);
  };

  const onAnimationHover = () => {
    setShowAnimationHelp(true);
  };

  const onAnimationLeave = () => {
    setShowAnimationHelp(false);
  };

  const handleSubjectLineUsername = () => {
    
    setPersEmailData({
      ...persEmailData,
      subjectLine: persEmailData.subjectLine
    });
    const el = document.getElementById('subject_line');
    insertTextAtCursor(el,"{user_first_name}")
  };
  
  const handleSubjectReminderUsername = () => {
    
    setPersEmailData({
      ...persEmailData,
      subjectLineReminder:
      persEmailData.subjectLineReminder
    });
    const el = document.getElementById('subject_line_reminder');
    insertTextAtCursor(el,"{user_first_name}")
  };

  const handleEmailHeaderUsername = () => {
    
    setPersEmailData({
      ...persEmailData,
      emailHeader: persEmailData.emailHeader
    });
    const el = document.getElementById('email_header');
    insertTextAtCursor(el,"{user_first_name}")
  };

  return (
    <div className="personalize_email_container">
      <div className="personalize_group">
        <div className="personalize_group_container">
          <label className="personalize_label" htmlFor="personalize_control">
            From Name:
          </label>
          <input
            type="text"
            value={persEmailData.sender}
            className="personalize_control"
            placeholder="Please avoid using special characters"
            onChange={handleFrom}
          />
        </div>
      </div>
      <div className="personalize_group">
        <div className="personalize_group_container">
          <label className="personalize_label" htmlFor="personalize_control">
            Subject Line
          </label>
          <input
            type="text"
            id="subject_line"
            value={persEmailData.subjectLine}
            className="personalize_control"
            placeholder="Use {user_first_name} to dynamically use recipient's first name"
            onChange={handleSubjectLine}
          />
        </div>
        <img
          onMouseEnter={onEmailHover}
          onMouseLeave={onEmailLeave}
          className="personalize_helper_icon"
          src={Info}
          alt="Helper text"
        />
        <div className="personalize_settings_helper">
          {showEmailHelp && (
            <div className="personalize_helper_container">
              <p className="personalize_helper_text">
                Use {`user_first_name`} to dynamically use the recipient’s first
                name
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="add_first_name_wrapper">
        <div
          className="add_first_name_container"
          onClick={handleSubjectLineUsername}
        >
          <img src={AddIcon} alt="Add {user_first_name}" />
          <button className="add_first_name">Add First Name</button>
        </div>
      </div>

      <div className="personalize_group">
        <div className="personalize_group_container">
          <label
            className="personalize_label __subject_line_reminder_label"
            htmlFor="personalize_label"
          >
            Subject Line For Reminder
          </label>
          <input
            type="text"
            id="subject_line_reminder"
            value={persEmailData.subjectLineReminder}
            placeholder="Use {user_first_name} to dynamically use recipient's first name"
            className="personalize_control __subject_line_reminder_control"
            onChange={handleSubjectLineReminder}
          />
        </div>
        <img
          onMouseEnter={onSubjectLineReminderHover}
          onMouseLeave={onSubjectLineReminderLeave}
          className="personalize_helper_icon"
          src={Info}
          alt="Helper text"
        />
        <div className="personalize_settings_helper">
          {showSubjectLineReminder && (
            <div className="personalize_helper_container">
              <p className="personalize_helper_text">
                Use {`user_first_name`} to dynamically use the recipient’s first
                name
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="add_first_name_wrapper">
        <div
          className="add_first_name_container"
          onClick={handleSubjectReminderUsername}
        >
          <img src={AddIcon} alt="Add {user_first_name}" />
          <button className="add_first_name">Add First Name</button>
        </div>
      </div>

      <div className="personalize_group">
        <div className="personalize_group_container">
          <label className="personalize_label" htmlFor="personalize_control">
            Email Header
          </label>
          <input
            type="text"
            id="email_header"
            value={persEmailData.emailHeader}
            className="personalize_control"
            name="header"
            onChange={handleEmailHeader}
            placeholder="Start typing for live preview"
          />
        </div>
        <img
          onMouseEnter={onNameHover}
          onMouseLeave={onNameLeave}
          className="personalize_helper_icon"
          src={Info}
          alt="Helper text"
        />

        <div className="personalize_settings_helper">
          {showNameHelp && (
            <div className="personalize_helper_container">
              <p className="personalize_helper_text">
                Use {`user_first_name`} to dynamically use the recipient’s first
                name
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="add_first_name_wrapper">
        <div
          className="add_first_name_container"
          onClick={handleEmailHeaderUsername}
        >
          <img src={AddIcon} alt="Add {user_first_name}" />
          <button className="add_first_name">Add First Name</button>
        </div>
      </div>

      <div className="personalize_group">
        <div className="personalize_group_container">
          <label className="personalize_label" htmlFor="personalize_control">
            Button text
          </label>
          <input
            type="text"
            value={persEmailData.btnText}
            className="personalize_control"
            name="header"
            onChange={handleBtnText}
            placeholder="Edit the button text"
          />
        </div>
      </div>

      <div className="personalize_slider_group">
        <div className="personalize_slider_group_container">
          <label className="personalize_sliders_label">
            Select Logo
            <div className="personalize_sliders_label_optional">(Optional)</div>
          </label>
          <div className="personalize_slider_container">
            {logoLoading ? (
              <PostLoader />
            ) : (
              <Swiper
                breakpoints={{
                  540: {
                    width: 540,
                    slidesPerView: 6
                  },
                  640: {
                    width: 640,
                    slidesPerView: 4
                  },
                  768: {
                    width: 768,
                    slidesPerView: 7
                  }
                }}
                spaceBetween={1}
                slidesPerView={5}
                navigation
              >
                {logoImage?.map((item) => {
                  return (
                    <SwiperSlide
                      key={item?.id}
                      onClick={() => handleAddLogo(item)}
                    >
                      <div className="personalize_swiper_main">
                        <img
                          src={axios.defaults.devMediaUrl + `${item?.image}`}
                        />

                        {logoInSelection(item) && (
                          <div className="personalize_swiper_check_main">
                            <img
                              style={{ height: "20px", width: "20px" }}
                              src={CheckSecondary}
                              alt="Check Icon"
                            />
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
        <div className="personalize_slider_upload">
          <UploadLogo getLogo={getLogo} />
        </div>
        <img
          onMouseEnter={onLogoHover}
          onMouseLeave={onLogoLeave}
          className="personalize_slider_helper_icon"
          src={Info}
          alt="Helper text"
        />
        <div className="personalize_slider_settings_helper">
          {showLogoHelp && (
            <div className="personalize_slider_helper_container">
              <p className="personalize_slider_helper_text">
                Feel free to drag and drop a file or upload your own! Accepted
                file types are .png, .svg, .jpeg, .gif,
                <br />
                with a maximum file size of 10 MB
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="personalize_slider_group">
        <div className="personalize_slider_group_container">
          <label className="personalize_sliders_label">
            Select Animation/Image
          </label>
          <div className="personalize_slider_container">
            {animationLoading ? (
              <PostLoader />
            ) : (
              <Swiper
                breakpoints={{
                  540: {
                    width: 540,
                    slidesPerView: 6
                  },
                  640: {
                    width: 640,
                    slidesPerView: 4
                  },
                  768: {
                    width: 768,
                    slidesPerView: 7
                  }
                }}
                spaceBetween={1}
                slidesPerView={5}
                navigation
              >
                {base64Image?.map((item) => {
                  return (
                    <SwiperSlide
                      key={item?.id}
                      onClick={() => handleAddAnimations(item)}
                    >
                      <div className="personalize_swiper_main">
                        <img
                          src={
                            axios.defaults.devMediaUrl + `${item?.image_base64}`
                          }
                        />

                        {animationInSelection(item) && (
                          <div className="personalize_swiper_check_main">
                            <img
                              style={{ height: "20px", width: "20px" }}
                              src={CheckSecondary}
                              alt="Check Icon"
                            />
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
        <div className="personalize_slider_upload">
          <UploadAnimation getAnimation={getAnimation} />
        </div>
        <img
          onMouseEnter={onAnimationHover}
          onMouseLeave={onAnimationLeave}
          className="personalize_slider_helper_icon"
          src={Info}
          alt="Helper text"
        />
        <div className="personalize_slider_settings_helper">
          {showAnimationHelp && (
            <div className="personalize_slider_helper_container">
              <p className="personalize_slider_helper_text">
                Feel free to drag and drop a file or upload your own! Accepted
                file types are .png, .svg, .jpeg, .gif,
                <br />
                with a maximum file size of 10 MB
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalizedEmail;
