import React, { memo, useEffect, useState } from "react";
import medalIMG from "../../../assets/images/first-medal.svg";
import GiftCardChart from "../GiftCardChart";
import axios from "../../../api_conf/BaseUrl";
import star from "../../../assets/images/star.svg";
import Gift from '../../../assets/images/giftChoosen.svg';
import { useMediaQuery } from '@mantine/hooks';
export default memo(function ChartCardComponent(props) {
  let { labels, giftslength, chartData, campaign_id,manual } = props;

  const [data, setData] = useState([]);
  const [tag,setTag] = useState(false);
  const [tag1,setTag1] = useState(false);
  const [tag2,setTag2] = useState(false);
  const [tag3,setTag3] = useState(false);
  const [tag4,setTag4] = useState(false);
  const matches_one = useMediaQuery('(min-width: 1650px)');
  const matches_two = useMediaQuery('min-width: 1780px');
  const matches_three = useMediaQuery('max-width:1980px');
  const tagsFilter = () => {
    if(data?.tags){
      let per = data?.tags.toLowerCase().includes("#female-founded")
      //let per = data?.tags.toLowerCase().includes("shiptoca")
      setTag(per?true:false);
      let per1 = data?.tags.toLowerCase().includes("#bipoc-owned")
      //let per1 = data?.tags.toLowerCase().includes("shiptocanada")
      setTag1(per1?true:false);
      let per2 = data?.tags.toLowerCase().includes("lgbtq-owned")
      //let per2 = data?.tags.toLowerCase().includes("physical")
      setTag2(per2?true:false);
      let per3 = data?.tags.toLowerCase().includes("gives back")
      //let per3 = data?.tags.toLowerCase().includes("purifier")
      setTag3(per3?true:false);
      let per4 = data?.tags.toLowerCase().includes("sustainable")
      //let per4 = data?.tags.toLowerCase().includes("wellness")
      setTag4(per4?true:false);
    }
  }

  useEffect(() => {
    axios.get(`campaign?id=${campaign_id}`).then((res) => {
      setData(res?.data.campaign?.shopify_Data?.product);
    });
  },[]);

  setTimeout(() => {
    tagsFilter();
  },200)

  return giftslength > 0 ? (
    <div
      className="cards-container order-container"
      // style={{ minWdth: "45vw" }}
    >
      <div className="gift-container order-card-container" style={(matches_one && manual)?{width:"63.1%"}:{}}>
        <div style={{ display: "flex", 
        flexDirection: "column", 
        marginTop:"25px"
        // flex: "0.75" 
        }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "5%",
              alignItems: "center",
              textAlign: "center",
              height:"10px"
            }}
          >
            <div style={{ paddingRight: "10px",fontSize:"14px",fontWeight:"700"}}>Gifts Chosen</div>
            <img src={Gift} alt="" style={{ height: "3vh" }} />
          </div>
          <div className="flex-container order-flex-container">
            <div
              className="order-card"
            >
              {labels?.map((label, index) => {
                const { color, name, count } = label;
                return (
                  <React.Fragment key={index}>
                    <div className="order-card-label-container">
                      <div
                        className="order-card-label"
                        style={{ backgroundColor: color, borderRadius: "2px" }}
                      />
                      <div className="order-card-label-text">
                        {name} - {count ? count : "0"}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="order-card-chart" style={(matches_one && manual) ? {transform:"scale(1.3)"}:(matches_two && matches_three && manual)?{transform:"scale(1.5)"}:{}} >
          {giftslength > 0 && (
            <GiftCardChart data={chartData} labelName="Gifts Chosen" />
          )}
        </div>
        {/* Most popular Gift */}
        <div style={{ paddingRight: "24px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div
              style={{ fontWeight: "700", fontSize: "14px", color: "#023047" }}
            >
              Most Popular
            </div>
            <div style={{ marginLeft: "10px" }}>
              <img src={star} alt="star Icon"></img>
            </div>
          </div>
          {data && (
            <>
              <div style={{ borderRadius: "4px", marginTop: "5px",textAlign:"center" }}>
                <img
                  className="most_popular_img"
                  style={{
                    // width: "121px",
                    // height: "121px",
                    margin:"10px 0px 10px 0px",
                    borderRadius: "4px"
                  }}
                  src={data?.image?.src}
                  alt="product Img"
                ></img>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#000000",
                  textAlign:"center",
                  width:"250px",
                }}
              >
                {data?.title}
              </div>
              <div
                style={{
                  marginTop: "5px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#000000",
                  width:"250px",
                  textAlign:"center"
                }}
              >
                {tag?"#female-founded ":""}
                {tag1?"#bipoc-owned ":""}
                {tag2?"#lgbtq-owned ":""}
                {tag3?"#gives back ":""}
                {tag4?"#sustainable ":""} 
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="flex-container cards-container order-container"
      //style={{ minWdth: "45vw" }}
    >
      <div
        className="gift-container order-card-container"
        style={{ flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "5%",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <img src={medalIMG} alt="" style={{ height: "3vh" }} />
          <div style={{ paddingLeft: "10px" }}>Gifts Chosen</div>
        </div>
        <div style={{ padding: "25px 40px" }}>No gifts in the list </div>
      </div>
    </div>
  );
});
