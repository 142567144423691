import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "../../api_conf/BaseUrl";

import Prev from "../../assets/images/previous.svg";
import Close from "../../assets/images/xmark.svg";
import CampaignStart from "./CampaignStart/CampaignStart";
import CurateGift from "./CurateGifts/CurateGift";
import PersonalizedContent from "./PersonalizeEmail/PersonalizedContent";
import SelectedGift from "./CurateGifts/SelectedGift";
import CardLayout from "./PersonalizeEmail/CardLayout";
import DesignCard from "./DesignCard/DesignCard";
import { hideToggleInfo, addPreviewLogo } from "../../store/designCardSlice";

export default function AddCampaign({
  emailHeaderEmpty,
  setEmailHeaderEmpty,
  btnTextEmpty,
  setBtnTextEmpty,
  persDataEmpty,
  designCardEmpty,
  editPersData,
  handleClose,
  getCampaignsApiCall,
  campaign,
  getDataById,
  showEditCampaign,
  handleOutBoundTemplete
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0); // For hanlding pages
  const [campaignId, setCampaignId] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [persEmailDisabled, setPersEmailDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  const [subTypeIdOptions, setSubTypeIdOptions] = useState([
    { id: 3, name: "Yes, for birthdays!" },
    { id: 2, name: "Yes, for work anniversaries please." },
    { id: 1, name: "No, Thanks, This campaign is for immmediate gift sends" }
  ]);
  const [showGifts, setShowGifts] = useState(false);
  const [emailTemplateId, setEmailTemplateId] = useState(0);

  /* Steps created response */
  const [startCampCreated, setStartCampCreated] = useState(false);
  const [curateGiftCreated, setCurateGiftCreated] = useState(false);
  const [personalizeEmailCreated, setPersonalizeEmailCreated] = useState(false);

  const giftAdded = useSelector((state) => state.gifts.giftAdded);
  const logoSelected = useSelector((state) => state.designCard.logoSelected);
  const cardLayoutTemplate = useSelector(
    (state) => state.personalizeEmail.cardLayoutTemplate
  );
  const designLayoutTemplate = useSelector(
    (state) => state.designCard.designLayoutTemplate
  );

  /* Campaign Start data */
  const [formData, setFormData] = useState({
    campaignName: showEditCampaign ? campaign.name : "",
    campaignEndDate: showEditCampaign
      ? new Date(campaign.campaign_end_date)
      : "",
    typeId: "",
    subTypeId:
      showEditCampaign && campaign.campaign_subtype === "Work Anniversary"
        ? { id: 2, name: "Yes, for work anniversaries please." }
        : showEditCampaign && campaign.campaign_subtype === "Birthday"
        ? { id: 3, name: "Yes, for birthdays!" }
        : showEditCampaign && campaign.campaign_subtype === "Manual"
        ? {
            id: 1,
            name: "No, Thanks, This campaign is for immmediate gift sends"
          }
        : "",
    giftExpirationDays: showEditCampaign
      ? campaign.gift_expiration
      : "Tap to unwrap",
    emailFrequencyReminder: showEditCampaign
      ? campaign.email_reminder_frequency
      : "",
    campaignStatus: showEditCampaign ? campaign.campaign_status : "",
    // estimatedCost: showEditCampaign ? campaign.estimated_cost : ""
    estimatedCost: showEditCampaign ? "0" : ""
  });

  /* Personalize email data */
  const [persEmailData, setPersEmailData] = useState({
    subjectLine: showEditCampaign ? editPersData?.template_subject : "",
    subjectLineReminder: showEditCampaign
      ? editPersData?.template_subject_for_reminder
      : "",
    sender: showEditCampaign ? editPersData?.sender_name : "",
    emailHeader: showEditCampaign ? editPersData.email_header : "",
    emailAnimation: [],
    emailLogo: [],
    emailAnimationId: showEditCampaign ? editPersData?.animation_id : null,
    emailLogoId: showEditCampaign ? editPersData?.email_logo : null,
    message: "",
    cardLayoutTemplate: [],
    btnText: showEditCampaign ? editPersData?.button_text : ""
  });

  const [cardMessage, setCardMessage] = useState({
    message: ""
  });

  const background = useSelector((state) => state.designCard.backgroundImage);
  const cardItem = useSelector((state) => state.designCard.card);
  const envelopeColor = useSelector((state) => state.designCard.envelopeColor);
  const backgroundColor = useSelector(
    (state) => state.designCard.backgroundColor
  );
  const alignment = useSelector((state) => state.designCard.alignment);
  const textColor = useSelector((state) => state.designCard.textColor);
  const textSize = useSelector((state) => state.designCard.textSize);
  const letterSpacing = useSelector((state) => state.designCard.letterSpacing);
  const lineHeight = useSelector((state) => state.designCard.lineHeight);
  const letterCasing = useSelector((state) => state.designCard.letterCasing);
  const cardFontFamily = useSelector(
    (state) => state.designCard.cardFontFamily
  );
  const color = useSelector((state) => state.personalizeEmail.color);
  const headerColor = useSelector(
    (state) => state.personalizeEmail.headerColor
  );
  const btnTextColor = useSelector(
    (state) => state.personalizeEmail.btnTextColor
  );

  // **** CAMPAIGN START **** //

  /* Format the campaign start and end date */
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  /* Add campaign post API */
  const addCampaign = async () => {
    try {
      const res = await axios.post("campaigns/", {
        campaign_name: formData?.campaignName,
        campaign_end_date: formatDate(formData?.campaignEndDate),
        type_id:
          formData?.subTypeId.id === 3
            ? 2
            : formData?.subTypeId.id === 2
            ? 2
            : 1,
        subtype_id: formData?.subTypeId.id,
        gift_expiration_days: parseInt(formData?.giftExpirationDays),
        email_frequency_reminder: parseInt(formData?.emailFrequencyReminder),
        campaign_status: "Active",
        estimated_cost: parseInt(formData?.estimatedCost)
      });

      if (res?.data?.success) {
        getCampaignsApiCall();
        setCampaignId(res?.data?.campaign_id);
        setStartCampCreated(true);
        toast.success("Campaign successfully created");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
      setPage(page);

      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  /* Campaign start edit API */
  const handleEditCampaign = async () => {
    try {
      const res = await axios.put("campaigns/", {
        id: showEditCampaign ? campaign?.id : campaignId,
        campaign_name: formData?.campaignName,
        campaign_start_date: "",
        campaign_end_date: formatDate(formData?.campaignEndDate),
        type_id:
          formData.subTypeId.id === 3 ? 2 : formData.subTypeId.id === 2 ? 2 : 1,
        subtype_id: formData.subTypeId.id,
        gift_expiration_days: parseInt(formData?.giftExpirationDays),
        email_reminder_frequency: parseInt(formData?.emailFrequencyReminder),
        campaign_status: "Active",
        estimated_cost: parseInt(formData?.estimatedCost)
      });

      if (res.data.success) {
        if (showEditCampaign) {
          getDataById(campaign.id);
        } else {
          getCampaignsApiCall();
        }
        toast.success(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  // **** CURATE GIFTS **** //

  /* Curate gifts post API */
  const addProductList = async () => {
    try {
      const res = await axios.post("gift/", {
        campaign_id: campaignId,
        product_ids: giftAdded.map((el) => el.id)
      });
      if (res?.data?.success) {
        setCurateGiftCreated(true);
        toast.success("Gifts successfully added");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  /* Curate gifts edit API */
  const editProductList = async () => {
    try {
      const res = await axios.put("gift/", {
        campaign_id: showEditCampaign ? campaign.id : campaignId,
        product_ids: giftAdded.map((el) => el.id)
      });
      if (res?.data?.success) {
        toast.success("Gifts successfully updated");
        if (showEditCampaign) {
          getDataById(campaign.id);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  // **** Personalize email **** //

  /* Personalize email post API */
  const addPersonalizedEmail = async () => {
    try {
      const res = await axios.post("templatebycampaign/", {
        campaign_id: showEditCampaign ? campaign.id : campaignId,
        subject_line: persEmailData.subjectLine,
        subject_line_for_reminder: persEmailData.subjectLineReminder,
        sender: persEmailData.sender,
        email_header: persEmailData.emailHeader,
        animation_id: persEmailData?.emailAnimation[0].id,
        message: "",
        html_template: cardLayoutTemplate,
        email_logo_id: persEmailData?.emailLogo[0].id,
        button_text: persEmailData?.btnText,
        BtnColor: color,
        HeaderColor: headerColor,
        TextColor: btnTextColor
      });

      if (res?.data?.success) {
        // getCampaignsApiCall();
        if (!showEditCampaign) {
          getCampaignsApiCall();
          setPersonalizeEmailCreated(true);
          dispatch(addPreviewLogo(res?.data?.logo));
          setEmailTemplateId(res?.data?.emailtemplate_id);
          toast.success("Email personalize successfully created");
        } else {
          getDataById(campaign.id);
          handleOutBoundTemplete();
          toast.success("Email personalize successfully created");
        }
      }
    } catch (error) {
      setPage(2);
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  /* Personalize email put API */
  const editPersonalizedEmail = async () => {
    try {
      let res = await axios.put("templatebycampaign/", {
        id: showEditCampaign ? editPersData?.id : emailTemplateId,
        subject: persEmailData.subjectLine,
        subject_for_reminder: persEmailData.subjectLineReminder,
        sender_name: persEmailData.sender,
        email_header: persEmailData.emailHeader,
        animation_id: persEmailData?.emailAnimation[0].id,
        message: "",
        html_template: cardLayoutTemplate,
        email_logo_id: persEmailData?.emailLogo[0].id,
        button_text: persEmailData?.btnText,
        BtnColor: color,
        HeaderColor: headerColor,
        TextColor: btnTextColor
      });

      if (res.data.success) {
        if (!showEditCampaign) {
          getCampaignsApiCall();
          dispatch(addPreviewLogo(res?.data?.logo_image));
        } else {
          getDataById(campaign.id);
          dispatch(addPreviewLogo(res?.data?.logo_image));
        }
        toast.success("Email personalize successfully updated");
      }
    } catch (error) {
      if (error.response) {
        setPage(2);
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  // **** Design Card **** //

  /* Design card post API */
  const addDesignCard = async () => {
    try {
      const res = await axios.post("postdesigncard/", {
        campaign_id: showEditCampaign ? campaign.id : campaignId,
        design_card: designLayoutTemplate,
        logo: logoSelected,

        DesignCardImage_id: cardItem?.id,
        DesignBackgroundImage_id: background[0]?.id,
        DesignBackgroundImageColor: backgroundColor,
        EnvelokColor: envelopeColor,
        Message: cardMessage.message,
        MessageFontFamily: cardFontFamily,
        MessageTextSize: textSize,
        MessageAlignment: alignment,
        MessageTextColor: textColor,
        LetterSpacing: letterSpacing,
        LineHeight: lineHeight,
        LetterCasing: letterCasing
      });
      if (res?.data?.success) {
        if (!showEditCampaign) {
          getCampaignsApiCall();
        } else {
          getDataById(campaign.id);
          handleOutBoundTemplete();
        }
        toast.success("Design card successfully added");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  /* Design card put API */
  const editDesignCard = async () => {
    try {
      const res = await axios.put("postdesigncard/", {
        campaign_id: showEditCampaign ? campaign.id : campaignId,
        design_card: designLayoutTemplate,
        logo: logoSelected,
        DesignCardImage_id: cardItem?.id,
        DesignBackgroundImage_id:
          background !== undefined ? background[0]?.id : null,
        DesignBackgroundImageColor: backgroundColor,
        EnvelokColor: envelopeColor,
        Message: cardMessage.message,
        MessageFontFamily: cardFontFamily,
        MessageTextSize: textSize,
        MessageAlignment: alignment,
        MessageTextColor: textColor,
        LetterSpacing: letterSpacing,
        LineHeight: lineHeight,
        LetterCasing: letterCasing
      });

      if (res?.data?.success) {
        if (!showEditCampaign) {
          getCampaignsApiCall();
        } else {
          getDataById(campaign.id);
          handleOutBoundTemplete();
        }
        toast.success("Design card updated");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  /* Stepper form Titles */
  const FormTitles = [
    "Start Campaign",
    "Curate Gift",
    "Personalize Email",
    "Design Landing Page"
  ];

  /* Handling page validation (page0, page1 as of now) */
  const pageValidation = () => {
    if (page === 0) {
      if (!isDisabled) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (page === 1) {
      if (giftAdded?.length >= 2) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (page === 2) {
      if (!persEmailDisabled) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (!showEditCampaign) {
      pageValidation();
    }
  }, [nextDisabled, isDisabled, giftAdded?.length, persEmailDisabled, page]);

  const GiftValidation = () => {
    if (page === 0) {
      setNextDisabled(false);
    } else if (page === 1) {
      if (giftAdded?.length >= 2) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else if (page === 2) {
      setNextDisabled(false);
    }
  };

  useEffect(() => {
    if (showEditCampaign) {
      GiftValidation();
    }
  }, [nextDisabled, giftAdded?.length, page]);



  /* Displays the current page selected */
  const DisplayPage = () => {
    if (page === 0) {
      return (
        <CampaignStart
          page={page}
          FormTitles={FormTitles}
          subTypeIdOptions={subTypeIdOptions}
          formData={formData}
          campaign={campaign}
          showEditCampaign={showEditCampaign}
          setIsDisabled={setIsDisabled}
          setFormData={setFormData}
        />
      );
    } else if (page === 1) {
      return (
        <CurateGift
          page={page}
          FormTitles={FormTitles}
          showGifts={showGifts}
          campaign={campaign}
          showEditCampaign={showEditCampaign}
          setIsDisabled={setIsDisabled}
          setShowGifts={setShowGifts}
        />
      );
    } else if (page === 2) {
      return (
        <PersonalizedContent
          page={page}
          FormTitles={FormTitles}
          persEmailData={persEmailData}
          showEditCampaign={showEditCampaign}
          setPersEmailData={setPersEmailData}
          persEmailDisabled={persEmailDisabled}
          setPersEmailDisabled={setPersEmailDisabled}
          handleOutBoundTemplete={handleOutBoundTemplete}
          setEmailHeaderEmpty={setEmailHeaderEmpty}
          setBtnTextEmpty={setBtnTextEmpty}
        />
      );
    } else {
      return (
        <DesignCard
          page={page}
          FormTitles={FormTitles}
          cardMessage={cardMessage}
          setCardMessage={setCardMessage}
          campaign={campaign}
          showEditCampaign={showEditCampaign}
          alignment={alignment}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <div className="add_campaign_wrapper">
        <div className="add_campaign_container">
          <div className="add_campaign_titles">
            <span
              className="campaign_title"
              style={{ color: page === 0 && "#fff" }}
            >
              <h4>1</h4> <h6>Start Campaign</h6>
            </span>
            <span
              className="campaign_title"
              style={{ color: page === 1 && "#fff" }}
            >
              <h4>2</h4> <h6>Curate Gift</h6>
            </span>
            <span
              className="campaign_title"
              style={{ color: page === 2 && "#fff" }}
            >
              <h4>3</h4> <h6>Personalize Email</h6>
            </span>
            <span
              className="campaign_title"
              style={{ color: page === 3 && "#fff" }}
            >
              <h4>4</h4> <h6>Design Card</h6>
            </span>
          </div>

          <div className="add_campaign_handle">
            <button
              className="add_campaign_prev_btn"
              disabled={page === 0}
              onClick={() => {
                dispatch(hideToggleInfo());
                setPage((currPage) => currPage - 1);
              }}
            >
              <img
                src={Prev}
                alt="Previous"
                className="add_campaign_prev_img"
              />
            </button>

            <div className="progressbar">
              <div
                style={{
                  width:
                    page === 0
                      ? "25%"
                      : page === 1
                      ? "50%"
                      : page === 2
                      ? "75%"
                      : "100%",
                  height: "10px",
                  borderRadius: "8px",
                  backgroundColor: "#FFFFFF"
                }}
              />
            </div>

            <button
              className="add_campaign_next_btn"
              disabled={nextDisabled}
              onClick={() => {
                if (page === FormTitles.length - 1) {
                  if (designCardEmpty === false) {
                    editDesignCard();
                  } else {
                    addDesignCard();
                  }

                  handleClose();
                } else if (page === 0) {
                  if (!startCampCreated && !showEditCampaign) {
                    addCampaign();
                  } else {
                    handleEditCampaign();
                  }

                  setNextDisabled(true);
                  setPage((currPage) => currPage + 1);
                } else if (page === 1) {
                  if (!curateGiftCreated && !showEditCampaign) {
                    addProductList();
                  } else {
                    editProductList();
                  }

                  setNextDisabled(true);
                  setPage((currPage) => currPage + 1);
                } else if (page === 2) {
                  if (!personalizeEmailCreated && !showEditCampaign) {
                    addPersonalizedEmail();
                  } else if (persDataEmpty === true) {
                    addPersonalizedEmail();
                  } else {
                    editPersonalizedEmail();
                  }
                  setPage((currPage) => currPage + 1);
                }
              }}
            >
              {page === 3 ? "Done" : "Next"}
            </button>

            <button
              className="add_campaign_close_btn"
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={Close}
                style={{ height: "34px", width: "34px" }}
                alt="Close campaign layout"
              />
            </button>
          </div>
          <div style={{ display: "flex" }}>
            <div className="add_campaign_form">
              <div className="add_campaign_body">{DisplayPage()}</div>
            </div>
            {showGifts && page === 1 ? <SelectedGift /> : null}
            {page === 2 && (
              <CardLayout
                emailHeaderEmpty={emailHeaderEmpty}
                btnTextEmpty={btnTextEmpty}
                persEmailData={persEmailData}
                setPersEmailData={setPersEmailData}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
