import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";

import {
  addBackgroundColor,
  hideBackgroundColorPicker
} from "../../../../store/designCardSlice";

import useClickOutside from "../../custom/useClickOutside";

function BackgrdoundColors() {
  const popover = useRef();
  const dispatch = useDispatch();
  const backgroundColor = useSelector(
    (state) => state.designCard.backgroundColor
  );
  const [color, setColor] = useState(backgroundColor);

  const close = useCallback(() => dispatch(hideBackgroundColorPicker()), []);
  useClickOutside(popover, close);

  return (
    <div className="envelope_color_picker" ref={popover}>
      <SketchPicker
        color={color}
        onChange={(updatedColor) =>
          dispatch(addBackgroundColor(updatedColor.hex))
        }
      />
    </div>
  );
}

export default BackgrdoundColors;
