import React from "react";

import "./ShowGift.scss";

const GiftLoader = () => {
  return (
    <div className="select_gift_loader_container">
      <div className="select_gift_loader"></div>
    </div>
  );
};

export default GiftLoader;
