import React, { useState, useEffect } from "react";
import axios from "../../../api_conf/BaseUrl";

import PersonalizedEmail from "./PersonalizedEmail";
import "./PersonalizeEmail.scss";

const PersonalizedContent = ({
  page,
  FormTitles,
  persEmailData,
  showEditCampaign,
  setPersEmailData,
  setPersEmailDisabled,
  setEmailHeaderEmpty,
  setBtnTextEmpty,
}) => {
  const [base64Image, setBase64Image] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [animationLoading, setAnimationLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);


  useEffect(() => {
    getAnimation();
  }, []);

  const getAnimation = async () => {
    setAnimationLoading(true);
    try {
      const res = await axios.get("animation/");
      if (res?.data?.success) {
        setBase64Image(res?.data?.response);
      }
      setAnimationLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLogo();
  }, []);

  const getLogo = async () => {
    setLogoLoading(true);
    try {
      const res = await axios.get("logo/");
      if (res?.data?.success) {
        setLogoImage(res?.data?.response);
      }
      setLogoLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const setAnimation = () => {
    const animation = base64Image?.filter(
      (currElem) => currElem?.id === parseInt(persEmailData?.emailAnimationId)
    );
    setPersEmailData({
      ...persEmailData,
      emailAnimation: animation
    });
  };

  useEffect(() => {
    if (showEditCampaign && page === 2) {
      setAnimation();
    }
  }, [base64Image]);

  const setLogo = () => {
    const logo = logoImage?.filter(
      (currElem) => currElem.id === parseInt(persEmailData?.emailLogoId)
    );
    setPersEmailData({
      ...persEmailData,
      emailLogo: logo
    });
  };

  useEffect(() => {
    if (showEditCampaign) {
      setLogo();
    }
  }, [logoImage]);

 

  return (
    <div className="personalize_email">
      <div className="personalize_email_header">
        <h5>{FormTitles[page]}</h5>
      </div>
      <div className="personalize_email_wrapper">
        <PersonalizedEmail
          logoLoading={logoLoading}
          animationLoading={animationLoading}
          showEditCampaign={showEditCampaign}
          logoImage={logoImage}
          base64Image={base64Image}
          getLogo={getLogo}
          getAnimation={getAnimation}
          persEmailData={persEmailData}
          setPersEmailData={setPersEmailData}
          setPersEmailDisabled={setPersEmailDisabled}
          setEmailHeaderEmpty={setEmailHeaderEmpty}
          setBtnTextEmpty={setBtnTextEmpty}
        />
      </div>
    </div>
  );
};

export default PersonalizedContent;
