import React, { useEffect, useState } from "react";

import axios from "../../api_conf/BaseUrl";
import API from "../../api_conf/ApiUrls";
import { handleSignOut } from "../../store/toolkit";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import TemplateEditor from "./TemplateEditor";
import BackArrow from "../../assets/images/backArrow";
import AddOutboundTemplate from "../../assets/images/outbound_template_add_circle.svg";
import EditOutboundTemplateIcon2 from "../../assets/images/EditIcon.svg";

import Loader from "./Loader";

import "./OutBoundEmail.scss";

export default function OutBoundEmail() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [campaign, setCampaign] = useState({});
  const [template, settemplate] = useState(null);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateReminder, setTemplateReminder] = useState("");
  const [senderName, setSenderName] = useState("");
  const [showEditor, setShowEditor] = useState(false);
  const [templateId, setTemplateId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleOutBoundTemplete = async (val) => {
    setIsLoading(true);
    try {
      let res = await axios.get("templatebycampaign/", {
        params: {
          campaign_id: val
        }
      });

      if (res.data.success) {
        setSenderName(res?.data?.sender_name);
        setTemplateSubject(res?.data?.template_subject);
        setTemplateReminder(res?.data?.template_subject_for_reminder);
        settemplate(res?.data?.html_template);
        setTemplateId(res?.data?.id);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataById = async (currentActiveCampaign) => {
    try {
      const { data } = await axios.get(API.CAMPAIGN_WITH_ID, {
        params: {
          id: currentActiveCampaign
        }
      });
      if (data) {
        setCampaign(data.campaign);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getDataById(id);
      handleOutBoundTemplete(id);
    }
  }, [id]);

  const Header = () => {
    return (
      <div className="header__flex-container">
        <div
          className="header__flex-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/dashboard/campaign/${id}`);
          }}
        >
          <BackArrow size={50} color={"#219ebc"} />
        </div>
        <div className="header__flex-item">
          <h5>{campaign.name}</h5>
        </div>
        <div className="header__flex-item">
          {!showEditor && (
            <button
              className="sign"
              onClick={() => {
                dispatch(handleSignOut());
                localStorage.clear();
                navigate("/");
              }}
            >
              sign out
            </button>
          )}
        </div>
      </div>
    );
  };

  const closeEditor = () => {
    setShowEditor(false);
    handleOutBoundTemplete(id);
    document.body.style.overflow = "unset";
  };

  console.log(template);

  const EditorModal = () => {
    return (
      <div className="editor_modal">
        <div className="editor_container">
          <div className="editor_body">
            <TemplateEditor
              id={id}
              templateId={templateId}
              // template={template}
              // settemplate={settemplate}
              // templateSubject={templateSubject}
              // templateReminder={templateReminder}
              // senderName={senderName}
              // setTemplateSubject={setTemplateSubject}
              // setTemplateReminder={setTemplateReminder}
              // setSenderName={setSenderName}
              closeEditor={closeEditor}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="outbound_email_template_container">
        <div className="editor_details">
          <div className="editor_details_content">
            <div className="editor_details_label">
              <h6>Sender Name:</h6>
            </div>
            <div className="editor_details_name">
              <h6>{senderName}</h6>
            </div>
          </div>
          <div className="editor_details_content">
            <div className="editor_details_label">
              <h6>Subject Line:</h6>
            </div>
            <div className="editor_details_name">
              <h6>{templateSubject}</h6>
            </div>
            <div className="editor_details_name_tooltip">
              <h6>{templateSubject}</h6>
            </div>
          </div>
          <div className="editor_details_content">
            <div className="editor_details_label">
              <h6>Subject Line For Reminder:</h6>
            </div>
            <div className="editor_details_name">
              <h6>{templateReminder}</h6>
            </div>
            <div className="editor_details_name_tooltip">
              <h6>{templateReminder}</h6>
            </div>
          </div>
        </div>
        {template?.length > 0 ? (
          <div className="edit_container">
            {template?.length > 0 && (
              <div className="edit_btn">
                <button
                  className="edit_modal_btn"
                  onClick={() => {
                    setShowEditor(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <img
                    src={EditOutboundTemplateIcon2}
                    alt="Add outbound template"
                    className="edit_template_icon"
                  />
                  <h2 className="edit_btn_title">Edit</h2>
                </button>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: template
              }}
              style={{
                height: "100%"
              }}
              className="emailtemplateContainer"
            />
          </div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <div className="empty_template">
            <p style={{ marginTop: "20px" }}>No template present</p>
            <button
              className="add_modal_btn"
              onClick={() => {
                setShowEditor(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <img
                src={AddOutboundTemplate}
                alt="Add outbound template"
                className="template_icons"
              />
            </button>
          </div>
        )}

        <style jsx>
          {`
            table {
              height: 100%;
              margin-top: 0;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
            }
            
           
            // .personalize_live_email_header_title {
            //   font-size: 1.2rem !important;
            //   background-color: green;
            // }

            // .ck-editor__editable .ck-editor__nested-editable {
            //   background-color: #000 !important;
            // }

            img {
              width: 100%;
              object-fit: contain;
            }
          `}
        </style>

        {showEditor && <EditorModal />}
      </div>
    </>
  );
}
