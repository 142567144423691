import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleSignOut } from "../../../store/toolkit";
import useWindowDimensions from "./useWindowDimensions";

import SearchIcon from "../../../assets/images/dashboard_search.svg";
import DashboardNotification from "../../../assets/images/dashboard_notification_icon.svg";
import "./DashboardSearch.scss";

const DashboardSearch = ({
  search,
  setSearch,
  setPage,
  setCampaignList,
  showNotifications,
  setShowNotifications
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const count = useSelector((state) => state.user.notifications);

  useEffect(() => {
    if (width > 1450) {
      setShowNotifications(false);
    }
  }, [width]);

  const handleSearch = (event) => {
    setPage(1);
    setCampaignList([]);
    setSearch(event.target.value);
  };

  const toggleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="dashboard_search_container">
      <div className="dashboard_search_inner">
        <img
          src={SearchIcon}
          className="dashboard_search_icon"
          title="Search Campaigns"
          alt="Search Campaigns"
        />
        <input
          className="dashboard_seach_input"
          type="search"
          value={search}
          placeholder="Find a Campaign"
          onChange={handleSearch}
        />
      </div>
      <div className="dashboard_search_signout_container">
        <div
          className="dashboard_search_notifications_icon"
          onClick={toggleShowNotifications}
        >
          <div className="dashboard_search_notifications_count">{count}</div>
          <img src={DashboardNotification} alt="Dashboard Notification" />
        </div>

        <button
          className="dashboard_search_signout"
          onClick={() => {
            dispatch(handleSignOut());
            localStorage.clear();
            navigate("/");
          }}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default DashboardSearch;
