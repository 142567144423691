import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../api_conf/BaseUrl";
import {
  addBackgroundImage,
  showBackgroundColorPicker
} from "../../../store/designCardSlice";
import CheckSecondary from "../../../assets/images/check_secondary.svg";

const ChooseBackground = ({ multiSelect = false }) => {
  const dispatch = useDispatch();
  const backgroundImages = useSelector(
    (state) => state.designCard.backgroundImages
  );
  const [data, setData] = useState(backgroundImages);
  const backgroundColor = useSelector(
    (state) => state.designCard.backgroundColor
  );

  const backgroundImage = useSelector(
    (state) => state.designCard.backgroundImage
  );

  const handleAddBackground = (item) => {
    if (!backgroundImage?.some((current) => current?.id === item.id)) {
      if (!multiSelect) {
        dispatch(addBackgroundImage([item]));
      } else if (multiSelect) {
        dispatch(addBackgroundImage([...backgroundImage, item]));
      }
    } else {
      let resultAfterRemoval = backgroundImage;
      resultAfterRemoval = resultAfterRemoval?.filter(
        (current) => current.id !== item.id
      );

      dispatch(addBackgroundImage([...resultAfterRemoval]));
    }
  };

  const isItemInSelection = (item) => {
    if (backgroundImage?.find((current) => current?.id === item?.id)) {
      return true;
    }
    return false;
  };

  const handleShowColorPicker = () => {
    dispatch(showBackgroundColorPicker());
    dispatch(addBackgroundImage([]))
  };


  return (
    <React.Fragment>
      {data.map((item) => {
        return (
          <div
            key={item.id}
            className="design_background_image_container"
            onClick={() => handleAddBackground(item)}
          >
            <span>
              {isItemInSelection(item) && (
                <button className="choose_background_btn">
                  <img
                    className="choose_background_icon"
                    src={CheckSecondary}
                    alt="Choose background"
                  />
                </button>
              )}
            </span>
            <img
              src={axios.defaults.devMediaUrl + item.image}
              alt={item.name}
              className="design_background_image"
            />
          </div>
        );
      })}
      <div className="choose_background_color_picker">
        Color:{" "}
        <span
          style={{ backgroundColor: backgroundColor }}
          onClick={handleShowColorPicker}
        ></span>
      </div>
    </React.Fragment>
  );
};

export default ChooseBackground;
