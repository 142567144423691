import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckPrimary from "../../../assets/images/check_primary.svg";
import CheckSecondary from "../../../assets/images/check_secondary.svg";
import SoldOut from "../../../assets/images/sold-out.svg";

import { handleGifts, addGiftId } from "../../../store/giftSlice";

const FilterCard = (props) => {
  const dispatch = useDispatch();
  const giftAdded = useSelector((state) => state.gifts.giftAdded);

  const handleSliderGifts = (id) => {
    dispatch(addGiftId(id));
    props.setShowGifts(true);
  };

  const singlePrice = props?.variants[0]?.price;

  const handleGiftsData = (item) => {
    if (!giftAdded.some((currElem) => currElem.id === item.id)) {
      dispatch(handleGifts([...giftAdded, item]));
    } else {
      let giftAfterRemoval = giftAdded;
      giftAfterRemoval = giftAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleGifts([...giftAfterRemoval]));
    }
  };

  const giftsInSelection = (item) => {
    if (giftAdded.find((currElem) => currElem.id === item.id)) {
      return true;
    }

    return false;
  };

  return (
    <React.Fragment>
      <div className="curate_filter_card">
        <div className="curate_filter_image_container">
          {props.sold_out && (
            <div className="curate_gifts_sold_out">
              <img src={SoldOut} alt="Sold out" />
            </div>
          )}

          <button
            className={
              giftsInSelection(props)
                ? "curate_remove_gifts"
                : "curate_add_gifts"
            }
            style={{ display: props.sold_out && "none" }}
            onClick={() => handleGiftsData(props)}
          >
            {giftsInSelection(props) ? (
              <img
                className="curate_remove_gift_icon"
                src={CheckSecondary}
                alt="Check"
              />
            ) : (
              <img
                className="curate_add_gift_icon"
                src={CheckPrimary}
                alt="Check"
              />
            )}
          </button>
          <img
            className="curate_filter_image"
            src={props?.image?.src}
            alt={props?.alt}
            onClick={() => handleSliderGifts(props?.idx)}
          />
        </div>
        <div
          className="curate_filter_info"
          onClick={() => handleSliderGifts(props?.idx)}
        >
          {props?.title?.length > 12 ? (
            <h6 className="curate_filter_name">{`${props?.title.slice(
              0,
              12
            )}...`}
            <span className="curate_filter_name_tooltip_text">{props.title}</span>
            </h6>
          ) : (
            <h6 className="curate_filter_name">{props?.title}</h6>
          )}

          <h6 className="curate_filter_price">${singlePrice}</h6>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterCard;
