import React, { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import Edit from "../../assets/images/edit.svg";
import { useLocation } from "react-router-dom";
export default function AppendList({
  user,
  deleteUser,
  changeUserList,
  selectedUsers,
  setShowUserEdit,
  updateIdEmail,
  setShowUserMove,
}) {
  const location = useLocation();
  const [list, setList] = useState([]);
  useEffect(() => {
    var postArr = [];
    if (user.listing && user.listing.length > 0) {
      setList([]);
      user.listing.map((post) => {
        postArr.push(post);
      });
    } else {
      postArr.push(user.group_name);
    }
    setList(postArr);
  }, [user]);

  const getFormatedBirthDate = (date) => {
    return moment.utc(date).format("MM/DD");
  };

  const getFormatedJoiningDate = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  const getUserCreatedAt = (date) => {
    return moment.utc(date).format("MM/DD/YYYY");
  };

  return (
    <div className="row">
      <div className="col list_content_item">
        <div
          className="form-check-list"
          style={{ fontWeight: "400", fontSize: "13px", color: "#707683",gap:"10px" }}
        >
          <input
            className="user_check"
            type="checkbox"
            style={{ transform: "scale(1.3)" }}
            onChange={(e) => changeUserList(e, user.id)}
            checked={selectedUsers.includes(user.id) ? true : false}
          />
          {user.first_name}
        </div>
      </div>
      <div
        className="col list_content_item"
        style={{ fontWeight: "400", fontSize: "13px", color: "#707683" }}
      >
        {" "}
        {user.last_name}{" "}
      </div>
      <div
        className="col list_content_item"
        style={{ fontWeight: "400", fontSize: "13px", color: "#707683" }}
      >
        {user.email}
      </div>
      <div
        className="col list_content_item"
        style={{ fontWeight: "400", fontSize: "13px", color: "#707683" }}
      >
        {list.toString()}
      </div>
      <div
        className="col list_content_item"
        style={{ fontWeight: "400", fontSize: "13px", color: "#707683" }}
      >
        {" "}
        {user.company}{" "}
      </div>
      <div
        className="col list_content_item"
        style={{ fontWeight: "400", fontSize: "13px", color: "#707683" }}
      >
        {user.date_of_birth
          ? getFormatedBirthDate(user.date_of_birth)
          : "Not available"}
      </div>
      <div
        className="col list_content_item"
        style={{
          fontWeight: "400",
          fontSize: "13px",
          color: "#707683",
          width: "400px !important",
        }}
      >
        {user.date_of_joining
          ? getFormatedJoiningDate(user.date_of_joining)
          : "Not available"}

        <span
          className="custom_icon_theme"
          title="Edit this user"
          style={
            location.pathname.includes("user")
              ? { marginRight: "32px" }
              : { marginRight: "10px", left: "98px" }
          }
          onClick={() => {
            updateIdEmail(user.id, user.email);
            setShowUserEdit(true);
          }}
        >
          <img width={"18px"} height={"18px"} src={Edit} alt="edit icon" />
        </span>

        {location.pathname.includes("user") ? (
          <span
            className="custom_icon_theme"
            title="move/copy the selected user"
            onClick={setShowUserMove}
            style={{ marginRight: "18px", color: "#219EBC" }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </span>
        ) : (
          ""
        )}

        {user?.listing?.length > 0 ? (
          <span
            className="custom_icon_theme"
            title="Delete this user"
            onClick={deleteUser}
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        ) : (
          String(" ", user?.listing?.length)
        )}
      </div>
    </div>
  );
}
