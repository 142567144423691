export const styles = {
  control: (provided, styles, state) => ({
    ...provided,
    cursor: "pointer",
    fontFamily: "DM Sans, sans-serif",
    fontSize: "1.06rem",
    color: "#b7b7b7",
    backgroundColor: "#f7f7f7",
    border: "none",
    textAlign: "start",
    "@media only screen and (max-height: 961px)": {
      ...styles["@media only screen and (max-height: 961px)"],
      fontSize: "1.06rem"
    },
    "@media only screen and (max-height: 658px)": {
      ...styles["@media only screen and (max-height: 658px)"],
      fontSize: "0.8rem"
    },

    "@media only screen and (max-height: 558px)": {
      ...styles["@media only screen and (max-height: 558px)"],
      fontSize: "0.65rem"
    },
    "@media only screen and (max-height: 475px)": {
      ...styles["@media only screen and (max-height: 575px)"],
      fontSize: "0.55rem"
    }
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: "1.06rem",
    "@media only screen and (max-height: 961px)": {
      ...styles["@media only screen and (max-height: 961px)"],
      fontSize: "`1.06rem"
    },
    "@media only screen and (max-height: 658px)": {
      ...styles["@media only screen and (max-height: 658px)"],
      fontSize: "0.95rem"
    },

    "@media only screen and (max-height: 558px)": {
      ...styles["@media only screen and (max-height: 558px)"],
      fontSize: "0.65rem"
    },
    "@media only screen and (max-height: 475px)": {
      ...styles["@media only screen and (max-height: 575px)"],
      fontSize: "0.55rem"
    }
  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "1.06rem",
    "@media only screen and (max-height: 761px)": {
      ...styles["@media only screen and (max-height: 861px)"],
      fontSize: "0.8rem"
    },
    "@media only screen and (max-height: 634px)": {
      ...styles["@media only screen and (max-height: 834px)"],
      fontSize: "0.8rem"
    },

    "@media only screen and (max-height: 558px)": {
      ...styles["@media only screen and (max-height: 558px)"],
      fontSize: "0.65rem"
    },
    "@media only screen and (max-height: 475px)": {
      ...styles["@media only screen and (max-height: 575px)"],
      fontSize: "0.55rem"
    }
  })
};


