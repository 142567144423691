export const validateCustomizeSettings = (formData, setIsDisabled,) => {
  if (
    formData?.campaignName?.length > 0 &&
    formData?.campaignEndDate > 0 &&
    formData?.subTypeId?.id > 0 &&
    formData?.giftExpirationDays > 0 &&
    formData?.emailFrequencyReminder > 0 &&
    formData?.estimatedCost > 0
  ) {
    setIsDisabled(false);
  }

  if (
    formData?.campaignName?.trim() === "" ||
    formData?.campaignEndDate === "" ||
    formData?.campaignEndDate === null ||
    formData?.subTypeId?.id === undefined ||
    formData?.subTypeId?.id === "" ||
    formData?.giftExpirationDays === "" ||
    formData?.giftExpirationDays === 0 ||
    formData?.emailFrequencyReminder === "" ||
    formData?.emailFrequencyReminder === 0 ||
    formData?.estimatedCost === "" ||
    formData?.estimatedCost === 0
  ) {
    setIsDisabled(true);
  }
};