import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch } from "react-redux";

import { enableLogo, disableLogo } from "../../../store/designCardSlice";
import { useSelector } from "react-redux";

const IOSSwitch = withStyles((theme,props) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginRight: "-20px",
    transform: "scale(0.9)",
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    transform:"translateX(-16px)",
    "&$checked": {
      transform:"translateX(0px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default function ToggleButton() {
  const [checked, setChecked] = useState(false);
  const logoSelected = useSelector((state) => state.designCard.logoSelected);
  const dispatch = useDispatch();
  
  const handleChange = () => {
    setChecked(!checked);
  };
  
  console.log(logoSelected);
  useEffect(() => {
    if (logoSelected) {
      setChecked(true);
    }
  }, [logoSelected]);

  useEffect(() => {
    if (checked) {
      dispatch(enableLogo());
    } else {
      dispatch(disableLogo());
    }
  }, [checked]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChange}
            name="checkedB"
          />
        }
      />
    </FormGroup>
  );
}
