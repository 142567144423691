export const envelopeColors = [
  {
    id: 0,
    color: "#a4508b"
  },
  {
    id: 1,
    color: "#bd4f6c"
  },
  {
    id: 2,
    color: "#20bf55"
  },
  {
    id: 3,
    color: "#ffa69e"
  },
  {
    id: 4,
    color: "#380036"
  },
  {
    id: 5,
    color: "#7ee8fa"
  },
  {
    id: 6,
    color: "#a40606"
  },
  {
    id: 7,
    color: "#8693ab"
  },
  {
    id: 8,
    color: "#0d324d"
  },
  {
    id: 9,
    color: "#e7a977"
  },
  {
    id: 10,
    color: "#fc575e"
  },
  {
    id: 11,
    color: "#a88beb"
  },
  {
    id: 12,
    color: "#ffd8cb"
  }
];
