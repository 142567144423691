import React, { useEffect, useState } from "react";
import { Navigate} from "react-router-dom";

import "./Users.scss";
import CustomList from "../../Reusables/CustomList/CustomList";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "../../api_conf/BaseUrl";

import API from "../../api_conf/ApiUrls";

import { saveUserListData, handleSignOut } from "../../store/toolkit";
export default function Users(props) {
  const dispatch = useDispatch();
  const [noOfPages, setNoOfPages] = React.useState(null);
  const [userList, setUserList] = React.useState([]);
  const [currentpage, setCurrentPage] = React.useState(1);
  const [isDataLoaded, setisDataLoaded] = React.useState(true);
  const [groupName, setGroupName] = useState(null);
  const [workingYears, setWorkingYears] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!isDataLoaded) userListHandler();
  }, []);

  useEffect(() => {
    if (groupName) {
      getUserByGroup(groupName);
    } else {
      userListHandler();
    }
  }, [currentpage, workingYears, searchValue]);

  let changeUserList;

  const getUserByGroup = async (listName) => {
    try {
      if (!listName) {
      } else {
        let data;
        let url;

        url = "userbylist/";
        data = {
          page: currentpage,
          group_name: listName === "All" ? null : listName
        };

        const res = await axios.get(url, {
          params: data,
          paramsSerializer: (params) => {
            let result = "";
            Object.keys(params).forEach((key) => {
              result += `${key}=${encodeURIComponent(params[key])}&`;
            });
            return result.substr(0, result.length - 1);
          }
        });

        if (res.data.success) {
          let data = {
            no_of_pages: res.data.num_of_pages,
            currentpage: currentpage,
            userList: res.data.response
          };
          console.log("data", data);
          // console.log("No. of pages", currentpage, res.data.num_of_pages);
          setCurrentPage(currentpage);
          setUserList(res.data.response);
          setNoOfPages(res.data.num_of_pages);
          setisDataLoaded(true);
        }
      }
    } catch (error) {
      let data = {
        no_of_pages: 0,
        currentpage: 1,
        userList: []
      };
      dispatch(saveUserListData(data));
    }
  };

  const userListHandler = async () => {
    try {
      setIsLoaded(true);
      let data;
      let url;

      url = "users/";
      data = {
        page: currentpage,
        string: searchValue,
        ...(workingYears && {
          working_years: workingYears
        })
      };
      const res = await axios.get(url, {
        params: data,
        paramsSerializer: (params) => {
          let result = "";
          Object.keys(params).forEach((key) => {
            result += `${key}=${encodeURIComponent(params[key])}&`;
          });
          return result.substr(0, result.length - 1);
        }
      });

      if (res.data.success) {
        // setCurrentPage(currentpage);

        let userListArray = res.data.response.map((item) => ({
          ...item,
          isChecked: false
        }));

        setUserList(userListArray);
        setNoOfPages(res.data.num_of_pages);
        setisDataLoaded(true);
        setIsLoaded(false);
      }
    } catch (error) {
      let data = {
        no_of_pages: 0,
        currentpage: 1,
        userList: []
      };
      dispatch(saveUserListData(data));
      if (error && error.response && error.response.status === 401) {
        dispatch(handleSignOut());
        localStorage.clear();
        <Navigate to="/login" />;
      }
    }
  };

  const addListHandler = () => {
    toast.success("List added successfully!");
  };

  const showAddUserFormHandler = () => {
    console.log("[Users.js] showAddUserFormHandler");
  };

  const addUserHandler = (user) => {
    const body = {
      first_name: user.firstname,
      last_name: user.lastname,
      email: user.email,
      listing: user.list,
      company: user.company,
      date_of_birth: user.birthdate,
      date_of_joining: user.joiningDate,
    };

    axios
      .post(API.USERS, body)
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          console.log(result);
          console.log("USER ADDED SUCCESSFULLY", user.list);
          if (user.list) {
            getUserByGroup(user.list);
          } else {
            userListHandler();
            //console.log(user);
          }
          return result;
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  

  const uploadUsersHandler = ($event) => {
    const body = new FormData();
    setDataUploaded(true);
    body.append("csvfile", $event.target.files[0]);
    axios
      .post(API.UPLOAD_CSV_FILE, body)
      .then((result) => {
        if (result.data.success) {
          toast.success(result.data.message);
          userListHandler();
          setDataUploaded(false);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };
  const handlepageChange = (val, groupName) => {
    setCurrentPage(val);
    setGroupName(groupName);
  };

  const deleteUserHandler = (user) => {
    axios
      .delete("deleteusergroup/", { data: { user_id: user.id } })
      .then((result) => {
        if (result.data.success) {
          toast.success("All lists deleted successfully!");
          userListHandler();
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  const handleApiCall = (val) => {
    if (val === "All") {
      userListHandler();
    } else {
      getUserByGroup(val);
    }
  };
  const resetCurrentPage = (val) => {
    setCurrentPage(1);
    handleApiCall(val);
  };
  return (
    <div style={{minHeight:`100vh`}}>
      {/* <div className="signOutButton">
        <button
          className="sign"
          onClick={() => {
            dispatch(handleSignOut());
            localStorage.clear();
            navigate("/");
          }}
        >
          sign out
        </button>
      </div> */}
      <CustomList
        usersList={userList}
        setUserList={setUserList}
        changeList={changeUserList}
        addList={addListHandler}
        showFrom={showAddUserFormHandler}
        addUserList={addUserHandler}
        uploadUsers={uploadUsersHandler}
        deleteUser={deleteUserHandler}
        allusersList={userListHandler}
        no_of_pages={noOfPages}
        setNoOfPages={setNoOfPages}
        currentpage={currentpage}
        setCurrentPage={() => setCurrentPage(1)}
        handlepageChange={handlepageChange}
        resetCurrentPage={resetCurrentPage}
        setWorkingYears={setWorkingYears}
        subType={props.campaign_sub_type}
        isDataLoaded={isDataLoaded}
        setIsLoaded={setIsLoaded}
        isLoaded={isLoaded}
        dataUploaded={dataUploaded}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setGroupName={setGroupName}
        sendingGiftButton={props.sendingGiftButton}
        handleShowModal={props.handleShowModal}
      />
    </div>
  );
}
