/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import "./Login.scss";
import loginLogo from "../../assets/images/kaido_logo_light.svg";

import axios from "../../api_conf/BaseUrl";
import API from "../../api_conf/ApiUrls";
import { saveUserData } from "../../store/toolkit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Login = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
    if (cookies) {
      setUsername(cookies.Name);
      setPassword(cookies.Password);
    }
    if (cookies.Name && cookies.Password) {
      setRemember(true);
    }
  }, []);
  const formValidation = () => {
    let usernameError = "";
    let passwordError = "";

    if (username) {
      setUsernameError("");
    }

    if (password) {
      setPasswordError("");
    }

    if (!username) {
      usernameError = "Username can't be empty";
    }

    if (!password) {
      passwordError = "Password can't be empty";
    }

    if (usernameError) {
      setUsernameError(usernameError);
      return false;
    }

    if (passwordError) {
      setPasswordError(passwordError);
      return false;
    }

    if (usernameError || passwordError) {
      setUsernameError(usernameError);
      setPasswordError(passwordError);

      return false;
    }

    return true;
  };

  const performLogin = (event) => {
    event.preventDefault();

    const isValid = formValidation();
    if (isValid) {
      setUsernameError("");
      setPasswordError("");
    }

    if (!username) {
      setUsernameError("Username can't be empty");
    }

    if (!password) {
      setPasswordError("Password can't be empty");
    }
    if (username !== "" && password !== "") {
      if (remember === true) {
        setCookie("Name", username);
        setCookie("Password", password);
        console.log("Cookies set");
      } else {
        removeCookie("Name");
        removeCookie("Password");
      }
    }
    axios
      .post(API.LOGIN, {
        email: username,
        password: password
      })
      .then((result) => {
        toast.success(result.data.message);
        if (result.data.success) {
          dispatch(saveUserData(result.data.response));
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        localStorage.removeItem("flex_id");
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      });
  };

  const handleUserChange = (e) => {
    if (e.target.value.length > 0) {
      setUsernameError("");
    }

    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    if (e.target.value.length > 0) {
      setPasswordError("");
    }

    setPassword(e.target.value);
  };

  return (
    <div className="LoginContainer">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <div className="vertical_container">
        <img className="main_logo" src={loginLogo} alt="logo_kaido" />
        <p className="welcome_note">Welcome to Your Gifting Sidekick</p>

        <div className="form_wrapper">
          <div className="form_container">
            {/* <h3> Welcome</h3> */}
            <form>
              <div className="form_controls">
                <label>USERNAME</label>
                <input
                  type="text"
                  // onChange={(event) => setUsername(event.target.value)}
                  onChange={handleUserChange}
                  value={username}
                  style={{
                    padding: "10px",
                    minWidth: "100%",
                    width: "100%",
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "4px",
                    border: "1px solid #E9EFF4",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(50, 60, 71, 0.49)",
                    textAlign: "center"
                  }}
                />

                <span className="login_input_error">{usernameError}</span>
              </div>
              <div className="form_controls">
                <label>PASSWORD</label>
                <input
                  type="password"
                  // onChange={(event) => setPassword(event.target.value)}
                  onChange={handlePasswordChange}
                  value={password}
                  style={{
                    webkitAppearance: "none",
                    mozAppearance: "none",
                    appearance: "none",
                    padding: "10px",
                    minWidth: "100%",
                    width: "100%",
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "4px",
                    border: "1px solid #E9EFF4",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(50, 60, 71, 0.49)",
                    textAlign: "center"
                  }}
                />
                <span className="login_input_error">{passwordError}</span>
              </div>
              <div className="other_mandatory_text">
                <a
                  onClick={() => {
                    props.onForgotPassword(true);
                  }}
                >
                  Forgot Password?
                </a>
                <div className="remember_me">
                  <input
                    type="checkbox"
                    checked={remember}
                    onClick={() => {
                      setRemember(!remember);
                    }}
                    id="rem_me"
                  />
                  <label htmlFor="rem_me">Remember Me</label>
                </div>
              </div>
              <button
                onClick={(event) => performLogin(event)}
                className="btn sign_in_btn"
              >
                Sign In
              </button>
            </form>

            <div className="submit_request_section">
              <p> Don't have an account yet? </p>
              <a onClick={() => props.onRegister(true)}>Request Access</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
