export const fontFam = [
  {
    id: 1,
    name: "DM Sans",
    font: "DM Sans, sans-serif"
  },
  {
    id: 2,
    name: "Water Brush",
    font: "Water Brush, cursive"
  },
  {
    id: 3,
    name: "Chicle",
    font: "Chicle, cursive"
  },
  {
    id: 4,
    name: "Pompiere",
    font: "Pompiere, cursive"
  },
  {
    id: 5,
    name: "Lato",
    font: "Lato, sans-serif"
  },
  {
    id: 6,
    name: "Roboto",
    font: "Roboto, sans-serif"
  },
  {
    id: 7,
    name: "Tapestry",
    font: "Tapestry, cursive"
  },
  {
    id: 8,
    name: "Ms Madi",
    font: "Ms Madi, cursive"
  },
  {
    id: 9,
    name: "Bebas Neue",
    font: "Bebas Neue, cursive"
  },
  {
    id: 10,
    name: "Whisper",
    font: "Whisper, cursive"
  },
  {
    id: 11,
    name: "Orelega One",
    font: "Orelega One, cursive"
  }
];


export const messageColors = [
  {
    id: 0,
    color: "#242F4F"
  },
  {
    id: 1,
    color: "#219EBC"
  },
  {
    id: 2,
    color: "#1E40BB"
  },
  {
    id: 3,
    color: "#54C18D"
  },
  {
    id: 4,
    color: "#FADA6C"
  },
  {
    id: 5,
    color: "#F96322"
  },
  {
    id: 6,
    color: "#F0A3A3"
  },
  {
    id: 7,
    color: "#E33DD3"
  }
];
