import React, { useEffect, useState,useRef } from 'react';
import { toast } from 'react-toastify';
import axios from "../../api_conf/BaseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Edit from "../../assets/images/edit.svg";
import './Frequency.css';



const GiftFrequencyCard = (props) => {
     const [seven,setSeven] = useState(false);
     const [fortin,setFortin] = useState(false);
     const [thirty,setThirty] = useState(false);
     const [custom,setCustom] = useState(false);

     const [customValue,setCustomValue] = useState("");
     const [saveicon,setSaveIcon] = useState(true);
     const [editable,setEditable] = useState(false);

     const UseFocus = () => {
      const htmlElRef = useRef(null)
      const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()
      setEditable(true)}
    
      return [ htmlElRef,  setFocus ] 
    }

     const [inputRef, setInputFocus] = UseFocus()

    const handleGiftExpirationS = () => {
        setSeven(true);
        setFortin(false);
        setThirty(false);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,gift_expiration_time:"7"}).then((res) => {
          props.getDataById(props.campaign_id);
         toast.success("Saved Gift Expiration Successfully")
         setCustomValue("7");
          //toast.success(res.data.message);
        })
    }

    const handleGiftExpirationM = () => {
        setSeven(false);
        setFortin(true);
        setThirty(false);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,gift_expiration_time:"14"}).then((res) => {
        props.getDataById(props.campaign_id);
        toast.success("Saved Gift Expiration Successfully")
        setCustomValue("14");
          //toast.success(res.data.message);
        })
    }
    const handleGiftExpirationL = () => {
        setSeven(false);
        setFortin(false);
        setThirty(true);
        setCustom(false);
        setSaveIcon(true);
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,gift_expiration_time:"30"}).then((res) => {
          props.getDataById(props.campaign_id);
          toast.success("Saved Gift Expiration Successfully")
          setCustomValue("30");
          //toast.success(res.data.message);
        })
    }
    const handleGiftExpirationCu = () => {
        axios.put(`campaignupdate/`,{campaign_id:props.campaign_id,gift_expiration_time:customValue}).then((res) => {
         props.getDataById(props.campaign_id);
         setSaveIcon(false);
         setEditable(false);
         toast.success("Saved Gift Expiration Successfully")
          //toast.success(res.data.message);
        })
    }

    const handleGiftExpirationXl = () => {
        setSeven(false);
        setFortin(false);
        setThirty(false);
        setCustom(true);
        setSaveIcon(false);
    }

    useEffect(() => {
        if(props.giftFrequency=="7"){
          setSeven(true);
          setCustomValue("7");
        }else if(props.giftFrequency=="14"){
          setFortin(true);
          setCustomValue("14");
        }else if(props.giftFrequency=="30"){
          setThirty(true);
          setCustomValue("30");
        }else{
          setCustom(true);
          setSaveIcon(false);
          setCustomValue(props.giftFrequency);
        }
    },[])
  return (
    <div>

        <div style={{fontWeight: "700",fontSize: "16px",lineHeight: "21px",color:"#023047",textAlign:"left"}}>Gift Expiration</div>

        <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}>

            <div style={seven?{width:"80px",height:"28px",borderBottom:"2px solid #023047",marginRight:"3px",color:"#023047",fontWeight: "700",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}:{width:"80px",height:"28px",marginRight:"3px",color:"#A8B8BF",fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}} onClick={handleGiftExpirationS}>7 days</div>

            <div style={fortin?{width:"80px",height:"28px",borderBottom:"2px solid #023047",marginRight:"3px",color:"#023047",fontWeight: "700",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}:{width:"80px",height:"28px",marginRight:"3px",color:"#A8B8BF",fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}} onClick={handleGiftExpirationM}>14 days</div>

            <div style={thirty?{width:"80px",height:"28px",borderBottom:"2px solid #023047",marginRight:"3px",color:"#023047",fontWeight: "700",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}:{width:"80px",height:"28px",marginRight:"3px",color:"#A8B8BF",fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}} onClick={handleGiftExpirationL}>30 days</div>

            <div style={custom?{width:"80px",height:"28px",borderBottom:"2px solid #023047",marginRight:"3px",color:"#023047",fontWeight: "700",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}:{width:"80px",height:"28px",marginRight:"3px",color:"#A8B8BF",fontWeight: "500",
                fontSize: "12px",
                lineHeight: "20px",cursor:"pointer"}} onClick={handleGiftExpirationXl} >Custom</div>

            {/* {custom?<div style={{fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#219EBC",
                   }}> <span style={{marginLeft:"10px",fontWeight: "700",fontSize: "32px",lineHeight: "32px", color: "#219EBC"}}>{customValue}</span> {customValue!==""?"Days":""}</div>:""} */}

        </div>

        {custom? <div style={{display:"flex",width:'60%',height:"20px",margin:"auto",marginTop:"25px",justifyContent:"center",alignItems:'center',gap:"3px"}}>
            <input ref={inputRef} readOnly={editable?false:true} style={{width:"100px",fontWeight: "700",fontSize: "32px",lineHeight: "32px", color: "#219EBC",outline:"none",border:"none",textAlign:"right"}} type="tel" placeholder='Days' value={customValue} onChange={(e)=>{setCustomValue(e.target.value.toString())
            setSaveIcon(true)}} /><p style={{fontWeight: "400",
            fontSize: "12px",
            lineHeight: "14px",
            color: "#219EBC",
            marginTop:"20px"
           }}>days</p>
            {saveicon?<FontAwesomeIcon style={{cursor:"pointer",marginLeft:"5px",color:"#219EBC",width:"20px",height:"20px"}} onClick={handleGiftExpirationCu} icon={faSave} />:<img style={{cursor:"pointer",marginLeft:"5px",color:"#219EBC",width:"20px",height:"20px"}} onClick={setInputFocus} src={Edit} alt="Edit Icon" />}
        </div>:""}

    </div>
  )
}

export default GiftFrequencyCard