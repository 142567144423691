import React, { useState } from "react";
import CustomDropdown from "../Dropdown/Dropdown";

const ActionArray = [
  {
    id: 1,
    name: "Move",
  },
  {
    id: 2,
    name: "Copy",
  },
];

export default function ActionsContainer(props) {
  const { data, userIDstoChange, handleChangeList,location,handleCloseUserMove,showUserMove } = props;

  const [action, setaction] = useState("");
  const [group, setgroup] = useState("");

  return (
    <div className="addUserContainer">
      <form style={{width:"630px",height:"60px",display:"flex",marginTop:"30px",justifyContent: "space-between"}}>
        <div className="form_control">
          <CustomDropdown
            labelName="Select Action"
            primaryCls={false}
            showLabel={true}
            options={ActionArray}
            onSearchChange={(event) => setaction(event)}
            location={location}
            showUserMove={showUserMove}
            defaultValue={action}
          />
        </div>

        <div className="form_control">
          <CustomDropdown
            labelName="Select List"
            primaryCls={false}
            showLabel={true}
            options={data.response}
            onSearchChange={(event) => setgroup(event)}
            location={location}
            showUserMove={showUserMove}
            defaultValue={group}
          />
        </div>
        <button
          className=""
          type="button"
          style={{width:"86px",height:"32px",padding:"4px 8px",background:"#219EBC",color:"#FFFFFF",textAlign:"center",fontWeight:"500",fontSize: "12px",lineHeight: "16px",border:"none",borderRadius:"2px"}}
          onClick={() => {
            handleChangeList(userIDstoChange, group, action);
            handleCloseUserMove();
          }}
        >
          SAVE
        </button>
      </form>
    </div>
  );
}
