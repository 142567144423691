import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../api_conf/BaseUrl";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";


import GiftLoader from "./GiftLoader";
import SoldOut from "../../assets/images/sold-out.svg";
import BackArrow from "../../assets/images/backArrow";
import "./ShowGift.scss";
import { handleSignOut } from "../../store/toolkit";
import GiftShowcase from "./GiftShowcase";

const ShowGift = () => {
  const [gifts, setGifts] = useState([]);
  const [logo, setLogo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showGiftInfo, setShowGiftInfo] = useState(false);
  const [showcaseItem, setShowcaseItem] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setError(false);
    const ShowGifts = async () => {
      try {
        const res = await axios.post("showgift/", {
          campaign_id: id
        });

        if (res?.data?.success) {
          setLoading(false);
          setLogo(res?.data?.logo_image_url);
          setGifts(res?.data?.response);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.message);
          setError(true);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      }
    };

    ShowGifts();
  }, []);

  const handleShowcase = (item) => {
    setShowcaseItem(item);
    setShowGiftInfo(true);
  };

  const Header = () => {
    return (
      <div className="header__flex-container">
        <div
          className="header__flex-item"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/dashboard/campaign/${id}`);
          }}
        >
          <BackArrow size={50} color={"#219ebc"} />
        </div>
        <div className="header__flex-item">
          <button
            className="sign"
            onClick={() => {
              dispatch(handleSignOut());
              localStorage.clear();
              navigate("/");
            }}
          >
            sign out
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="gift_section_wrapper">
        {showGiftInfo && (
          <GiftShowcase
            showcaseItem={showcaseItem}
            setShowGiftInfo={setShowGiftInfo}
          />
        )}
        <div className="gift_section_navigation">
          <div></div>
          {logo.length > 0 ? (
            <div className="gift_section_logo">
              <img src={axios.defaults.devMediaUrl + logo} alt="Logo" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="gift_section_container">
          {error ? (
            <div className="choose_gift_section_error">
              <h6>Invalid gift key</h6>
            </div>
          ) : loading ? (
            <GiftLoader />
          ) : (
            gifts?.map((currElem) => {
              return (
                <div key={currElem.id} className="gift_section_content">
                  {currElem?.sold_out && (
                    <div className="gift_section_content_sold_out">
                      <img src={SoldOut} alt="Gift sold out" />
                    </div>
                  )}
                  <img
                    className="gift_section_content_image"
                    src={currElem.img_url}
                    alt={currElem.name}
                    onClick={() => handleShowcase(currElem)}
                  />
                  <div
                    className="choose_gift_section_info"
                    onClick={() => handleShowcase(currElem)}
                  >
                    {currElem.name.length > 18 ? (
                      <p className="choose_gift_section_info_name">
                        {`${currElem.name.slice(0, 18)}...`}
                        <span className="choose_gift_section_name_tooltip">
                          {currElem.name}
                        </span>
                      </p>
                    ) : (
                      <p>{currElem.name}</p>
                    )}
                    <p>{currElem.vendor}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default ShowGift;
