import React, { memo, useEffect, useState } from "react";
import millify from "millify";
import { createUseStyles } from "react-jss";
import ProgressBar from "@ramonak/react-progress-bar";
import { Modal, Button } from "react-bootstrap";
import CancelIcon from "../../../../assets/images/cancelIcon.png";
import axios from "../../../../api_conf/BaseUrl";

import "./BudgetSpent.scss";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";

export default memo(function BudgetSpent(props) {
  const { totalspent, totalbudget, remaining, id, setCampaign, campaign } =
    props;
  const [amount, setAmount] = useState("");
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const handleCloseUserEdit = () => setShowUserEdit(false);

  const validateAddress = () => {
    if (amount !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  let val;
  let progress = (100 * totalspent) / totalbudget;
  if (totalspent > totalbudget) {
    progress = 100;
  }
  val = `${progress.toFixed(1)}`;

  //console.log("val", val);
  const styles = createUseStyles({
    spentProgress: {
      height: "14px",
      left: "0px",
      top: "0px",
      width: `${val}%`,
      background: "#219ebc",
      borderRadius: "8px",
      border: "2px solid #FFFFFF",
    },
  });

  const classes = styles();

  const updateBudget = () => {
    axios
      .put(`UpdateBudget/`, { amount: amount, campaign_id: id })
      .then((res) => {
        if (res.data.success) {
          setCampaign({ ...campaign, total_budget: res.data.Total_Budget });
          toast.success(res.data.Message);
          setAmount("");
          setShowUserEdit(false);
        } else {
          toast.error(res.data.Message);
        }
      });
  };
  useEffect(() => {
    validateAddress();
  }, [totalbudget, amount]);

  return (
    <div className="budget_spent_container">
      <Modal
        show={showUserEdit}
        onHide={handleCloseUserEdit}
        backdrop="static"
        keyboard={false}
        className="listModalUser"
        //style={{width:"40%",margin:"auto"}}
      >
        <Modal.Body
          className="specific_list_body border-0"
          style={{ width: "700px", margin: "auto" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItem: "center",
            }}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "700", color: "#023047" }}
            >
              Add a budget
            </div>

            <div
              style={{ marginLeft: "470px", cursor: "pointer" }}
              onClick={handleCloseUserEdit}
            >
              <img src={CancelIcon} alt="closeIcon"></img>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "16px",
                alignItem: "center",
                textAlign: "left",
                marginTop: "24px",
              }}
            >
              <div className="">
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#000000",
                  }}
                >
                  Amount
                </label>
                <br></br>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  style={{
                    width: "635px",
                    height: "32px",
                    border: "1px solid #023047",
                    borderRadius: "4px",
                    color: "#5E7D8C",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "5px",
                    outline: "none",
                  }}
                  onChange={(e) => {if(e.target.value<=1000000){setAmount(e.target.value)} else{return false}}}
                  //onChange={(event) => setAmount(event.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="specific_list_body border-0">
          <Button
            className=""
            style={{
              background: "#FFFFFF",
              padding: "8px 16px",
              width: "95px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#023047",
              border: "1px solid #023047",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
            }}
            onClick={handleCloseUserEdit}
          >
            CANCEL
          </Button>
          <Button
            className=""
            style={{
              background: "#219EBC",
              padding: "8px 16px",
              width: "133px",
              height: "37px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#FFFFFF",
              border: "none",
              opacity: isDisabled ? "0.5" : "",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
            }}
            disabled={isDisabled}
            onClick={updateBudget}
          >
            ADD BUDGET
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="update_budget_container">
        <Tooltip title="Add Budget" placement="right">
          <p
            className="update_budget"
            onClick={() => {
              setShowUserEdit(true);
            }}
          >
            +
          </p>
        </Tooltip>
      </div>
      <div className="budget_spent_container_inner">
        <h3 className="budget_spent_container_title">Budget Spent</h3>
        <div style={{ width: "100%" }}>
          <ProgressBar
            completed={20}
            completedClassName="budget_spent_barCompleted"
            labelClassName="budget_spent_budget_label"
            className="budget_spent_progress_bar"
            barContainerClassName={classes.spentProgress}
          />
        </div>
        <div className="budget_spent_info">
          ${totalspent.toLocaleString("en-US")} spent / $
          {totalbudget.toLocaleString("en-US")}
        </div>
      </div>
    </div>
  );
});
