import React from "react";
import moment from "moment";

const ThankyouList = ({ notes, handleSingleList}) => {
  
  return (
    <div className="thank_you_list" onClick={() => handleSingleList(notes)}>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "500",
          color: "#A8B8BF",
          lineHeight: "24px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
        className="thank_you_list_date"
      >
        {moment(notes.created_at).format("MM/DD/YYYY")}
      </div>
      <div
        style={{
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontSize: "14px",
          lineHeight: "24px",
          color: "#023047",
          fontWeight: "500",
          marginTop: "5px",
          marginBottom: "5px",
          textTransform:"capitalize",
          overflow: "hidden",
          width: "27%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          marginRight: "10px",
        }}
        className=""
      >
        {notes.user_name}
      </div>
      <div
        style={{
          fontSize: "12px",
          lineHeight: "24px",
          color: "#023047",
          fontWeight: "500",
          marginTop: "10px",
          marginBottom: "5px",
        }}
        className="thank_you_list_notes"
      >
        {(notes.message.length>22)?notes.message.slice(0,22)+"...":notes.message}
      </div>

      {/* <div className="thank_you_list_see_more">See more</div> */}
      {/* <div className="thank_you_list_notes">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium eos cupiditate necessitatibus veniam, maxime earum blanditiis fuga reiciendis voluptate cum? Quidem incidunt fuga fugiat? Quia cum odit aspernatur ullam assumenda doloribus? Recusandae laudantium unde, optio odio quae soluta mollitia commodi, nemo nisi doloremque magni cupiditate placeat voluptatibus adipisci nihil voluptas doloribus, consequuntur illum ducimus. Consectetur at eos aliquid nostrum natus dicta totam quaerat sed dignissimos sit molestias omnis suscipit aperiam debitis, nam cumque. In, quisquam eius. Necessitatibus facilis culpa repellat cupiditate a dolorem magni, quia cumque officia rerum natus odit, et possimus ipsa quis vitae animi at doloremque! Alias, optio.</div> */}
    </div>
  );
};

export default ThankyouList;
