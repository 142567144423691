import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeFilter,
  handleValueTag,
  handleCountryTag,
  handleCollectionTag,
  handleGiftTypeTag
} from "../../../store/giftSlice";

const GiftFilter = ({
  filter,
  value,
  isOpened,
  isClosed,
  productLoading,
  priceMultiSelect = false,
  valueMultiSelect = true,
  countryMultiSelect = true,
  collectionMultiSelect = false,
  giftTypeMultiSelect = false
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const filtersTag = useSelector((state) => state.gifts.filtersTag);
  const valuesTag = useSelector((state) => state.gifts.valuesTag);
  const countryTag = useSelector((state) => state.gifts.countryTag);
  const collectionsTag = useSelector((state) => state.gifts.collectionsTag);
  const giftTypeTag = useSelector((state) => state.gifts.giftTypeTag);

  const handleShowValue = () => {
    setShow(!show);
  };

  const handlePriceTags = (item) => {
    if (!filtersTag.some((currElem) => currElem.id === item.id)) {
      if (!priceMultiSelect) {
        dispatch(changeFilter([item]));
      } else if (priceMultiSelect) {
        dispatch(changeFilter([...filtersTag, item]));
      }
    } else {
      let removeFilters = filtersTag;
      removeFilters = removeFilters.filter(
        (currElem) => currElem.id !== item.id
      );

      dispatch(changeFilter([...removeFilters]));
    }
  };

  const isFilterSelected = (item) => {
    if (
      filtersTag.find(
        (currElem) =>
          currElem?.name?.toLowerCase().trim() === item?.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  const handleValueTags = (item) => {
    if (!valuesTag.some((currElem) => currElem.id === item.id)) {
      if (!valueMultiSelect) {
        dispatch(handleValueTag([item]));
      } else if (valueMultiSelect) {
        dispatch(handleValueTag([...valuesTag, item]));
      }
    } else {
      let removeFilters = valuesTag;
      removeFilters = removeFilters.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleValueTag([...removeFilters]));
    }
  };
  const isValuesInSelection = (item) => {
    if (
      valuesTag.find(
        (currElem) =>
          currElem?.name?.toLowerCase().trim() === item?.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  const handleCountryTags = (item) => {
    if (!countryTag.some((currElem) => currElem.id === item.id)) {
      if (!countryMultiSelect) {
        dispatch(handleCountryTag([item]));
      } else if (countryMultiSelect) {
        dispatch(handleCountryTag([...countryTag, item]));
      }
    } else {
      let removeFilters = countryTag;
      removeFilters = removeFilters.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleCountryTag([...removeFilters]));
    }
  };
  const isCountryInSelection = (item) => {
    if (
      countryTag.find(
        (currElem) =>
          currElem?.name?.toLowerCase().trim() === item?.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  const handleCollectionTags = (item) => {
    if (!collectionsTag.some((currElem) => currElem.id === item.id)) {
      if (!collectionMultiSelect) {
        dispatch(handleCollectionTag([item]));
      } else if (collectionMultiSelect) {
        dispatch(handleCollectionTag([...collectionsTag, item]));
      }
    } else {
      let removeFilters = collectionsTag;
      removeFilters = removeFilters.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleCollectionTag([...removeFilters]));
    }
  };
  const isCollectionInSelection = (item) => {
    if (
      collectionsTag.find(
        (currElem) =>
          currElem?.name?.toLowerCase().trim() === item?.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  const handleGiftTypeTags = (item) => {
    if (!giftTypeTag.some((currElem) => currElem.id === item.id)) {
      if (!giftTypeMultiSelect) {
        dispatch(handleGiftTypeTag([item]));
      } else if (giftTypeMultiSelect) {
        dispatch(handleGiftTypeTag([...giftTypeTag, item]));
      }
    } else {
      let removeFilters = giftTypeTag;
      removeFilters = removeFilters.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleGiftTypeTag([...removeFilters]));
    }
  };
  const isGiftTypeInSelection = (item) => {
    if (
      giftTypeTag.find(
        (currElem) =>
          currElem?.name?.toLowerCase().trim() === item?.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="filter_heading_container">
        <div className="filter_heading">
          <h6>{filter}</h6>
          <p onClick={handleShowValue}>
            {show && value ? isOpened : value ? isClosed : null}
          </p>
        </div>
      </div>
      {show &&
        value.map((item, index) => {
          return (
            <>
              <div
                key={index}
                style={{
                  pointerEvents: productLoading && "none",
                  backgroundColor:
                    isFilterSelected(item.name) ||
                    isValuesInSelection(item.name) ||
                    isCountryInSelection(item.name) ||
                    isCollectionInSelection(item.name) ||
                    isGiftTypeInSelection(item.name)
                      ? "#219ebc"
                      : ""
                }}
                className="gift_filter_value"
                onClick={() => {
                  if (filter === "Price") {
                    handlePriceTags(item);
                  } else if (filter === "Values") {
                    handleValueTags(item);
                  } else if (filter === "Ship to Country") {
                    handleCountryTags(item);
                  } else if (filter === "Collections") {
                    handleCollectionTags(item);
                  } else if (filter === "Gift Type") {
                    handleGiftTypeTags(item);
                  }
                }}
              >
                <span>{item.name}</span>
              </div>
            </>
          );
        })}
    </React.Fragment>
  );
};

export default GiftFilter;
