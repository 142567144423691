import React from "react";
import CustomDropdown from "../Dropdown/Dropdown";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './AddUserForm.scss';

export default function AddUserFormContainer(props) {
  const {
    list,
    setEmail,
    setFirstname,
    setLastname,
    setCompany,
    setList,
    birthdate,
    joiningDate,
    setBirthdate,
    setJoiningDate,
    data,
  } = props;

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  return (
    <div className="addUserContainer">
      <form>
         
         <div style={{display:"flex",justifyContent:"flex-start",gap:"16px",alignItem:"center",textAlign:"left",marginTop:"24px"}}>
              <div className="">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}> First Name <span style={{color:"#219EBC"}}>*</span></label><br></br>
                <input
                  type="text"
                  style={{width:"135px",height:"32px",border:"1px solid #023047",borderRadius:"4px",color:"#5E7D8C",fontSize:'12px',fontWeight:"400",paddingLeft:'5px',outline:"none"}}
                  placeholder="Enter First Name"
                  onChange={(event) => setFirstname(event.target.value)}
                />
              </div>

              <div className="">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}> Last Name <span style={{color:"#219EBC"}}>*</span></label><br></br>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  style={{width:"135px",height:"32px",border:"1px solid #023047",borderRadius:"4px",color:"#5E7D8C",fontSize:'12px',fontWeight:"400",paddingLeft:'5px',outline:"none"}}
                  onChange={(event) => setLastname(event.target.value)}
                />
              </div>

              <div className="">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}> Email <span style={{color:"#219EBC"}}>*</span></label><br></br>
                <input
                  type="text"
                  placeholder="Enter Email"
                  style={{width:"190px",height:"32px",border:"1px solid #023047",borderRadius:"4px",color:"#5E7D8C",fontSize:'12px',fontWeight:"400",paddingLeft:'5px',outline:"none"}}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="">
              <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}> Add to List </label><br></br>
                <CustomDropdown
                  //labelName="List"
                  primaryCls={false}
                  showLabel={true}
                  options={data.response}
                  onSearchChange={(event) => setList(event)}
                  disabled
                  defaultValue={list}
                  defaultAll="None"
                />
              </div>
         </div>

         <div style={{display:"flex",justifyContent:"flex-start",gap:"16px",alignItem:"center",textAlign:"left",marginTop:"24px"}}>

              <div className="customDatePickerWidth">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}>Add Birthday</label><br></br>
                <DatePicker
                  className="date_picker"
                  selected={birthdate}
                  placeholderText="MM/ DD"
                  onChange={(date) => {
                    setBirthdate(date);
                  }}
                  dateFormat="MM/dd"
                  autoComplete="off"
                />
              </div>

              <div className="customDatePickerWidth">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}>Add Joining Date</label><br></br>
                <DatePicker
                  id="manualJoin"
                  name="manualJoin"
                  selected={joiningDate}
                  placeholderText="MM/ DD/ YYYY"
                  onChange={(date) => {
                    setJoiningDate(date);
                  }}
                  // onChange={(date) => handleJoiningDate(date)}
                  // onChangeRaw={handleOnChangeRaw}
                  dateFormat="MM/dd/yyyy"
                  autoComplete="off"
                  maxDate={new Date()}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  disabledKeyboardNavigation
                />
              </div>

              <div className="">
                <label style={{fontSize:"14px",fontWeight:"700",color:"#000000"}}>Company Name </label><br></br>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  style={{width:"206px",height:"32px",border:"1px solid #023047",borderRadius:"4px",color:"#5E7D8C",fontSize:'12px',fontWeight:"400",paddingLeft:'5px',outline:"none"}}
                  onChange={(event) => setCompany(event.target.value)}
                />
              </div>

         </div>

      </form>
    </div>
  );
}
