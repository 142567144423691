import React from "react";
import { useDispatch } from "react-redux";
import InfoIcon from "../../../assets/images/flip-info.svg";
import CloseIcon from "../../../assets/images/xmark.svg";

import { hideToggleInfo } from "../../../store/designCardSlice";

const FlipCardInfo = () => {
  const dispatch = useDispatch();

  const handleFlipInfo = () => {
    dispatch(hideToggleInfo());
  };

  return (
    <div className="flip_card_info">
      <div className="flip_info">
        <img src={InfoIcon} alt="Info" />
      </div>
      <p>Tap card to create content for card</p>
      <div className="close_flip_info" onClick={handleFlipInfo}>
        <img src={CloseIcon} alt="Close" />
      </div>
    </div>
  );
};

export default FlipCardInfo;
