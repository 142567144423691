import React, { useState } from "react";
import { useSelector } from "react-redux";

import { filters } from "./filterApi";
import GiftFilter from "./GiftFilter";

const CurateGiftFilter = ({ productLoading }) => {
  const giftCollection = useSelector((state) => state.gifts.giftCollection);
  let newFilter = filters;
  newFilter.map((el) => {
    if (el.id === 4) {
      el.value = giftCollection?.map((el) => {
        return { ...el, name: el.title };
      });
    }
  });
  const [data, setData] = useState(newFilter);

  return (
    <>
      <section className="curate_main_filter">
        {data.map((item) => {
          const { id, filter, value, isOpened, isClosed } = item;
          return (
            <GiftFilter
              key={id}
              filter={filter}
              value={value}
              isOpened={isOpened}
              isClosed={isClosed}
              productLoading={productLoading}
            />
          );
        })}
      </section>
    </>
  );
};

export default CurateGiftFilter;
