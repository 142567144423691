const URLS = {
  REQUEST_SIGNUP: "organization_requests/",
  LOGIN: "login/",
  FORGOT_PASSWORD: "forget_password/",
  DASHBOARD: "dashboard/",
  CAMPAIGNS: "campaigns/",
  CAMPAIGN_WITH_ID: "campaign",
  USERS: "users/",
  USERS_BY_ID: "users/",
  USER_BY_LIST: "userbylist/",
  USER_GROUP: "usergroups/",
  USER_ACTIONS: "user_actions/",
  UPLOAD_CSV_FILE: "userupload/",
  GROUPS: "groups/",
  DESIGNATIONS: "designations/",
};

export default URLS;
