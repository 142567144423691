import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "../../../api_conf/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SketchPicker } from "react-color";

import {
  items,
  fontWeights,
  colorMenu
} from "./PersonalizeEmailUtil/CardLayout.util";
import {
  addCardLayoutTemplate,
  addColor,
  addBtnTextColor,
  addHeaderColor
} from "../../../store/personalizeEmailSlice";
import useClickOutside from "../custom/useClickOutside";

import UpIcon from "../../../assets/images/chevron-up-secondary.svg";
import DownIcon from "../../../assets/images/chevron-down-secondary.svg";
import FontFamilyDropdown from "../custom/FontFamilyDropdown";
import FontWeightDropdown from "../custom/FontWeightDropdown";

const CardLayout = ({ persEmailData, emailHeaderEmpty, btnTextEmpty }) => {
  const popover = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [headerColorPicker, setHeaderColorPicker] = useState(false);
  const [btnTextColorPicker, setBtnColorTextPicker] = useState(false);
  const [colorOptions, setColorOptions] = useState(false);

  const color = useSelector((state) => state.personalizeEmail.color);
  const headerColor = useSelector(
    (state) => state.personalizeEmail.headerColor
  );
  const btnTextColor = useSelector(
    (state) => state.personalizeEmail.btnTextColor
  );

  const fontFam = useSelector((state) => state.personalizeEmail.fontFamily);
  const weight = useSelector((state) => state.personalizeEmail.fontWeight);
  const fontFamilyName = fontFam?.map((item) => item.font);
  const fontWeightName = weight?.map((item) => item?.fontWeight);

  const close = useCallback(() => setShowColorPicker(false), []);
  const closeHeaderColorPicker = useCallback(
    () => setHeaderColorPicker(false),
    []
  );
  const closeBtnTextColorPicker = useCallback(
    () => setBtnColorTextPicker(false),
    []
  );

  const closeMutliOption = useCallback(() => setColorOptions(false));
  useClickOutside(popover, close);
  useClickOutside(popover, closeHeaderColorPicker);
  useClickOutside(popover, closeBtnTextColorPicker);
  useClickOutside(popover, closeMutliOption);

  const handleColorMenu = (item) => {
    if (item.id === 0) {
      setHeaderColorPicker(true);
    } else if (item.id === 1) {
      setShowColorPicker(true);
    } else {
      setBtnColorTextPicker(true);
    }

    setColorOptions(false);
  };

  const livePreview = document.querySelector(".personalize_layout_html");

  const templateHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Hey Kaido</title>
      <style type="text/css">
      @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Chicle&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Pompiere&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Water+Brush&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Tapestry&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Whisper&display=swap");
      @import url("https://fonts.googleapis.com/css2?family=Orelega+One&display=swap");
      </style>
    </head>
    <body style="margin: 0">
    ${livePreview?.innerHTML}
    </body>
  </html>`;

  useEffect(() => {
    dispatch(addCardLayoutTemplate(templateHtml));
  }, [templateHtml]);

  return (
    <>
      <div className="personalize_layout">
        <div className="personalize_layout_container">
          <div className="personalize_live_preview">
            <div className="personalize_live_email_logo">
              {persEmailData.emailLogo.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={axios.defaults.devMediaUrl + `${item.image}`}
                      alt="Logo"
                    />
                  </div>
                );
              })}
            </div>

            <div className="personalize_live_email_header">
              <h4
                className="personalize_live_email_header_title"
                style={{
                  textAlign: "center",
                  color: headerColor,
                  fontFamily: fontFamilyName,
                  fontWeight: fontWeightName
                }}
              >
                {persEmailData.emailHeader === ""
                  ? "Your Header Here"
                  : emailHeaderEmpty === true
                  ? "Your Header Here"
                  : persEmailData.emailHeader}
              </h4>
            </div>

            <div className="personalize_email_animation">
              {persEmailData.emailAnimation.map((item) => {
                return (
                  <div key={item.id}>
                    <img
                      src={axios.defaults.devMediaUrl + `${item.image_base64}`}
                      alt="Animation"
                    />
                  </div>
                );
              })}
            </div>

            <a
              className="gift_link_button"
              id="gift_link"
              href="{gift_link}"
              target="_blank"
              style={{
                color: btnTextColor,
                fontWeight: fontWeightName,
                backgroundColor: color,
                fontFamily: fontFamilyName,
                pointerEvents: pathname === "/dashboard" && "none"
              }}
            >
              {persEmailData.btnText === ""
                ? "Tap to unwrap"
                : btnTextEmpty === true
                ? "Tap to unwrap"
                : persEmailData.btnText}
            </a>
          </div>
        </div>
        <div className="personalize_properties">
          <FontFamilyDropdown title="Font" items={items} />
          <FontWeightDropdown title="Font Weight" items={fontWeights} />
          <div className="personalize_change_color" ref={popover}>
            {showColorPicker && (
              <div className="live_sketchPicker">
                <SketchPicker
                  color={color}
                  onChange={(updatedColor) =>
                    dispatch(addColor(updatedColor.hex))
                  }
                />
              </div>
            )}

            {headerColorPicker && (
              <div className="live_sketchPicker">
                <SketchPicker
                  color={headerColor}
                  onChange={(updatedColor) =>
                    dispatch(addHeaderColor(updatedColor.hex))
                  }
                />
              </div>
            )}
            {btnTextColorPicker && (
              <div className="live_sketchPicker">
                <SketchPicker
                  color={btnTextColor}
                  onChange={(updatedColor) =>
                    dispatch(addBtnTextColor(updatedColor.hex))
                  }
                />
              </div>
            )}
            <button onClick={() => setColorOptions(!colorOptions)}>
              Color
              {showColorPicker ? (
                <img src={UpIcon} alt="Chevron Down" />
              ) : (
                <img src={DownIcon} alt="Chevron Down" />
              )}
            </button>
            {colorOptions && (
              <div className="live_options_picker_container">
                {colorMenu.map((item) => (
                  <div
                    key={item.id}
                    className="live_options_picker"
                    onClick={() => handleColorMenu(item)}
                  >
                    <h6>{item.name}</h6>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="personalize_layout_html">
        <center
          className="wrapper"
          style={{ width: "100%", tableLayout: "fixed", paddingBottom: "60px" }}
          width="100%"
          padding="60px"
        >
          <table
            className="main mobilefullwidth"
            style={{
              borderSpacing: "0",
              width: "100%",
              maxWidth: "600px",
              backgroundColor: "#ffff",
              boxShadow: "0 0 25px rgba(0, 0, 0, 0.05)",
              borderStyle: "none",
              borderSpacing: "0"
            }}
            width="600"
            height="auto"
            border="0"
          >
            <tr>
              <td style={{ padding: 0 }}>
                <table width="100%" style={{ borderSpacing: "0" }}>
                  <tr>
                    <td style={{ padding: "0", textAlign: "center" }}>
                      {persEmailData.emailLogo.map((item, index) => {
                        return (
                          <div key={index}>
                            <img
                              src={axios.defaults.devMediaUrl + `${item.image}`}
                              alt="Logo"
                              title="Logo"
                              style={{
                                maxWidth: "85px",
                                borderStyle: "none",
                                display: "block",
                                width: "100%",
                                margin: "0px auto"
                              }}
                              //class="mobilefullwidth"
                              width="85"
                              height="auto"
                              border="0"
                            />
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "0"
                }}
              >
                <table
                  width="100%"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "0",
                        textAlign: "center",
                        fontSize: "38px",
                        color: headerColor,
                        fontFamily: fontFamilyName,
                        fontWeight: fontWeightName
                      }}
                    >
                      <p> {persEmailData.emailHeader}</p>
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td
                      colspan="3"
                      height="10"
                      style={{ height: "10px", lineHeight: "1px" }}
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "0" }}>
                <table
                  width="100%"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td style={{ padding: "0", textAlign: "center" }}>
                      {persEmailData.emailAnimation.map((item) => {
                        return (
                          <div key={item.id}>
                            <img
                              src={
                                axios.defaults.devMediaUrl +
                                `${item.image_base64}`
                              }
                              alt="Animation"
                              title="Animation"
                              style={{
                                maxWidth: "320px",
                                borderStyle: "none",
                                display: "block",
                                width: "100%",
                                margin: "0px auto"
                              }}
                              class="mobilefullwidth"
                              width="320"
                              height="auto"
                              border="0"
                            />
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "0" }}>
                <table>
                  <tr>
                    <td
                      colspan="3"
                      height="10"
                      style={{ height: "10px", lineHeight: "1px" }}
                    ></td>
                  </tr>

                  <tr>
                    <td
                      width="10"
                      style={{ height: "10px", lineHeight: "1px" }}
                    ></td>

                    <td
                      width="10"
                      style={{ height: "10px", lineHeight: "1px" }}
                    ></td>
                  </tr>
                </table>
                {/* <table
                  width="100%"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "0",
                        textAlign: "center",
                        padding: "40px 0px 20px",
                        fontFamily: fontFamilyName
                      }}
                    >
                      <a
                        href="{gift_link}"
                        target="_blank"
                        style={{
                          color: btnTextColor,
                          fontWeight: fontWeightName,
                          backgroundColor: color,
                          fontFamily: fontFamilyName,
                          fontSize: "20px",
                          borderRadius: "37px",
                          padding: "10px 20px",
                          width: "auto",
                          textDecoration: "none",
                          wordBreak: "keep-all"
                        }}
                      >
                        {persEmailData.btnText}
                      </a>
                    </td>
                  </tr>
                </table> */}

                {/* new button */}
                <table
                  align="center"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "12px 18px",
                        borderRadius: "37px",
                        backgroundColor: color
                      }}
                      align="center"
                    >
                      <a
                        rel="noopener"
                        target="_blank"
                        href="{gift_link}"
                        style={{
                          fontSize: "20px",
                          fontFamily: fontFamilyName,
                          fontWeight: fontWeightName,
                          color: btnTextColor,
                          textDecoration: "none",
                          display: "inline-block"
                        }}
                      >
                        <strong style={{ fontWeight: fontWeightName }}>
                          {persEmailData.btnText}
                        </strong>
                      </a>
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td
                      colspan="3"
                      height="10"
                      style={{ height: "10px", lineHeight: "1px" }}
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "0" }}>
                <table
                  width="100%"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "0",
                        textAlign: "center"
                      }}
                    >
                      <a href="https://www.heykaido.com" target="_blank">
                        <img
                          className="personalize_html_info_1 mobilefullwidth"
                          src="https://res.cloudinary.com/dcgbzqanx/image/upload/v1654165944/Hey%20kaido/Info_img_1_wi1pto.jpg"
                          alt="www.heykaido.com"
                          style={{
                            maxWidth: "400px",
                            borderStyle: "none",
                            display: "block",
                            width: "100%",
                            margin: "0px auto"
                          }}
                          width="400"
                          height="auto"
                          border="0"
                        />
                      </a>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "0" }}>
                <table
                  width="100%"
                  style={{ borderSpacing: "0", borderSpacing: "0" }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "0",
                        textAlign: "center"
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/dcgbzqanx/image/upload/v1654165945/Hey%20kaido/Info_img_2_zuuncn.jpg"
                        alt="Hey Kaido"
                        style={{
                          maxWidth: "400px",
                          borderStyle: "none",
                          display: "block",
                          width: "100%",
                          margin: "0px auto"
                        }}
                        class="mobilefullwidth"
                        width="400"
                        height="auto"
                        border="0"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </center>
      </div>
    </>
  );
};

export default CardLayout;
