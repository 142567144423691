import React from "react";
import "./PostLoader.scss";

const PostLoader = () => {
  return (
    <div className="post_loader_container">
      <div className="post_loader"></div>
    </div>
  );
};

export default PostLoader;
