import React, { useState } from "react";
import "./GiftInfo.scss";

const GiftInfo = ({
  campaignDetails,
  isGiftSent,
  isGiftClaimed,
  isNotesRecieved,
  isTotalSpent,
  title
}) => {
  const [toggleMonth, setToggleMonth] = useState(true);
  const {
    monthlyGiftSent,
    monthlyGiftClaimed,
    monthlyGiftNotes,
    totalGiftSent,
    totalGiftClaimed,
    totalGiftNotes,
    spentMonth,
    totalSpent
  } = campaignDetails;
  const handleToggle = () => {
    setToggleMonth(!toggleMonth);
  };

  const toggleMonthStyle = {
    borderBottom: toggleMonth ? "3px solid #023047" : 0
  };

  const toggleYearStyle = {
    borderBottom: toggleMonth ? 0 : "3px solid #023047"
  };

  const numStyle = {
    color: !isGiftSent && "red",
    width: isGiftClaimed && '108px'
  };

  return (
    <div className="gift_info_contaner">
      <div className="gift_info_title">{title}</div>
      <div className="gift_info_container">
        {isGiftSent && (
          <h2 className="gift_info_num" style={numStyle}>
            {toggleMonth ? monthlyGiftSent : totalGiftSent}
          </h2>
        )}
        {isGiftClaimed && (
          <div className="gift_info_num" style={numStyle}>
            {toggleMonth ? `${monthlyGiftClaimed}/${monthlyGiftSent}` : `${totalGiftClaimed}/${totalGiftSent}`}
          </div>
        )}
        {isNotesRecieved && (
          <div className="gift_info_num" style={numStyle}>
            {toggleMonth ? monthlyGiftNotes : totalGiftNotes}
          </div>
        )}
         {isTotalSpent && (
          <h2 className="gift_info_num" style={numStyle}>
            {toggleMonth ? "$"+spentMonth.toLocaleString("en-US") : "$"+totalSpent.toLocaleString("en-US")}
          </h2>
        )}
        <div className="gift_info_toggle" onClick={handleToggle}>
          <div
            className="gift_info_toggle_month_container"
            style={toggleMonthStyle}
          >
            <span className="gift_info_toggle_month">This Month</span>
          </div>
          <div
            className="gift_info_toggle_all_time_container"
            style={toggleYearStyle}
          >
            <span className="gift_info_toggle_all_time">All-Time</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftInfo;
