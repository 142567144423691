import React from "react";

import "./CustomModal.scss";
import CancelIcon from "../../assets/images/cancelIcon.png";

const CustomModal = (props) => {
  if (!props.show) {
    return null;
  }

  let closeModal = () => {
    if (props.closeModal) {
      props.closeModal();
    } else props.escapeModal(false);
  };

  let buttonComponent = (
    okButtonTitle,
    okButtonAction,
    disabledVal = false,
    index = 0
  ) => {
    return (
      <button
        className="list_common_button"
        onClick={okButtonAction}
        key={index}
        disabled={disabledVal}
        style={
          okButtonTitle == "SPECIFIC LIST"
            ? {
                background: "#FFFFFF",
                padding: "8px 16px",
                width: "150px",
                height: "37px",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#023047",
                border: "1px solid #023047",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            : okButtonTitle == "ALL LISTS"
            ? {
                background: "#FF304C",
                padding: "8px 16px",
                width: "113px",
                height: "37px",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            : {
                background: "#219EBC",
                padding: "8px 16px",
                width: "133px",
                height: "37px",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#FFFFFF",
                border: "none",
                fontFamily: "DM Sans",
                display: "flex",
                justifyContent: "center"
                //alignItems:"center",
              }
        }
      >
        {okButtonTitle}
      </button>
    );
  };

  return (
    <div className="custom_modal_container">
      <div className="modal">
        <div className="modal-content">
          <div className="modal_Header">
            <h4>
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "700",
                  fontSize: "24px"
                }}
              >
                {props.title}
              </div>
              {/* {props.handleModalAction && (
                <div className="modal-close" onClick={closeModal}>
                  &times;
                </div>
              )} */}
              <div
                style={{ marginLeft: "44px", cursor: "pointer" }}
                onClick={closeModal}
              >
                <img src={CancelIcon} alt="closeIcon"></img>
              </div>
            </h4>

            <span
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "500",
                color: "#5E7D8C",
                marginRight:
                  props.subTitle ===
                  "Drop in your email so we can send your password as a reminder."
                    ? "102px"
                    : "0px"
              }}
            >
              {" "}
              {props.subTitle}{" "}
            </span>
          </div>

          <div className="modal_Body">{props.children}</div>
          <div className="modal_footer">
            {!props.handleModalAction && (
              <button
                //className="list_common_button closeBtn"
                style={{
                  background: "#FFFFFF",
                  padding: "8px 16px",
                  width: "95px",
                  height: "37px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#023047",
                  border: "1px solid #023047",
                  fontFamily: "DM Sans",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={closeModal}
              >
                Close
              </button>
            )}
            {props.handleModalAction
              ? props.handleModalAction.map((action, index) =>
                  buttonComponent(
                    action.okButtonTitle,
                    action.okButtonAction,
                    action.disabledVal,
                    index
                  )
                )
              : buttonComponent(props.okButtonTitle, props.okButtonAction)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
