import React, { useState } from "react";
import "./CalendarCard.scss";

import { toast } from "react-toastify";
import axios from "../../../../api_conf/BaseUrl";
import moment from "moment";

import ReactDatePicker from "react-datepicker";
import Edit from "../../../../assets/images/edit.svg";
import "react-datepicker/dist/react-datepicker.css";

const CalendarCard = ({ notActive, getDataById, campaign }) => {
  const [showCalender, setshowCalender] = useState(false);

  const [value, setValue] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(
    new Date(campaign.campaign_end_date)
  );

  const changeDate = async (e) => {
    setValue(e);
    let res = await axios.put("campaignupdate/", {
      campaign_id: campaign.id,
      campaign_end_date: e
    });

    console.log(res);

    if (res?.data?.success) {
      getDataById(campaign.id);
      setLocalEndDate(e);
      toast.success("End date updated");
    }
    setshowCalender(false);
  };

  const calendarStyle = {
    visibility: "none",
    border: "none"
  };

  return (
    <div className="calendar_card_container">
      <div className="calendar_card_inner">
        <div className="calendar_card_title">Dates</div>
        <div className="calendar_card_field">
          <div className="created_card_label">Created on:</div>
          <div className="created_card_date">
            {moment(campaign.campaign_start_date).format("MMMM Do")}{" "}
            <span className="created_card_year">
              {moment(campaign.campaign_start_date).format("YYYY")}
            </span>
          </div>
        </div>
        <div className="calendar_card_field">
          <div className="created_card_label">Ends on:</div>
          <div className="created_card_date">
            {moment(campaign.campaign_end_date).format("MMMM Do")}{" "}
            <span className="created_card_year">
              {moment(campaign.campaign_end_date).format("YYYY")}
            </span>
            <span
              className="calendar_card_open_cal"
              onClick={() => setshowCalender(!showCalender)}
              style={{ display: notActive && "none" }}
            >
              <img src={Edit} alt="Edit calendar" title="Edit calendar" />
            </span>
          </div>
        </div>
      </div>
      {showCalender ? (
        <div className="calenderVieww">
          <ReactDatePicker
            className="calendar_picker"
            minDate={new Date()}
            open={true}
            style={calendarStyle}
            selected={value}
            onChange={changeDate}
            onKeyDown={(e) => e.preventDefault()}
            showYearDropdown
            scrollableMonthYearDropdown
            autoComplete="off"
          />
        </div>
      ) : null}
    </div>
  );
};

export default CalendarCard;
