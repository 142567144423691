import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useDebouncedEffect from "use-debounced-effect";

import { saveCampaignData, handleSignOut } from "../../store/toolkit";
import CampaignCards from "../Dashboard/CampaignCards/CampaignCards";
import axios from "../../api_conf/BaseUrl";
import API from "../../api_conf/ApiUrls";
import { Spinner } from "react-bootstrap";
import GiftInfo from "../Dashboard/GiftInfo/GiftInfo";
import Notifications from "../Dashboard/Notifications/Notifications";
// import { toast } from "react-toastify";
import AddCampaignButton from "../AddCampaign/AddCampaignButton";
// import DashboardSearch from "../Dashboard/DashboardSearch/DashboardSearch";
import "./Home.scss";
import DashboardSearch from "../Dashboard/DashboardSearch/DashboardSearch";

const Home = (props) => {
  const { loggedInUser, giftInfo } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 600);
  const [loader, setloader] = useState(true);
  const [statusUpdate, setStatusUpdate] = useState(true);
  const [totalPages, settotalPages] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationHeight,setNotificationHeight] = useState(0);

  useEffect(() => {
    setCampaignList([]);
  }, [search]);

  console.log("campaignList", campaignList);

  const getCampaignsApiCall = async () => {
    setloader(true);
    try {
      const res = await axios.get(API.CAMPAIGNS, {
        params: {
          page: page,
          SearchCampaign: search
        }
      });
      if (res?.data?.success) {
        setCampaignList([
          ...new Set([...campaignList, ...res?.data?.response])
        ]);
        console.log("status", res.data.status_update);
        setStatusUpdate(res.data.status_update);
        settotalPages(res.data["number of pages"]);
      }
      setloader(false);
    } catch (error) {
      setloader(false);
      if (error.response && error && error.response.status === 401) {
        dispatch(handleSignOut());
        localStorage.clear();
        navigate("/login");
      }
      // toast.error(error.message);
    }
  };

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    getCampaignsApiCall();
  }, []);

  useDebouncedEffect(
    () => {
      if (page <= totalPages) {
        getCampaignsApiCall();
        setNotificationHeight(notificationHeight+325)
      }
    },
    800,
    [page, search]
  );

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="Home_container" style={{minHeight:"100vh"}}>
      <DashboardSearch
        page={page}
        search={search}
        setPage={setPage}
        setSearch={setSearch}
        setCampaignList={setCampaignList}
        showNotifications={showNotifications}
        setShowNotifications={setShowNotifications}
      />

      <div className="home_inner_right">
        <div className="campaign_user_name">
          Welcome, {loggedInUser.organization_name}!
        </div>

        <div className="campaign_overview">
          <div className="campaign_overview_title">
            <h4>Overview</h4>
          </div>
        </div>

        <div className="active_gifts_info">
          <GiftInfo
            campaignDetails={giftInfo}
            isGiftSent={true}
            title="Gifts Sent"
          />
          <GiftInfo
            campaignDetails={giftInfo}
            isGiftClaimed={true}
            title="Gifts Claimed"
          />
          <GiftInfo
            campaignDetails={giftInfo}
            isNotesRecieved={true}
            title="Notes Received"
          />
          <GiftInfo
            campaignDetails={giftInfo}
            isTotalSpent={true}
            title="Total Spent"
          />
        </div>

        <div className="home_add_campaigns_container">
          <div className="home_add_campaigns_container_title">
            <h6>Campaigns</h6>
          </div>
          <div className="home_add_campaigns_btn_container">
            <AddCampaignButton getCampaignsApiCall={getCampaignsApiCall} />
          </div>
        </div>
        <div className="active_festivals">
          <div className="running_container">
            {campaignList.length > 0 &&
              campaignList.map((campaign, i) => {
                return i === campaignList.length - 1 &&
                  !loader &&
                  page <= totalPages ? (
                  <div key={`${campaign.id}-${i}`} ref={setLastElement}>
                    <CampaignCards
                      id={campaign.id}
                      name={campaign.name}
                      status={campaign.campaign_status}
                      giftSent={campaign.gift_sent}
                      giftClaimend={campaign.gift_claimed}
                      giftBudget={campaign.total_budget}
                      remainingBudget={campaign.remaining_budget}
                      giftDelivered={campaign.gift_delivered}
                      campaignType={campaign.campaign_type}
                      campaignSubType={campaign.campaign_subtype}
                      campaign={campaign}
                      popularGift={campaign.popular_gift}
                      setPage={setPage}
                    />
                  </div>
                ) : (
                  <CampaignCards
                    key={`${campaign.id}-${i}`}
                    id={campaign.id}
                    name={campaign.name}
                    status={campaign.campaign_status}
                    giftSent={campaign.gift_sent}
                    giftClaimend={campaign.gift_claimed}
                    giftBudget={campaign.total_budget}
                    remainingBudget={campaign.remaining_budget}
                    giftDelivered={campaign.gift_delivered}
                    campaignType={campaign.campaign_type}
                    campaignSubType={campaign.campaign_subtype}
                    campaign={campaign}
                    popularGift={campaign.popular_gift}
                    setPage={setPage}
                  />
                );
              })}
          </div>
        </div>

        {loader && (
          <div className="campaign_loading">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {page - 1 === totalPages && (
          <div className="campaign_end_of_list">
            {/* <p>End of the list...</p> */}
          </div>
        )}

        {!statusUpdate && (
          <div className="campaign_not_found">No data found</div>
        )}
      </div>
      <div className="home_inner_left">
        <Notifications showNotifications={showNotifications} notificationHeight={notificationHeight} />
      </div>
    </div>
  );
};

export default Home;
