import React, { useState } from "react";
//import { Progress } from "@mantine/core";
import {CProgress,CProgressBar} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';

import "./GiftProgress.scss";
import { Tooltip } from "@material-ui/core";

const GiftProgress = ({ campaign }) => {
 
  let giftSent = campaign.gift_waiting;
  let giftClaimed = campaign.gift_claimed;
  let giftDelivered = campaign.gift_delivered;
  let total = campaign.total_gift_sent;

  let waiting = parseInt((giftSent/total) * 100).toFixed(1);
  //console.log(waiting);
  let claimed = parseInt(((giftClaimed+giftDelivered) / total) * 100).toFixed(1);
  //console.log(claimed);
  let delivered = parseInt(((giftDelivered )/ total) * 100).toFixed(1);
  //console.log(delivered);

  const [claimedH,setClaimedH] = useState(false);
  const [deliveredH,setDeliveredH] = useState(false);
  const [waitingH,setWaitingH] = useState(false);


  let deliveredShow = ((giftDelivered)*100/(giftClaimed+giftDelivered)).toFixed(1)

  // console.log("delivered",delivered+""+claimed+""+waiting)

  // let remaining = props.remainingBudget;
  // spent = total - remaining;
  // progress = (100 * spent) / total;
  // val = `${progress.toFixed(1)}%`;
  // console.log(campaign.gift_sent);
  
  return (
    <div className="gift_progress_container">
      <div
        style={{
          textAlign: "left",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "18px",
          marginLeft:"10px",
          marginBottom:"10px"
        }}
      >
        Gifting Acceptance
      </div>
      <div className="gift_progress">
        {/* <Progress
          mt="md"
          size="xl"
          radius="xl"
          sections={[
            {
              //value: `${waiting}`,
              value: (waiting=="NaN")?33:`${waiting}`,
              color: "#219EBC",
              // label: "Waiting"
            },
            {
              //value: `${claimed}`,
              value: (claimed=="NaN")?33:`${claimed}`,
              color: "#023047",
              // label: "Claimed & In Transit"
            },
            {
              //value: `${delivered}`,
              value: (delivered=="NaN")?33:`${delivered}`,
              color: "#FF2543",
              // label: "Delivered"
            },
          ]}
        /> */}

        <CProgress className="mb-1" >
            <CProgressBar onMouseMove={() => {waiting<10?setWaitingH(true):setWaitingH(false)}} onMouseOut={() => setWaitingH(false)} className="waiting" value={(waiting==="NaN")?33:`${waiting}`} />
            {(claimed>0)?<div></div>:""}
           <CProgressBar onMouseMove={() => {claimed<=12?setClaimedH(true):setClaimedH(false)}} onMouseOut={() => setClaimedH(false)}  className="claimed"  value={(claimed==="NaN")?33:`${claimed}`} />
           {(delivered>0)?<div></div>:""}
           <CProgressBar onMouseMove={() => {delivered<=10?setDeliveredH(true):setDeliveredH(false)}} onMouseOut={() => setDeliveredH(false)} className="delivered" value={(delivered==="NaN")?33:`${delivered}`} />
        </CProgress>

        <div
          className="gift_progress_options_container"
          //style={{transform:((delivered<8||delivered===0)||(claimed<8||claimed===0))?"scale(0.90)":"scale(1)"}}
        >
          {<div
            className="gift_progress_options"
            style={{width:(waiting=="NaN")?`${33}%`:(waiting<=1)?`${waiting+6}%`:`${waiting}%`,color:"#219EBC"}}
            //style={{ color: "#219EBC", width: `17%`,marginLeft:"10px" }}
          >
            <div className="gift_progress_options_inner">
              <div className="gift_progress_options_title">Waiting</div>
              <div className="gift_progress_stats">
                <div className="gift_progress_num">
                  {giftSent}
                </div>
                <div className="gift_progress_percent">
                  {waiting == "NaN" ? 0 : waiting}%
                </div>
              </div>
            </div>
          </div>}
          <div
            className="gift_progress_options"
            //style={{ color: "#023047", width: `25%` }}
            style={{width:(claimed=="NaN")?`${33}%`:(claimedH || claimed <= 1 )?`${15}%`:(delivered<=1 && claimed<25)?`${20}%`:`${claimed}%`,color:"#023047",transform:(claimed>=1)?"translateX(14%)":"translateX(0%)"}}
          >
            {/* <div style={{width:"20px",height:'20px',background:"#023047",marginBottom:"10px",borderRadius:"50%"}}></div> */}
            {(claimed>=12 || claimedH  || claimed==="NaN"|| claimed < 1)?<div className="gift_progress_options_inner">
              {/* <Tooltip title="Claimed & In Transit"><div className="gift_progress_options_title" 
              style={(claimed<12)?{textOverflow:"ellipsis",
              overflow: "hidden",width: "100px",cursor:"pointer"}:{}}
              >Claimed & In Transit</div></Tooltip> */}
              <div className="gift_progress_options_title" 
              >Claimed & In Transit</div>
              <div className="gift_progress_stats">
                <div className="gift_progress_num">{giftClaimed+giftDelivered}</div>
                <div className="gift_progress_percent">
                  {claimed == "NaN" ? 0 : claimed}%
                </div>
              </div>
            </div>:""}
            <div className="gift_progress_options_title"></div>
          </div>
          <div
            className="gift_progress_options"
            //style={{color: "#FF2543",width:"25%"}}
            style={{ color: "#FF2543", width:(deliveredH || delivered<=1 )? `${8}%`:`${delivered}%` }}
          >
            {/* <div style={{width:"20px",height:'20px',background:"#FF2543",marginBottom:"10px",borderRadius:"50%"}}></div> */}
           {(delivered>=10 || deliveredH || delivered==="NaN" || giftDelivered===0)? <div className="gift_progress_options_inner">
              <div className="gift_progress_options_title">Delivered</div>
              <div className="gift_progress_stats">
                <div className="gift_progress_num">{giftDelivered}</div>
                <div className="gift_progress_percent">
                  {delivered == "NaN" ? 0 : deliveredShow==="NaN" ? 0: deliveredShow}%
                </div>
              </div>
            </div>:""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftProgress;
