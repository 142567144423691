import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { handleGifts } from "../../../store/giftSlice";
import CheckPrimary from "../../../assets/images/check_primary.svg";
import CheckSecondary from "../../../assets/images/check_secondary.svg";
import PrevSlide from "../../../assets/images/previous_secondary.svg";
import NextSlide from "../../../assets/images/next_secondary.svg";
import SoldOut from "../../../assets/images/sold-out.svg";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const SelectedGift = () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [currentImage, setCurrentImage] = useState("");
  const giftId = useSelector((state) => state.gifts.giftId);
  const giftItems = useSelector((state) => state.gifts.giftsData);
  const giftAdded = useSelector((state) => state.gifts.giftAdded);
  const dataLength = giftItems.length;

  useEffect(() => {
    setCurrent(giftId);
    setCurrentImage("");
  }, [giftId]);

  const handlePrevSlide = () => {
    setCurrent(current === 0 ? dataLength - 1 : current - 1);
    setCurrentImage("");
  };

  const handleNextSlide = () => {
    setCurrent(current === dataLength - 1 ? 0 : current + 1);
    setCurrentImage("");
  };

  if (!Array.isArray(giftItems) || giftItems.length <= 0) {
    return null;
  }

  const handleGiftsData = (item) => {
    if (!giftAdded.some((currElem) => currElem.id === item.id)) {
      dispatch(handleGifts([...giftAdded, item]));
    } else {
      let giftAfterRemoval = giftAdded;
      giftAfterRemoval = giftAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleGifts([...giftAfterRemoval]));
    }
  };

  const giftsInSelection = (item) => {
    if (giftAdded.find((currElem) => currElem.id === item.id)) {
      return true;
    }

    return false;
  };

  const handleImage = (image) => {
    setCurrentImage(image);
  };

  return (
    <div className="curate_layout_container">
      {giftItems.map((item, index) => {
        return (
          <div key={index} className="curate_gifts_selected">
            {index === current && (
              <React.Fragment>
                <div className="curate_gift_selected_image_container">
                  <button onClick={handlePrevSlide} className="curate_prev_btn">
                    <img
                      src={PrevSlide}
                      alt="Previous slide"
                      className="curate_prev_img"
                    />
                  </button>
                  <div className="curate_gift_selected_image_container_inner">
                    <img
                      src={!currentImage ? item.image.src : currentImage}
                      alt={item.title}
                      className="curate_gift_image"
                    />
                    {item.sold_out && (
                      <div className="curate_gift_selected_sold_out">
                        <img src={SoldOut} alt="Sold out" />
                      </div>
                    )}

                    <button
                      style={{ display: item.sold_out && "none" }}
                      className={
                        giftsInSelection(item)
                          ? "curate_gift_selected_primary"
                          : "curate_gift_selected_secondary"
                      }
                      onClick={() => handleGiftsData(item)}
                    >
                      {giftsInSelection(item) ? (
                        <img src={CheckSecondary} alt="Select Gifts" />
                      ) : (
                        <img src={CheckPrimary} alt="Select Gifts" />
                      )}
                    </button>
                  </div>

                  <button onClick={handleNextSlide} className="curate_next_btn">
                    <img
                      src={NextSlide}
                      alt="Next slide"
                      className="curate_next_img"
                    />
                  </button>
                </div>
                <div className="curate_gifts_selected_info">
                  <div className="curate_gifts_selected_info_images">
                    {item.images.length > 1 && (
                      <Swiper spaceBetween={-2} slidesPerView={3} navigation>
                        {item.images.map((el) => {
                          return (
                            <SwiperSlide key={item.id}>
                              <div className="curate_gifts_selected_info_images_slider">
                                <img
                                  src={el.src}
                                  key={el.id}
                                  alt="Slider images"
                                  onClick={() => handleImage(el.src)}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    )}
                  </div>
                  <h5>{item.title}</h5>
                  <span className="curate_gifts_selected_brand" style={{ color: "#B1B1B1" }}>
                    <p>
                      {" "}
                      <span
                        style={{
                          textDecoration: "none",
                          display: "inline-block"
                        }}
                      >
                        By{" "}
                      </span>{" "}
                      <span style={{ textDecoration: "underline" }}>{item.vendor}</span>
                    </p>
                  </span>
                  <h5>${item.variants[0].price}</h5>
                  <div className="curate_gifts_selected_info_description">
                    <div
                      className="curate_gifts_selected_info_description_inner"
                      dangerouslySetInnerHTML={{ __html: item.body_html }}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SelectedGift;
