import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { average } from "color.js";
import axios from "../../../api_conf/BaseUrl";
import { toast } from "react-toastify";
import cn from "classnames";

import DesignCardFilter from "./DesignCardFilter";
import FlipCardInfo from "./FlipCardInfo";
import ChooseCardFilter from "./ChooseCardFilter";
import MessageSettings from "./MessageSettings";
import TemplatePreview from "./TemplatePreview/TemplatePreview";
import EnvelopeColors from "./DesignColors/EnvelopeColors";
import BackgrdoundColors from "./DesignColors/BackgrdoundColors";

import "./DesignCard.scss";

import {
  addNewCard,
  toggleInfo,
  hideToggleInfo,
  showToggleInfo,
  addDesignLayoutTemplate,
  addBackgroundImages,
  handlebackDomColor,
  addBackgroundImage,
  addBackgroundColor,
  addEnvelopeColor,
  addCardFontFamily,
  addTextColor,
  addCard,
  enableLogo,
  changeAlignment,
  changeLetterCasing,
  changeTextSize,
  changeLetterSpacing,
  changeLineHeight,
  disableLogo
} from "../../../store/designCardSlice";
import FlipIcon from "./FlipIcon/FlipIcon";
import TextColors from "./DesignColors/TextColors";

const DesignCard = ({
  page,
  FormTitles,
  multiSelect = false,
  cardMessage,
  setCardMessage,
  campaign,
}) => {
  const dispatch = useDispatch();
  const messageRef = useRef();
  const [image, setImage] = useState("");
  const [cardsData, setCardData] = useState([]);
  const [cardItems, setCardItems] = useState([]);
  const [showCard, setShowCard] = useState(true);
  const [selection, setSelection] = useState([]);
  const [showFlipIcon, setShowFlipIcon] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const envelopeColorPicker = useSelector(
    (state) => state.designCard.envelopeColorPicker
  );
  const backgroundColorPicker = useSelector(
    (state) => state.designCard.backgroundColorPicker
  );
  const textColorPicker = useSelector(
    (state) => state.designCard.textColorPicker
  );

  const showInfo = useSelector((state) => state.designCard.showInfo);
  const background = useSelector((state) => state.designCard.backgroundImage);
  const envelopeColor = useSelector((state) => state.designCard.envelopeColor);
  const backgroundColor = useSelector(
    (state) => state.designCard.backgroundColor
  );
  const messageSettings = useSelector(
    (state) => state.designCard.showMessageSettings
  );
  const backgroundDominantColor = useSelector(
    (state) => state.designCard.backgroundDominantColor
  );
  const previewLogo = useSelector((state) => state.designCard.previewLogo);
  const logoSelected = useSelector((state) => state.designCard.logoSelected);

  const alignment = useSelector((state) => state.designCard.alignment);
  const textColor = useSelector((state) => state.designCard.textColor);
  const cardItem = useSelector((state) => state.designCard.card);
  const textSize = useSelector((state) => state.designCard.textSize);
  const letterSpacing = useSelector((state) => state.designCard.letterSpacing);
  const lineHeight = useSelector((state) => state.designCard.lineHeight);
  const letterCasing = useSelector((state) => state.designCard.letterCasing);
  const cardFontFamily = useSelector(
    (state) => state.designCard.cardFontFamily
  );

  const [showBack, setShowBack] = useState(false);
  const [cards, setCards] = useState({
    id: 1,
    variant: "click",
    front: "",
    back: "Back",
    isBack: true
  });

  const handleFlipCard = (card) => {
    if (card.variant === "click") {
      setShowBack(!showBack);
      dispatch(toggleInfo());
      setCards({
        ...card,
        isBack: !card.isBack
      });
    }

    if (!card.isBack) {
      dispatch(hideToggleInfo());
    } else {
      dispatch(showToggleInfo());
    }
  };

  useEffect(() => {
    filterCard(selection?.[0]);
  }, [selection]);

  useEffect(() => {
    getDesignCard();
  }, []);

  const getDesignCard = async () => {
  
    try {
      const res = await axios.get(`postdesigncard/?campaign_id=${campaign.id}`);
      if (res?.data?.success) {
        dispatch(addBackgroundImage(res?.data?.Templates_data?.DesignBackgroundImageColor !== "" ? "" [res?.data?.Templates_data?.DesignBackgroundImage] : null));
        dispatch(addBackgroundColor(res?.data?.Templates_data?.DesignBackgroundImageColor === "" ? "#eee" : res?.data?.Templates_data?.DesignBackgroundImageColor));
        dispatch(addCard(res?.data?.Templates_data?.DesignCardImage))
        dispatch(addEnvelopeColor(res?.data?.Templates_data?.EnvelokColor === "" ? '#e75753' : res?.data?.Templates_data?.EnvelokColor));
        dispatch(changeLetterCasing(res?.data?.Templates_data?.changeLetterCasing === "" ? "uppercase" : res?.data?.Templates_data?.changeLetterCasing));
        dispatch(changeLetterSpacing(res?.data?.Templates_data?.LetterSpacing === null ? 1 : res?.data?.Templates_data?.LetterSpacing));
        dispatch(changeLineHeight(res?.data?.Templates_data?.LineHeight === null ? 1 : res?.data?.Templates_data?.LineHeight));
        dispatch(changeAlignment(res?.data?.Templates_data?.MessageAlignment === "" ? "left" : res?.data?.Templates_data?.MessageAlignment));
        dispatch(addCardFontFamily(res?.data?.Templates_data?.MessageFontFamily === "" ? "DM Sans, sans-serif" : res?.data?.Templates_data?.MessageFontFamily));
        dispatch(addTextColor(res?.data?.Templates_data?.MessageTextColor === "" ? "#000" : res?.data?.Templates_data?.MessageTextColor));
        dispatch(changeTextSize(res?.data?.Templates_data?.MessageTextSize === null ? 18 : res?.data?.Templates_data?.MessageTextSize));
        let temp = cardMessage;
        temp.message = res?.data?.Templates_data?.Message_Data;
        setCardMessage(temp);
        if(res?.data?.Templates_data?.logo) {
          dispatch(enableLogo())
          } else {
          dispatch(disableLogo())
        }
        
        
      }
    } catch (error) {
      if (error.reponse) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    const getCards = async () => {
      try {
        const res = await axios.get("designcardimage/");
        if (res?.data?.success) {
          setCardData(res.data.response);
          setCardItems([
            ...new Set(res.data.response?.map((currElem) => currElem.category))
          ]);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      }
    };

    getCards();
  }, []);

  useEffect(() => {
    const getBackground = async () => {
      try {
        const res = await axios.get("designcardbackgroundimage/");
        if (res?.data?.success) {
          dispatch(addBackgroundImages(res.data.response));
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      }
    };

    getBackground();
  }, []);

  const filterCard = (item) => {
    if (item === "All") {
      dispatch(addNewCard(cardsData));
      return;
    }
    const updatedCard = cardsData?.filter((currElem) => {
      return currElem?.category === item;
    });
    dispatch(addNewCard(updatedCard));
  };

  const toggleEdit = () => {
    setShowCard(!showCard);
  };

  const addCardCategory = (item) => {
    if (!selection?.some((currElem) => currElem === item)) {
      if (!multiSelect) {
        setSelection([item]);
      } else if (multiSelect) {
        setSelection([...selection, item]);
      }
    } else {
      let cardAfterRemoval = selection;
      cardAfterRemoval = cardAfterRemoval.filter(
        (currElem) => currElem !== item
      );

      setSelection([...cardAfterRemoval]);
    }
  };

  const cardInSelection = (item) => {
    if (selection?.find((currElem) => currElem === item)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (background?.length > 0) {
      background?.map((item) => {
        setImage(item?.image);
      });
    } else {
      setImage(undefined);
    }
  }, [background]);

  const designCardHtml = document.querySelector(".design_card_main_container");
  const designCardTemplate = `<html><head></head><body>${designCardHtml?.outerHTML}</body></html>`;

  useEffect(() => {
    dispatch(addDesignLayoutTemplate(designCardTemplate));
  }, [designCardTemplate, cardMessage, messageRef?.current?.value]);

  const handleCardMessage = () => {
    setCardMessage({
      ...cardMessage,
      message: messageRef?.current?.value
    });
    // setCardMessage(messageRef?.current?.value);
  };

  useEffect(() => {
    if (messageRef && messageRef.current) {
      messageRef.current.addEventListener("input", function () {
        while (
          messageRef.current.clientHeight < messageRef.current.scrollHeight
        ) {
          messageRef.current.value = messageRef.current.value.substr(
            0,
            messageRef.current.value.length - 1
          );
        }
      });
    }
  }, [messageRef?.current?.value]);

  const buttonStyles = {
    cursor: "pointer",
    position: "absolute",
    left: "30%",
    top: "2%",
    padding: "10px",
    outline: "none",
    border: "none",
    fontSize: "1.2rem",
    zIndex: "12",
    filter: background?.length > 0 ? "invert(1)" : "invert(25%)",
    color: backgroundDominantColor,
    backgroundColor: "transparent"
  };

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      let temp = cardMessage.message;
      temp = cardMessage.message + `/n`;
      setCardItems(temp);
    }
  };

  const sampleTemplate =
    "https://images.pexels.com/photos/1037997/pexels-photo-1037997.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940";

  const dominantColor = async () => {
    const rgb = await average(axios.defaults.devMediaUrl + image, {
      amount: 1,
      format: "hex",
      group: 30
    });
    dispatch(handlebackDomColor(rgb));
  };
  useEffect(() => {
    dominantColor();
  }, [image]);

  useEffect(() => {
    invertColor(backgroundColor);
  }, [backgroundColor]);

  const invertColor = (col) => {
    col = col.toLowerCase();
    const colors = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f"
    ];
    let inverseColor = "#";
    col
      .replace("#", "")
      .split("")
      .forEach((i) => {
        const index = colors.indexOf(i);
        inverseColor += colors.reverse()[index];
      });
    dispatch(handlebackDomColor(inverseColor));
    return inverseColor;
  };

  return (
    <React.Fragment>
      <div className="design_card">
        {showCard && (
          <div className="choose_card_filter">
            <div className="choose_card_filter_container">
              <React.Fragment>
                <div className="choose_card_filter_title">
                  <h4>Card Occasion</h4>
                </div>
                <ChooseCardFilter
                  cardItems={cardItems}
                  cardInSelection={cardInSelection}
                  filterCard={addCardCategory}
                />
                <button
                  disabled={selection?.length > 0 ? false : true}
                  onClick={toggleEdit}
                  className="choose_card_next"
                >
                  Next
                </button>
              </React.Fragment>
            </div>
          </div>
        )}
        {envelopeColorPicker && <EnvelopeColors />}
        {backgroundColorPicker && <BackgrdoundColors />}
        {textColorPicker && <TextColors />}
        {messageSettings && <MessageSettings />}
        <button style={buttonStyles} onClick={handleShowPreview}>
          Preview
        </button>
        {showPreview && (
          <TemplatePreview
            image={image}
            cardItem={cardItem}
            envelopeColor={envelopeColor}
            backgroundColor={backgroundColor}
            textColor={textColor}
            textSize={textSize}
            letterSpacing={letterSpacing}
            lineHeight={lineHeight}
            alignment={alignment}
            letterCasing={letterCasing}
            cardFontFamily={cardFontFamily}
            cardMessage={messageRef?.current?.value}
            setShowPreview={setShowPreview}
          />
        )}

        {showInfo && (
          <FlipCardInfo
            page={page}
            FormTitles={FormTitles}
            showFlipIcon={showFlipIcon}
            setShowFlipIcon={setShowFlipIcon}
          />
        )}
        <div className="design_card_container">
          <div className="design_card_sidebar">
            <div className="design_card_sidebar_title">
              <h5>{FormTitles[page]}</h5>
            </div>
            <div className="design_card_filter_container">
              <div className="design_card_toggle_container">
                <p>{selection}</p>
                <button
                  className="design_card_toggle_edit"
                  onClick={toggleEdit}
                >
                  Edit
                </button>
              </div>
              <DesignCardFilter
                cardMessage={cardMessage}
                setCardMessage={setCardMessage}
                messageRef={messageRef}
              />
            </div>
          </div>
            <div
              className="design_card_main_container"
              style={{
                position: "relative",
                background: `${backgroundColor} url(${
                  axios.defaults.devMediaUrl + image
                }) no-repeat center center/cover`
              }}
            >
              <div onClick={() => handleFlipCard(cards)}>
                <FlipIcon
                  background={background}
                  color={backgroundDominantColor}
                />
              </div>
              <div className="design_flip_card">
                <div key={cards.id} className="flip-card-outer">
                  <div
                    className={cn("flip-card-inner", {
                      showBack,
                      "hover-trigger": cards.variant === "hover"
                    })}
                  >
                    <div
                      className="card front"
                      style={{
                        background:
                          cardItem?.image !== undefined
                            ? `url(${
                                axios.defaults.devMediaUrl + cardItem?.image
                              }) no-repeat center center/ cover`
                            : `url(${sampleTemplate}) no-repeat center center/ cover`
                      }}
                    >
                      <div className="card-body d-flex justify-content-center align-items-center">
                        <p className="card-text fs-1 fw-bold">{cards.front}</p>
                      </div>
                    </div>
                    <div className="card back" style={{padding:"10px"}}>
                      <div className="card-body">
                        {logoSelected ? (
                          <div className="preview_logo">
                            <img
                              src={axios.defaults.devMediaUrl + previewLogo}
                              alt="Logo"
                              title="Logo"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <textarea
                          ref={messageRef}
                          name="message"
                          id="message"
                          placeholder="Tap To Personalize A Message. Feel Free To Type {user_first_name} Wherever You Want To Dynamically Use Your Recipient’s First Name"
                          style={{
                            color: textColor,
                            fontSize: textSize,
                            letterSpacing: letterSpacing,
                            lineHeight: lineHeight,
                            textAlign: alignment,
                            fontFamily: cardFontFamily,
                            textTransform:letterCasing,
                          }}
                          onFocus={(e) => (e.target.placeholder = "")}
                          value={cardMessage?.message}
                          onChange={handleCardMessage}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="design_card_envelope_container">
                <svg
                  width="850"
                  height="850"
                  viewBox="0 0 850 850"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="designTemplate">
                    <path
                      id="lid1"
                      d="M304.182 17.5932L60.5962 354.315C53.7081 363.837 50 375.289 50 387.041C50 394.75 56.2496 401 63.9589 401H573C581.837 401 589 393.837 589 385V383.9C589 370.045 584.504 356.565 576.188 345.484L329.942 17.3671C323.472 8.74527 310.5 8.85911 304.182 17.5932Z"
                      fill={envelopeColor}
                    />

                    <path
                      id="lid1layer"
                      d="M86 401H557L320 74L86 401Z"
                      fill="url(#paint0_linear_1_2)"
                    />

                    <path
                      id="envelope_cover"
                      d="M557.976 400H87L157.47 500H486.331L557.976 400Z"
                      fill="white"
                    />

                    <image
                      id="letter1"
                      d="M159 221H488V525H159V221Z"
                      style={{
                        objectFit: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                      }}
                      href={
                        cardItem?.image !== undefined
                          ? axios.defaults.devMediaUrl + cardItem?.image
                          : sampleTemplate
                      }
                    />

                    <path
                      id="envelope"
                      d="M41.543 710.859C40.8751 683.364 40.562 603.626 42.1809 416.994C42.2571 408.213 49.3986 401 58.1792 401H88L156.5 499H487L556.5 401H583.052C591.869 401 599.024 408.133 599.052 416.95L599.95 706.771C599.978 715.627 592.806 722.821 583.95 722.821H54.7603C47.4435 722.821 41.7207 718.174 41.543 710.859Z"
                      fill={envelopeColor}
                    />

                    <path
                      id="letter2"
                      d="M159 221H488V525H159V221Z"
                      fill="white"
                      stroke-width="0.2"
                      stroke="#000"
                    />

                    <g id="letter_data">
                      <foreignObject
                        x="-25"
                        y="5"
                        width="380"
                        height="560"
                        style={{
                          wordBreak: "break-all",
                          display: "inline-block"
                        }}
                      >
                        <div
                          xmlns="http://www.w3.org/1999/xhtml"
                          style={{
                            // padding: "10px",
                            height: "500px",
                            width: "380px"
                          }}
                        >
                          <pre
                            style={{
                              color: textColor,
                              fontSize: textSize,
                              letterSpacing: letterSpacing,
                              lineHeight: lineHeight,
                              textAlign: alignment,
                              // textTransform: letterCasing,
                              fontFamily: cardFontFamily,
                              overflow: "hidden",
                              overflowY: "none"
                            }}
                          >
                            {/* {cardMessage.message} */}
                            {messageRef?.current?.value}
                          </pre>
                        </div>
                      </foreignObject>
                    </g>

                    <path
                      id="lid2layer"
                      d="M86 402H557L320 662L86 402Z"
                      fill="url(#paint0_linear_1_2)"
                    />

                    <path
                      id="lid2"
                      d="M305.287 706.22L60.4139 436.938C53.7131 429.569 50 419.967 50 410.007C50 404.48 54.4804 400 60.0073 400H577.187C583.711 400 589 405.289 589 411.813C589 423.868 584.392 435.468 576.119 444.237L328.763 706.435C322.368 713.213 311.556 713.114 305.287 706.22Z"
                      fill={envelopeColor}
                      stroke="black"
                      stroke-width="0.3"
                    />
                  </g>

                  {/* defs */}

                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2"
                      x1="333.224"
                      y1="83.236"
                      x2="333.224"
                      y2="418.947"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.848958" stop-color="white" />
                      <stop offset="1" stop-color="#D9D9D9" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesignCard;
