import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterTags: [],
};

const curateGiftSlice = createSlice({
  name: "curateGift",
  initialState,
  reducers: {
    addFilterTags: (state, action) => {
      const tagIndex = state.filterTags.findIndex(
        (item) => item.id === action.payload.id
      );

      if (tagIndex >= 0) {
        return;
      } else {
        const newTag = { ...action.payload };
        state.filterTags.push(newTag);
      }
    },
  },
});

export const { addFilterTags } = curateGiftSlice.actions;
export default curateGiftSlice.reducer;
