import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { alignment,  letterCase } from "./DesignCardUtil/SettingsUtil";
import DeleteSettings from "../../../assets/images/delete_message_settings.svg";

import {
  increaseTextSize,
  decreaseTextSize,
  changeTextSize,
  increaseLetterSpacing,
  decreaseLetterSpacing,
  changeLetterSpacing,
  increaseLineHeight,
  decreaseLineHeight,
  changeLineHeight,
  changeLetterCasing,
  resetSettings,
  changeAlignment,
  hideSettings
} from "../../../store/designCardSlice";

const MessageSettings = () => {
  const dispatch = useDispatch();
  const textSize = useSelector((state) => state.designCard.textSize);
  const letterSpacing = useSelector((state) => state.designCard.letterSpacing);
  const lineHeight = useSelector((state) => state.designCard.lineHeight);
  // const letterCasing = useSelector((state) => state.designCard.letterCasing);

  const imageStyles = {
    height: "20px",
    width: "20px"
  };


  const handleCloseSettings = () => {
    dispatch(hideSettings());
  };

  const handleResetSettings = () => {
    dispatch(resetSettings());
  };

  const handleTextSize = (e) => {
    console.log(e.target.value);
    dispatch(changeTextSize(e.target.value));
  };

  const handleIncreaseTextSize = (e) => {
    dispatch(increaseTextSize());
  };
  const handleDecreaseFontSize = () => {
    dispatch(decreaseTextSize());
  };

  const handleIncreaseLetterSpacing = () => {
    dispatch(increaseLetterSpacing());
  };

  const handleDecreaseLetterSpacing = () => {
    dispatch(decreaseLetterSpacing());
  };

  const handleLetterSpacing = (e) => {
    dispatch(changeLetterSpacing(e.target.value));
  };

  const handleIncreaseLineHeight = () => {
    dispatch(increaseLineHeight());
  };

  const handleDecreaseLineHeight = () => {
    dispatch(decreaseLineHeight());
  };

  const handleLineHeight = (e) => {
    dispatch(changeLineHeight(e.target.value));
  };

  const handleAlignment = (item) => {
    if (item.align === "left") {
      dispatch(changeAlignment("left"));
    } else if (item.align === "center") {
      dispatch(changeAlignment("center"));
    } else {
      dispatch(changeAlignment("right"));
    }
  };

  const handleCase = (item) => {
    if (item.name === "uppercase") {
      dispatch(changeLetterCasing("uppercase"));
    } else if (item.name === "lowercase") {
      dispatch(changeLetterCasing("lowercase"));
    } else {
      dispatch(changeLetterCasing("capitalize"));
    }
  };

  const handleDeleteSettings = () => {
    dispatch(hideSettings());
    dispatch(resetSettings());
  };

  return (
    <div className="design_card_message_settings">
      <div className="message_settings_container">
        <div className="message_settings_top">
          <div className="message_settings_close">
            <button onClick={handleCloseSettings}>x</button>
          </div>
          <div className="message_settings_reset">
            <button onClick={handleResetSettings}>Reset</button>
          </div>
        </div>
        <div className="message_settings_options">
          <div className="message_settings_control_input">
            <button
              className="message_settings_handle_options"
              onClick={handleDecreaseFontSize}
            >
              -
            </button>
            <div className="message_settings_input_container">
              <input type="number" value={textSize} onChange={handleTextSize} />
              <p>Text Size</p>
            </div>
            <button
              className="message_settings_handle_options"
              onClick={handleIncreaseTextSize}
            >
              +
            </button>
          </div>
          <div className="message_settings_control_input">
            <button
              className="message_settings_handle_options"
              onClick={handleDecreaseLetterSpacing}
            >
              -
            </button>
            <div className="message_settings_input_container">
              <input
                type="number"
                value={letterSpacing?.toFixed(1)}
                onChange={handleLetterSpacing}
              />
              <p>Letter Spacing</p>
            </div>
            <button
              className="message_settings_handle_options"
              onClick={handleIncreaseLetterSpacing}
            >
              +
            </button>
          </div>
          <div className="message_settings_control_input">
            <button
              className="message_settings_handle_options"
              onClick={handleDecreaseLineHeight}
            >
              -
            </button>
            <div className="message_settings_input_container">
              <input
                type="number"
                value={lineHeight?.toFixed(1)}
                onChange={handleLineHeight}
              />
              <p>Line Height</p>
            </div>
            <button
              className="message_settings_handle_options"
              onClick={handleIncreaseLineHeight}
            >
              +
            </button>
          </div>
          <div className="message_settings_control_selection">
            {alignment?.map((item) => (
              <div
                key={item.id}
                className="message_settings_alignment"
                onClick={() => handleAlignment(item)}
              >
                {item.icon}
              </div>
            ))}
            <p>Alignment</p>
          </div>
          <div className="message_settings_control_selection">
            {letterCase?.map((item) => (
              <div
                key={item.id}
                className="message_settings_casing"
                onClick={() => handleCase(item)}
                style={{ textTransform: item.name }}
              >
                {item.text}
              </div>
            ))}
            <p>Letter casing</p>
          </div>
          <div className="message_settings_delete_selection">
            <img
              src={DeleteSettings}
              onClick={handleDeleteSettings}
              alt="Delete Settings"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSettings;
