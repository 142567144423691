import axios from "axios";

const instance = axios.create({
  //baseURL:"http://ce36-14-97-215-162.ngrok.io",
  // baseURL: "http://103.46.239.133:8011/",
  // devMediaUrl: "http://103.46.239.133:8011",
  baseURL: "https://backend.heykaido.com/",
  devMediaUrl: "https://backend.heykaido.com"
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

export default instance;
