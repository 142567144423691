import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  selectLogo,
  showDropdownSettings
} from "../../../store/designCardSlice";

const DesignFilter = ({
  id,
  filter,
  component,
  isClosed,
  isOpened,
  toggle
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const handleShowValue = () => {
    setShow(!show);

    if (id === 4) {
      dispatch(showDropdownSettings());
    }

    // if (id === 5) {
    //   setSelected(!selected);
    //   dispatch(selectLogo());
    // }
    //console.log("hello",show,component)
  };

  return (
    <React.Fragment>
      <div className="design_filter_heading_container">
        <div className="design_filter_heading">
          <h6>{filter}</h6>
          <p onClick={handleShowValue}>
            {show && component ? isOpened : component ? isClosed : toggle}
          </p>
        </div>
        {show && component}
      </div>
    </React.Fragment>
  );
};

export default DesignFilter;
