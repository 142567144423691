import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function UpdateUserFormContainer(props) {
  const {
    _setEmail,
    _setBirthdate,
    _setJoiningDate,
    _birthdate,
    _joiningDate,
  } = props;

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  return (
    <div className="addUserContainer">
      <form>
        <div className="form_control">
          <label> Email </label>
          <input
            type="email"
            placeholder="johndoe@gmail.com"
            onChange={(event) => _setEmail(event.target.value)}
          />
        </div>
        <div className="form_control">
          <label>Birthday</label>

          <DatePicker
            selected={_birthdate}
            placeholderText="MM/ DD"
            onChange={(date) => _setBirthdate(date)}
            dateFormat="MM/dd"
            autoComplete="off"
          />
        </div>

        <div className="form_control">
          <label>Joining Date</label>
          <DatePicker
            selected={_joiningDate}
            placeholderText="MM/ DD/ YYYY"
            onChange={(date) => _setJoiningDate(date)}
            dateFormat="MM/dd/yyyy"
            maxDate={new Date()}
            autoComplete="off"
            showYearDropdown
            scrollableMonthYearDropdown
          />
        </div>
      </form>
    </div>
  );
}
