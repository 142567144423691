import React, { useEffect, useState,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from "../../api_conf/BaseUrl";
import search from "../../assets/images/Vector.png";
import downarrow from '../../assets/images/downarrow.png';
import uparrow from '../../assets/images/uparrow.png';
import GiftIcon from "../../assets/images/gift-solid.svg";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../context/GlobalState";
import moment from "moment";

import XLSX from "xlsx";


const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 450,
    },
  });

const GiftStatusTable = (props) => {
    const classes = useStyles();
    const [data,setData] = useState([]);
    const [mainData,setMainData] = useState([]);
    const [searchTerm,setSearchTerm] = useState("");
    
    const [all,setAll] = useState(true);
    const [claim,setClaimed] = useState(false);
    const [sent,setSent] = useState(false);
    const [delivered,setDelivered] = useState(false);
    const [scheduled,setScheduled] = useState(false);
    
    const [name,setName] = useState(true);
    const [email,setEmail] = useState(true);
    const [gift,setGift] = useState(true);

    const {
      setScheduledDate,
      setScheduledResponse,
      setTotalCostResponse,
      giftTransitStatus,
      setgiftTransitStatus,
      filteredArray,
      setFilteredArray,
      selectedtabId,
      setselectedtabId,
      loading,
      setLoading
    } = useContext(GlobalContext);
  
    const handleDownload = async () => {
        try {
          let res = await axios.post("download_gift_transit_list/", {
            campaign: props.id,
            status: ""
          });
          const outputFilename = `source.xlsx`;
          const data = res.data.response;
    
          const downloadxls = (data) => {
            let ws = XLSX.utils.json_to_sheet(data);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet");
            XLSX.writeFile(wb, outputFilename);
          };
          downloadxls(data);
        } catch (error) {}
      };
          const handleAll = () => {
            setAll(true);
            setClaimed(false);
            setSent(false);
            setDelivered(false);
            setScheduled(false);
        axios.get(`gifttransitlist/?page=1&campaign=${props.id}&status=`).then((res) => {
           setData([]);
            setData(res.data.response?res.data.response:"");
            setMainData(res.data.response?res.data.response:"");
          //    console.log("hello",res.data.response?res.data.response:"");
          })
        }
       const handleClaimed = () => {
           setClaimed(true);
           setAll(false);
           setSent(false);
           setDelivered(false);
           setScheduled(false);
        axios.get(`gifttransitlist/?page=1&campaign=${props.id}&status=claimed`).then((res) => {
            setData([]);
            setData(res.data.response?res.data.response:"");
            setMainData(res.data.response?res.data.response:"");
         //    console.log("hello",res.data.response?res.data.response:"");
         })
       }

       const handleSent = () => {
           setClaimed(false);
           setAll(false);
           setSent(true);
           setDelivered(false);
           setScheduled(false);
        axios.get(`giftlinksentdetails/?page=1&campaign_id=${props.id}`).then((res) => {
            setData([]);
            setData(res.data.response?res.data.response:"");
            setMainData(res.data.response?res.data.response:"");
         //    console.log("hello",res.data.response?res.data.response:"");
         })
       }

       const handleDelivered = () => {
           setClaimed(false);
           setAll(false);
           setSent(false);
           setDelivered(true);
           setScheduled(false);
        axios.get(`gifttransitlist/?page=1&campaign=${props.id}&status=delivered`).then((res) => {
            setData([]);
            setData(res.data.response?res.data.response:"");
            setMainData(res.data.response?res.data.response:"");
         //    console.log("hello",res.data.response?res.data.response:"");
         })
       }

       const handleScheduled = () => {
           setClaimed(false);
           setAll(false);
           setSent(false);
           setDelivered(false);
           setScheduled(true);
        axios.get(`scheduled_gift/?campaign=${props.id}`).then((res) => {
            setData([]);
            setData(res.data.response?res.data.response:"");
            setMainData(res.data.response?res.data.response:"");
         //    console.log("hello",res.data.response?res.data.response:"");
         })
       }
        
       const handleAscNameSort = () => {
          
           const mainDataTemp = [...mainData]
      
            let sorted = mainDataTemp.sort((a, b) => (a["recipient name"] > b["recipient name"]) ? 1 : -1)
            setName(true);
            setData(sorted)
            setMainData(sorted)
           
       }

       const handleDscNameSort = () => {
          
        const mainDataTemp = [...mainData]
   
         let sorted = mainDataTemp.sort((a, b) => (a["recipient name"] < b["recipient name"]) ? 1 : -1)
         setName(false);
         setData(sorted)
         setMainData(sorted)
        
    }
     
    const handleAscEmailSort = () => {
      const mainDataTemp = [...mainData]
      
      let sorted = mainDataTemp.sort((a, b) => (a["send_date"] > b["send_date"]) ? 1 : -1)
      setEmail(true);
      setData(sorted)
      setMainData(sorted)
    }

    const handleDscEmailSort = () => {
      const mainDataTemp = [...mainData]
      
      let sorted = mainDataTemp.sort((a, b) => (a["send_date"] < b["send_date"]) ? 1 : -1)
      setEmail(false);
      setData(sorted)
      setMainData(sorted)
    }

    const handleAscSchSort = () => {
      const mainDataTemp = [...mainData]
      
      let sorted = mainDataTemp.sort((a, b) => (a["scheduled_date"] > b["scheduled_date"]) ? 1 : -1)
      setEmail(true);
      setData(sorted)
      setMainData(sorted)
    }

    const handleDscSchSort = () => {
      const mainDataTemp = [...mainData]
      
      let sorted = mainDataTemp.sort((a, b) => (a["scheduled_date"] < b["scheduled_date"]) ? 1 : -1)
      setEmail(false);
      setData(sorted)
      setMainData(sorted)
    }

    const handleAscGiftSort = () => {
      const mainDataTemp = [...mainData]
      
      let sorted = mainDataTemp.sort((a, b) => (a["gift choosen date"] > b["gift choosen date"]) ? 1 : -1)
      setGift(true);
      setData(sorted)
      setMainData(sorted)
    }

    const handleDscGiftSort = () => {
      const mainDataTemp = [...mainData]

      let sorted = mainDataTemp.sort((a, b) => (a["gift choosen date"] < b["gift choosen date"]) ? 1 : -1)
      setGift(false);
      setData(sorted)
      setMainData(sorted)
    }
     
    const getScheduledDates = async () => {
      try {
        let res = await axios.get("scheduled_gift_by_date/", {
          params: { campaign_id:props.id }
        });
  
        if (res?.data?.success) {
          setScheduledDate(res?.data?.dates?.map((dates) => dates));
          setScheduledResponse(res.data.response);
          setTotalCostResponse(res.data.estimated_cost);
        } else if (res?.data?.message === "Nothing to show!") {
          setScheduledDate([]);
          setScheduledResponse([]);
          setTotalCostResponse(0);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const deleteScheduleDates = (item) => {
      axios
        .delete("scheduled_gift/", {
          data: {
            ids: [item]
          }
        })
        .then((result) => {
          if (result.data.success) {
            getScheduledDates();
            setgiftTransitStatus([]);
            //getData(id);
            toast.success("Deleted successfully!");
            {scheduled? handleScheduled():handleAll()}
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else if (error.request) {
          } else {
            toast.error(error);
          }
        });
    };

    useEffect(() => {
        axios.get(`gifttransitlist/?page=1&campaign=${props.id}&status=`).then((res) => {
           setData(res.data.response?res.data.response:"");
           setMainData(res.data.response?res.data.response:"");
        //    console.log("hello",res.data.response?res.data.response:"");
        })
        getScheduledDates();
    },[])

  return (
    <div>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px",fontSize:'13px',lineHeight:"17px",padding:"5px",fontWeight:"700",color:"#989898",marginBottom:"20px"}}>
              <div style={{color:'#023047'}}>Sort By:</div>
              <div onClick={handleAll} style={all?{background:'#FFFFFF',width: "85px",height: "33px",padding:"8px 16px",borderRadius:"3px",cursor:"pointer",color:"#323C47"}:{cursor:"pointer",width: "85px",height: "33px",padding:"8px 16px"}}>All</div>
              <div onClick={handleClaimed} style={claim?{background:'#FFFFFF',width: "85px",height: "33px",padding:"8px 16px",borderRadius:"3px",cursor:"pointer",color:"#323C47"}:{cursor:"pointer",width: "85px",height: "33px",padding:"8px 16px"}}>Claimed</div>
              <div onClick={handleSent} style={sent?{background:'#FFFFFF',width: "85px",height: "33px",padding:"8px 16px",borderRadius:"3px",cursor:"pointer",color:"#323C47"}:{cursor:"pointer",width: "85px",height: "33px",padding:"8px 16px"}}>Sent</div>
              <div onClick={handleDelivered} style={delivered?{background:'#FFFFFF',width: "85px",height: "33px",padding:"8px 14px",borderRadius:"3px",cursor:"pointer",color:"#323C47"}:{cursor:"pointer",width: "85px",height: "33px",padding:"8px 14px"}}>Delivered</div>
              <div onClick={handleScheduled} style={scheduled?{background:'#FFFFFF',width: "85px",height: "33px",padding:"8px 12px",borderRadius:"3px",cursor:"pointer",color:"#323C47"}:{cursor:"pointer",width: "85px",height: "33px",padding:"8px 12px"}}>Scheduled</div>
          </div>
         <Paper>
             <div style={{textAlign:"left",border:"1px solid transparent",display:"flex",justifyContent:"start",alignItems:"center"}}>
               <div style={{width:"688px",height:"34px",border:"1px solid #A8B8BF",borderRadius:"4px",marginTop:"10px",marginLeft:"10px",padding:"5px"}}>
               <img style={{marginLeft:"15px"}} src={search} alt="search icon"/>
               <input onChange={(e) => {console.log(e.target.value)
                 setSearchTerm(e.target.value)}} type="text" style={{border:"none",marginLeft:"15px",fontSize:"14px",lineHeight:"18px",outline:"none",width:"92%"}}  placeholder="Search recepient name or email" />
               </div>
               <button style={{width:"98px",height:"37px",background:"#219EBC",color:"#FFFFFF",borderRadius:"6px",border:"none",marginTop:"10px",marginLeft:"15px",cursor:"pointer"}}>
                   SEARCH
               </button>
             </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{color:"#5E7D8C",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Name {name?<span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleDscNameSort()}><img src={downarrow} alt="" /></span>:<span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleAscNameSort()}><img src={uparrow} alt="" /></span>}</TableCell>
              <TableCell style={{color:"#A8B8BF",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Email</TableCell>
              <TableCell style={{color:"#A8B8BF",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Status</TableCell>
              <TableCell style={{color:"#A8B8BF",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Gift Chosen</TableCell>
              <TableCell style={{color:"#A8B8BF",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Gift Cost</TableCell>
              {scheduled?<TableCell style={{color:"#5E7D8C",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Scheduled Date {email ? <span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleDscSchSort()}><img src={downarrow} alt="" /></span>:<span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleAscSchSort()}><img src={uparrow} alt="" /></span>}</TableCell>:
              <TableCell style={{color:"#5E7D8C",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Email Send Date {email ? <span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleDscEmailSort()}><img src={downarrow} alt="" /></span>:<span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleAscEmailSort()}><img src={uparrow} alt="" /></span>}</TableCell>}
              <TableCell style={{color:"#5E7D8C",fontWeight:"700",fontSize:"16px",fontHeight:"24px"}}>Gift Chosen Date {gift ? <span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleDscGiftSort()}><img src={downarrow} alt="" /></span>:<span style={{cursor:"pointer",marginLeft:"10px"}} onClick={() => handleAscGiftSort()}><img src={uparrow} alt="" /></span>}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.length ? data.filter((val) => {
              if(searchTerm === ""){
                return val;
              }else if(val["recipient name"].toLowerCase().includes(searchTerm.toLowerCase())){
                return val;
              }else if(val["email"].toLowerCase().includes(searchTerm.toLowerCase())){
                return val;
              }
              })
              .map((el) => {
                          return (
                              <TableRow key={el.id}>
                                  <TableCell style={{color:"#023047",fontWeight:"500",fontSize:"14px",fontHeight:"24px"}}>{el["recipient name"]}</TableCell>
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px"}}>{el["email"]}</TableCell>
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px",width:"150px",textTransform:"uppercase"}}>{el["status"]?el["status"]:"-"}</TableCell>
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px",width:"200px"}}>{el["gift chosen"]?el["gift chosen"]:"None"}</TableCell>
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px"}}>$ {el["gift_cost"]?el["gift_cost"]:0}</TableCell>
                                  {scheduled?<TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px"}}>{el["scheduled_date"]?moment(el["scheduled_date"]).format("MM/DD/YYYY"):moment(el["scheduled_date"]).format("MM/DD/YYYY")}{scheduled? <span
                              title="Delete the scheduled date"
                              style={{
                                // display: !selectedtabId === 5 && "none",moment(el["gift choosen date"]).format("MM/DD/YYYY")
                                marginLeft: "15px",
                                cursor: "pointer",
                                color: "red"
                              }}
                              onClick={() => {
                                deleteScheduleDates(el.id);
                              }}
                            >
                              
                                <FontAwesomeIcon icon={faTrash} />
                              
                            </span>:""}</TableCell>:
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px"}}>{(el["status"]==="scheduled")?el["scheduled_date"]?moment(el["scheduled_date"]).format("MM/DD/YYYY"):moment(el["scheduled_date"]).format("MM/DD/YYYY"):el["send_date"]?moment(el["send_date"]).format("MM/DD/YYYY"):moment(el["send_date"]).format("MM/DD/YYYY")}{(el["status"]==="scheduled")?<span
                                  title="Delete the scheduled date"
                                  style={{
                                    // display: !selectedtabId === 5 && "none",moment(el["gift choosen date"]).format("MM/DD/YYYY")
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                    color: "red"
                                  }}
                                  onClick={() => {
                                    deleteScheduleDates(el.id);
                                  }}
                                >
                                  
                                    <FontAwesomeIcon icon={faTrash} />
                                  
                                </span>:""}</TableCell>}
                                  <TableCell style={{color:"#707683",fontWeight:"400",fontSize:"13px",fontHeight:"20px"}}>{el["gift choosen date"]?moment(el["gift choosen date"]).format("MM/DD/YYYY"):"Not Chosen"}
                                 {(el["status"]==="Sent"||el["status"]==="sent")?<CopyToClipboard
                                      onCopy={() => toast.success("Gift link copied")}
                                      text={el["gift_link"]}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "20px"
                                      }}
                                    >
                
                                <span>
                                  <img
                                    title="Copy gift link"
                                    src={GiftIcon}
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                </span>
                              </CopyToClipboard>:""}
                                
                                </TableCell>
                              </TableRow>
                          )
                    }):<TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{width:"150px"}}>No Data Found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      <div style={{textAlign:"left",marginTop:"20px"}}>
          <button onClick={handleDownload} style={{color:"#023047",border:"1px solid #A8B8BF",background:"#F4F8F9",fontSize:"16px",lineHeight:"21px",fontWeight:"500",padding:"8px 16px",borderRadius:"6px"}}>DOWNLOAD TABLE</button>
      </div>
    </div>
  )
}

export default GiftStatusTable

