import * as React from "react";

const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
    width={props.size}
    {...props}
  >
    <defs>
      <linearGradient
        id="yUZaZJj0hIVayuiNAioR7a"
        x1={23.5}
        x2={23.5}
        y1={34}
        y2={43}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f85e55" />
        <stop offset={1} stopColor="#d41414" />
      </linearGradient>
      <linearGradient
        id="yUZaZJj0hIVayuiNAioR7b"
        y1={7}
        y2={32}
        xlinkHref="#yUZaZJj0hIVayuiNAioR7a"
      />
    </defs>
    <g data-name="punctuation">
      <circle cx={23.5} cy={38.5} r={4.5} fill={props.color} />
      <path
        fill={props.color}
        d="M27,7H20a1,1,0,0,0-1,1.043l1,23A1,1,0,0,0,21,32h5a1,1,0,0,0,1-.957l1-23A1,1,0,0,0,27,7Z"
      />
    </g>
  </svg>
);

export default ErrorIcon;
