import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";

import {
  addTextColor,
  hideTextColorPicker
} from "../../../../store/designCardSlice";

import useClickOutside from "../../custom/useClickOutside";

function TextColors() {
  const popover = useRef();
  const dispatch = useDispatch();
  const textColor = useSelector((state) => state.designCard.textColor);
  const [color, setColor] = useState(textColor);

  const close = useCallback(() => dispatch(hideTextColorPicker()), []);
  useClickOutside(popover, close);
  return (
    <div className="envelope_color_picker" ref={popover}>
      <SketchPicker
        color={color}
        onChange={(updatedColor) => dispatch(addTextColor(updatedColor.hex))}
      />
    </div>
  );
}

export default TextColors;
