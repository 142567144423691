import LeftAlign from "../alignment/align-left.svg";
import CenterAlign from "../alignment/align-center.svg";
import RightAlign from "../alignment/align-right.svg";


const imageStyles = {
  height: "20px",
  width: "20px"
};

export const letterCase = [
  {
    id: 0,
    name: "uppercase",
    text: "ag"
  },
  {
    id: 1,
    name: "lowercase",
    text: "ag"
  },
  {
    id: 2,
    name: "capitalize",
    text: "ag"
  }
];

export const alignment = [
  {
    id: 0,
    align: "left",
    icon: <img src={LeftAlign} style={imageStyles} alt="Left Align" />
  },
  {
    id: 1,
    align: "center",
    icon: <img src={CenterAlign} style={imageStyles} alt="Center Align" />
  },
  {
    id: 2,
    align: "right",
    icon: <img src={RightAlign} style={imageStyles} alt="Right Align" />
  }
];
