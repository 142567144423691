import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleGifts } from "../../../store/giftSlice";
import { Tooltip } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import CheckSecondary from "../../../assets/images/check_secondary.svg";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily:"DM Sans",
    backgroundColor: "#023047",
    color: "#fff",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 10px",
    fontSize: "11px",
  },
  arrow:{
    color: "#023047",
  }
}))(Tooltip);

const GiftCuration = ({ item }) => {
  const dispatch = useDispatch();
  const giftAdded = useSelector((state) => state.gifts.giftAdded);
  const handleGiftsData = (item) => {
    if (!giftAdded.some((currElem) => currElem.id === item.id)) {
      dispatch(handleGifts([...giftAdded, item]));
    } else {
      let giftAfterRemoval = giftAdded;
      giftAfterRemoval = giftAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(handleGifts([...giftAfterRemoval]));
    }
  };

  return (
    <React.Fragment>
      <LightTooltip title={<>{item.title}<br/>${item.variants[0].price}</>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 800 }}>
        <div key={item.id} className="gift_curation_container">
          <div className="gift_curation_content">
            <img src={item.image.src} alt={item.title} />
            <button onClick={() => handleGiftsData(item)}>
              <img src={CheckSecondary} alt="Remove gifts" />
            </button>
          </div>
        </div>
      </LightTooltip>
    </React.Fragment>
  );
};

export default GiftCuration;
