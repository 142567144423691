export const items = [
  {
    id: 1,
    name: "DM Sans",
    font: "DM Sans, sans-serif"
  },
  {
    id: 2,
    name: "Water Brush",
    font: "Water Brush, cursive"
  },
  {
    id: 3,
    name: "Chicle",
    font: "Chicle, cursive"
  },
  {
    id: 4,
    name: "Pompiere",
    font: "Pompiere, cursive"
  },
  {
    id: 5,
    name: "Lato",
    font: "Lato, sans-serif"
  },
  {
    id: 6,
    name: "Roboto",
    font: "Roboto, sans-serif"
  },
  {
    id: 7,
    name: "Tapestry",
    font: "Tapestry, cursive"
  },
  {
    id: 8,
    name: "Ms Madi",
    font: "Ms Madi, cursive"
  },
  {
    id: 9,
    name: "Bebas Neue",
    font: "Bebas Neue, cursive"
  },
  {
    id: 10,
    name: "Whisper",
    font: "Whisper, cursive"
  },
  {
    id: 11,
    name: "Orelega One",
    font: "Orelega One, cursive"
  }
];

export const fontWeights = [
  {
    id: 0,
    name: "Regular",
    fontWeight: "lighter"
  },
  {
    id: 1,
    name: "Medium",
    fontWeight: "normal"
  },
  {
    id: 2,
    name: "Bold",
    fontWeight: "bold"
  },
  {
    id: 3,
    name: "Extra Bold",
    fontWeight: "bolder"
  }
];

export const colorMenu = [
  {
    id: 0,
    name: "Header Color"
  },
  {
    id: 1,
    name: "Button Color"
  },
  {
    id: 2,
    name: "Text Color"
  }
];
