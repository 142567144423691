import React, { useState } from "react";

import ThankyouList from "./ThankyouList";
import ThankyouListCard from "./ThankyouListCard";

import "./ThankyouNotes.scss";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import heartIcon from "../../../../assets/images/heart-icon-red.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import thankyou from "../../../../assets/images/thankyou.svg";
import greyheart from "../../../../assets/images/grey-heart.svg";
import { useMediaQuery } from '@mantine/hooks';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 238,
    backgroundColor: "#FBFCFC",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    overflow: "scroll"
  }
}));

// function getModalStyle() {
//   const top = 50;
//   const left = 91;

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }
const ThankyouNotes = ({ thankYouList, manual }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [seeMore, setSeeMore] = useState(false);
  // const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [single, setSingle] = useState(false);
  const [singleList, setSingleList] = useState([]);
   
  const matches_one = useMediaQuery('(min-width: 1650px)');

  const handleSeeMore = () => {
    setSeeMore(!seeMore);
    setSingle(false);
    handleOpen();
  };
  const singleThankyou = () => {
    setSingle(true);
    handleOpen();
  };

  const handleSingleList = (item) => {
    setSingleList(item);
  };

  const bodySingle = (
    <div
      style={{ top: 0, right: 0, height: "100vh" }}
      className={classes.paper}
    >
      <div>
        <div
          style={{ cursor: "pointer", textAlign: "right", color: "#A8B8BF" }}
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ width: "12px", height: "12px" }}
          />
        </div>
        <div
          style={{
            color: "#323C47",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
            marginTop: "15px",
            marginBottom: "15px"
          }}
        >
          Thank You Notes
        </div>
      </div>
      <div>
        <div style={{ marginTop: "2%", marginBottom: "2%" }}>
          <img
            src={heartIcon}
            alt=""
            style={{ margin: "15px 0" }}
            // style={{ marginTop: "5px", marginBottom: "5px" }}
          />
          <ThankyouList open={open} key={singleList.id} notes={singleList} />
        </div>
        <div
          onClick={handleSeeMore}
          style={{
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#219EBC",
            textAlign: "center",
            alignItems: "center",
            marginTop: "10px",
            textDecoration: "underline",
            cursor: "pointer"
          }}
        >
          View all notes
        </div>
      </div>
    </div>
  );
  const body = (
    <div
      style={{ top: 0, right: 0, height: "100vh" }}
      className={classes.paper}
    >
      <div>
        <div
          style={{ cursor: "pointer", textAlign: "right", color: "#A8B8BF" }}
          onClick={handleClose}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ width: "12px", height: "12px" }}
          />
        </div>
        <div
          style={{
            color: "#323C47",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "24px",
            marginTop: "15px",
            marginBottom: "15px"
          }}
        >
          Thank You Notes
        </div>
      </div>
      <div>
        {thankYouList.map((currElem) => (
          <div key={currElem.id} style={{ marginTop: "2%", marginBottom: "2%" }}>
            <img
              src={heartIcon}
              alt=""
              style={{ marginTop: "5px", marginBottom: "5px" }}
            />
            <ThankyouList open={open} key={currElem.id} notes={currElem} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="thank_you_container" style={(matches_one && manual) ? {width:"35.4%"}:{}}>
      <h2 className="thank_you_container_title">
        Thank You Notes
        <span style={{ marginLeft: "5px"}}>
          <img src={thankyou} alt="icon"></img>
        </span>
      </h2>
      <div style={{ width:"100%",height:"280px",overflow:"hidden" }} onClick={singleThankyou}>
        {thankYouList.length ? (
          thankYouList.map((currElem) => (
            <ThankyouListCard
              key={currElem.id}
              notes={currElem}
              singleList={singleList}
              handleSingleList={handleSingleList}
            />
          ))
        ) : (
          <div>
            <div style={{ marginTop: "110px" }}>
              <img src={greyheart} alt="icon"></img>
            </div>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "700",
                lineHeight: "20px",
                color: "#A8B8BF",
                marginTop: "5px"
              }}
            >
              No thank you notes yet.
            </div>
          </div>
        )}
      </div>
      {(thankYouList.length>5)? (
        <div
          className="thank_you_container_see_more"
          style={{ cursor: "pointer" }}
          onClick={handleSeeMore}
        >
          See More
        </div>
      ) : (
        <div
          className="thank_you_container_see_more"
        >
          
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {!single ? body : bodySingle}
      </Modal>
    </div>
  );
};

export default ThankyouNotes;
