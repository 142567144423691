import React, { useEffect } from "react";
import "./Dropdown.scss";
import DropdownIcon from "../../assets/images/Path 342.svg";
import Select from "react-select";
const CustomDropdown = (props) => {
  const customStyles = {
    control: (_, { selectProps: { width } }) => ({
      width: "130px",
      height: "32px",
      background: " #ffffff",
      border: "1px solid #023047",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "flex-start",
      textAlign:"left",
      alignItems:"center",
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      color: "#023047",
    }),
  };
  const IndicatorSeparator = ({
    innerProps,
  }) => {
    return <span {...innerProps} />;
  };
  return (
    <div
      className={
        props.primaryCls
          ? "align_center Transparent__background"
          : "align_center Transparent__background"
      }
      // style={props.showUserMove ? { width: "230px" } : {}}
    >
      {props.showLabel ? (
        <label
          style={{
            color: props.location ? "#023047" : "#ffffff",
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18px",
            marginRight:props.showUserMove ? "20px":"0px",
          }}
        >
          {props.labelName}
        </label>
      ) : (
        ""
      )}
      {/* <select
        style={{
          width: "125px",
          height: "32px",
          border: "1px solid #023047",
          borderRadius: "4px",
          color: "#5E7D8C",
          fontSize: "12px",
          fontWeight: "400",
          paddingLeft: "5px",
          outline: "none",
        }}
        className="form-control"
        name="{props.labelName}"
        onChange={($event) => {
          console.log("Hello ", $event);
          props.onSearchChange($event);
        }}
        value={props.defaultValue || props.defaultAll}
      >
        <option
          className="select_options"
          selected
          disabled={!props.defaultAll}
        >
          {props.defaultAll ? props.defaultAll : "Select"}
        </option>
        {props.options.map((option) => (
          <option
            className="select_options"
            key={option.id}
            value={option.name}
          >
            {option.name}
          </option>
        ))}
      </select>
      <img src={DropdownIcon} alt="" className="dropdown_icon" /> */}
      <div>
        <Select
          className="custom_select_input"
          components={{ IndicatorSeparator }}
          styles={customStyles}
          options={props.options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          name="{props.labelName}"
          placeholder={props.defaultAll ? props.defaultAll : "Select"}
          onChange={($event) => {
            //console.log("Hello ", $event);
            props.onSearchChange($event.name);
          }}
          //InputValue={props.defaultValue || props.defaultAll}
          value={props.options.filter(
            opt => opt.name===(props.defaultValue || props.defaultAll)
          )}
        />
      </div>
    </div>
  );
};

export default CustomDropdown;
