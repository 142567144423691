/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
// import logoPrimary from "../../assets/images/kaido_logo_primary.svg";
// import logoHome from "../../assets/images/home_icon.svg";
// import logoUser from "../../assets/images/user_icon.svg";
// import logoProfile from "../../assets/images/profile_icon.svg";
// import logoHomeActive from "../../assets/images/home_icon_active.svg";
// import logoUserActive from "../../assets/images/user_icon_active.svg";

// logo
import logoSecondary from "../../assets/images/kaido_logo_secondary.svg";
// new menus
import homeMenu from "../../assets/images/sidebar_home.svg";
import usersMenu from "../../assets/images/sidebar_users.svg";
import profileMenu from "../../assets/images/sidebar_profile.svg";
import helpMenu from "../../assets/images/sidebar_help.svg";

import homeActive from "../../assets/images/sidebar_homeActive.svg";
import usersActive from "../../assets/images/sidebar_usersActive.svg";
import profileActive from "../../assets/images/sidebar_profileActive.svg";
import helpActive from "../../assets/images/sidebar_helpActive.svg";

import "./sideBar.scss";

let sidebarList = [
  {
    key: 1,
    name: "Home",
    icon: homeMenu,
    activeIcon: homeActive,
    classes: [],
    path: "/dashboard"
  },
  {
    key: 2,
    name: "Users",
    icon: usersMenu,
    activeIcon: usersActive,
    classes: [],
    path: "/users"
  },
  {
    key: 3,
    name: "Profile",
    icon: profileMenu,
    activeIcon: profileActive,
    classes: [],
    path: "/profile"
  },
  {
    key: 4,
    name: "Help",
    icon: helpMenu,
    activeIcon: helpActive,
    classes: [],
    path: "/help"
  }
];

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleRouteChange = (path) => {
    navigate(path);
  };
  return (
    <div className="sidebar_container">
      <div className="sidebar_header">
        <div className="logo_container">
          <img src={logoSecondary} alt="Kaido Logo Primary" />
        </div>
      </div>
      <div className="sidebar_content">
        <ul>
          {sidebarList.map((item, index) => {
            return (
              <div key={index}>
                <li onClick={() => handleRouteChange(item.path)}>
                  <a
                    className={
                      location.pathname.includes(item.path)
                        ? "sidebar_link_active"
                        : ""
                    }
                  >
                    <div className="sidebar_img_container">
                      <img
                      style={{width:"25px",height:"25px"}}
                        src={
                          location.pathname.includes(item.path)
                            ? item.activeIcon
                            : item.icon
                        }
                        alt="section_icon"
                      />
                    </div>
                    <div className="sidebar_menu_title">
                      <p> {item.name} </p>
                    </div>
                  </a>
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
