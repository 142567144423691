import React from "react";
import millify from "millify";
import { createUseStyles } from "react-jss";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import kaido_bullet_icon from "../../../assets/images/kaido_bullet_icon.svg";
import "./CampaignCards.scss";

const CampaignCards = (props) => {
  const navigate = useNavigate();
  let sent = "";

  let statusClass = "";
  let statusTitle = "";
  if (props.status === "EXPIRED") {
    statusTitle = "Expired";
    statusClass = "error-red";
  }
  if (props.status === "ACTIVE") {
    statusTitle = "Active";
    statusClass = "active-green";
  }
  if (props.status === "PAUSED") {
    statusTitle = "Paused";
    statusClass = "paused-yellow";
  }
  if (props.status === "PENDING") {
    statusTitle = "Pending";
    statusClass = "pending-warn";
  }

  if (props.giftSent < 10) {
    sent = "0" + props.giftSent;
  } else {
    sent = props.giftSent;
  }

  let progress;
  let val;
  let spent;
  let total = props.giftBudget;
  let remaining = props.remainingBudget;
  if(remaining<0){
    remaining=0;
  }
  spent = total - remaining;
  progress = (100 * remaining) / total;
  if(progress>100){
    progress=100;
  }else if(progress<0){
    progress=0;
  }
  val = `${progress.toFixed(1)}`;

  const styles = createUseStyles({
    budgetProgress: {
      height: "14px",
      left: "0px",
      top: "0px",
      width: `${val}%`,
      background: "#219ebc",
      borderRadius: "8px",
      color:(`${val}`<20) ? "black" :"white"
    }
  });

  const classes = styles();

  const handleCampaign = () => {
    props.setPage(1);
    navigate("/dashboard/campaign/" + props.id);
  };

  return (
    <div className="campaign_card_container" onClick={handleCampaign}>
      <div className="campaign_card_inner">
        <div className="campaign_card_top">
          <div className="campaign_card_top_container">
            <div className="campaign_card_top_container_inner">
              <div className="campaign_card_top_container_inner_left">
                <img src={kaido_bullet_icon} alt="Hey, Kaido logo" />
              </div>
              <div className="campaign_card_top_container_inner_right">
                <p className="campaign_card_title">{props.name}</p>
                <p className="campaign_card_sub_type_title">
                  {props.campaignSubType}
                </p>
                <p className={`campaign_card_sub_type ${statusClass}`}>
                  {statusTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="campaign_card_bottom">
          <div className="campaign_card_bottom_container_top">
            <div className="campaign_card_gifts_sent">
              <div className="campaign_cards_gift_sent_text">Gift Sent</div>
              <div className="campaign_cards_gift_sent_num">{sent}</div>
            </div>
            <div className="campaign_card_gifts_delivered">
              <div className="campaign_cards_gift_delivered_text">
                Delivered
              </div>
              <div className="campaign_cards_gift_delivered_number">
                <div className="campaign_cards_gift_delivered_indicator"></div>
                {props.giftDelivered}
              </div>
            </div>
          </div>
          <div className="campaign_card_bottom_container_bottom">
            <div className="campaign_card_most_popular_container">
              <h2 className="campaign_card_most_popular_header">
                Most Popular Gift
              </h2>
              <p className="campaign_card_most_popular_title">
                {props.popularGift === null ? "" : props.popularGift}
              </p>
            </div>
            <div className="campaign_card_budget_container">
              <div className="campaign_card_budget_header">Remaining Budget</div>
              <div className="campaign_card_budget_progress_bar_container">
                <ProgressBar
                  completed={val+"%"}
                  completedClassName="barCompleted"
                  labelClassName="campaign_card_budget_label"
                  className="campaign_card_budget_progress_bar"
                  barContainerClassName={classes.budgetProgress}
                />
                <div className="campaign_card_total_budget">
                  Total Budget: ${(props.giftBudget.toLocaleString("en-US"))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCards;
