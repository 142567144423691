import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  from: "",
  subjectLine: "",
  subjectLineReminder: "",
  
  emailHeader: "Your Header Here",
  btnText: "Tap to unwrap",
  personalizeLogo: [],
  personalizeAnimation: [],
  fontFamily: [],
  fontWeight: [],
  cardLayoutTemplate: [],
  color: "#219ebc",
  headerColor: "#000",
  btnTextColor: "#fff",
};

const personalizeEmailSlice = createSlice({
  name: "personalizeEmail",
  initialState,
  reducers: {
    addFrom: (state, action) => {
      const newFrom = action.payload;
      state.from = newFrom;
    },
    addSubjectLine: (state, action) => {
      const newSubjectLine = action.payload;
      state.subjectLine = newSubjectLine;
    },
    addSubjectLineReminder: (state, action) => {
      const newSubjectLineReminder = action.payload;
      state.subjectLineReminder = newSubjectLineReminder;
    },

    addColor: (state, action) => {
      state.color = action.payload;
    },

    addHeaderColor: (state, action) => {
      state.headerColor =  action.payload
    },

    addBtnTextColor: (state, action) => {
      state.btnTextColor =  action.payload
    },

    // ********** //

    addEmailHeader: (state, action) => {
      const newHeader = action.payload;
      state.emailHeader = newHeader;
    },
    addBtnText: (state, action) => {
      state.btnText = action.payload;
    },
    addLogo: (state, action) => {
      const newLogo = action.payload;
      state.personalizeLogo = newLogo;
    },
    addAnimation: (state, action) => {
      const newAnimation = action.payload;
      state.personalizeAnimation = newAnimation;
    },
    removeLogo: (state, action) => {
      state.personalizeLogo = null;
    },
    removeAnimation: (state, action) => {
      state.personalizeAnimation = [];
    },
    addFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    },
    addFontWeight: (state, action) => {
      state.fontWeight = action.payload;
    },
    addCardLayoutTemplate: (state, action) => {
      state.cardLayoutTemplate = action.payload;
    },
    clearPersonalizeEmail: () => initialState
  }
});

export const {
  addColor,
  addHeaderColor,
  addBtnTextColor,

  addFrom,
  addSubjectLine,
  addEmailHeader,
  addSubjectLineReminder,
  addBtnText,
  addLogo,
  addAnimation,
  removeLogo,
  addFontFamily,
  addFontWeight,
  addCardLayoutTemplate,
  clearPersonalizeEmail
} = personalizeEmailSlice.actions;
export default personalizeEmailSlice.reducer;
