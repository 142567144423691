import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import axios from "../../../api_conf/BaseUrl";

import {
  handleGiftsData,
  getGiftStatus,
  changeFilter,
  handleValueTag,
  handleCountryTag,
  handleCollectionTag,
  handleGiftTypeTag,
  fetchCollections
} from "../../../store/giftSlice";
import CurateGiftFilter from "./CurateGiftFilter";
import FilterCard from "./FilterCard";
import GiftCuration from "./GiftCuration";
import GiftLoader from "../custom/GiftLoader";
import SearchIcon from "../../../assets/images/search_secondary.svg";

import "./CurateGift.scss"

const CurateGift = ({ page, FormTitles, setShowGifts, showEditCampaign }) => {
  const dispatch = useDispatch();
  const giftsData = useSelector((state) => state.gifts.giftsData);
  const giftAdded = useSelector((state) => state.gifts.giftAdded);
  const filtersTag = useSelector((state) => state.gifts.filtersTag);
  const valuesTag = useSelector((state) => state.gifts.valuesTag);
  const countryTag = useSelector((state) => state.gifts.countryTag);
  const collectionsTag = useSelector((state) => state.gifts.collectionsTag);
  const giftTypeTag = useSelector((state) => state.gifts.giftTypeTag);

  const [productLoading, setProductLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 600);

  const status = useSelector(getGiftStatus);

  const showIcon = {
    display: search.length > 0 && "none"
  };

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCollections());
    }
  }, [status, dispatch]);


  const clearAllTags = () => {
    dispatch(changeFilter([]));
    dispatch(handleValueTag([]));
    dispatch(handleCountryTag([]));
    dispatch(handleCollectionTag([]));
    dispatch(handleGiftTypeTag([]));
  };

  const removeFilters = (item) => {
    let removeFilters = filtersTag;
    removeFilters = removeFilters.filter((currElem) => currElem.id !== item.id);
    dispatch(changeFilter([...removeFilters]));
  };

  const removeValuesFilters = (item) => {
    let removeFilters = valuesTag;
    removeFilters = removeFilters.filter((currElem) => currElem.id !== item.id);
    dispatch(handleValueTag([...removeFilters]));
  };

  const removeCountryFilters = (item) => {
    let removeFilters = countryTag;
    removeFilters = removeFilters.filter((currElem) => currElem.id !== item.id);
    dispatch(handleCountryTag([...removeFilters]));
  };

  const removeCollectionFilters = (item) => {
    let removeFilters = collectionsTag;
    removeFilters = removeFilters.filter((currElem) => currElem.id !== item.id);
    dispatch(handleCollectionTag([...removeFilters]));
  };

  const removeGiftTypeFilters = (item) => {
    let removeFilters = giftTypeTag;
    removeFilters = removeFilters.filter((currElem) => currElem.id !== item.id);
    dispatch(handleGiftTypeTag([...removeFilters]));
  };

  const filter = {
    search_string: !!value ? [value] : [],
    search_countries:
      countryTag.length > 0 ? countryTag.map((el) => el.condition) : [],
    search_values:
      valuesTag.length > 0 ? valuesTag.map((el) => el.condition) : [],
    search_price: filtersTag.length > 0 ? filtersTag[0].condition : [],
    gift_type: giftTypeTag.length > 0 ? giftTypeTag[0].condition : "",
    collection_id: collectionsTag.length > 0 ? collectionsTag[0].id : ""
  };

  const productsAPI = async () => {
    setProductLoading(true);
    try {
      const res = await axios.post("products/", filter);

      if (res?.data?.success) {
        const giftLoaded = res.data.response.products.map((gift, index) => {
          gift.idx = index;
          return gift;
        });

        dispatch(handleGiftsData(giftLoaded));
        setProductLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");

      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    productsAPI();
  }, [value, countryTag, filtersTag, valuesTag, giftTypeTag, collectionsTag]);

  return (
    <React.Fragment>
      <div className="curate_gift">
        <div className="curate_gift_container">
          <div className="curate_gift_sidebar">
            <div className="curate_gift_sidebar_title">
              <h5>{FormTitles[page]}</h5>
            </div>
            <div className="curate_gift_filter_container">
              <CurateGiftFilter productLoading={productLoading} />
            </div>
          </div>
          <div className="curate_gift_main_container">
            <div className="curate_gift_top_container">
              <div className="curate_search_container">
                <img
                  src={SearchIcon}
                  alt="Search Gifts"
                  className="curate_search_icon"
                  style={showIcon}
                />
                <input
                  type="search"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  className="curate_gift_search"
                />
              </div>
              <div className="curate_gift_tags_filter_title">
                <p>Filter By:</p>
              </div>
              <div className="curate_gift_tags_container">
                <div
                  className="curate_gift_tags"
                  style={{ pointerEvents: productLoading && "none" }}
                >
                  {filtersTag?.map((item, id) => (
                    <div key={id} className="filter_tag">
                      <div className="inner_filter_tag">
                        <span>{item.name}</span>
                        <button onClick={() => removeFilters(item)}>X</button>
                      </div>
                    </div>
                  ))}
                  {valuesTag?.map((item, id) => (
                    <div key={id} className="filter_tag">
                      <div className="inner_filter_tag">
                        <span>{item.name}</span>
                        <button onClick={() => removeValuesFilters(item)}>
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                  {countryTag?.map((item, id) => (
                    <div key={id} className="filter_tag">
                      <div className="inner_filter_tag">
                        <span>{item.name}</span>
                        <button onClick={() => removeCountryFilters(item)}>
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                  {collectionsTag?.map((item, id) => (
                    <div key={id} className="filter_tag">
                      <div className="inner_filter_tag">
                        <span>{item.name}</span>
                        <button onClick={() => removeCollectionFilters(item)}>
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                  {giftTypeTag?.map((item, id) => (
                    <div key={id} className="filter_tag">
                      <div className="inner_filter_tag">
                        <span>{item.name}</span>
                        <button onClick={() => removeGiftTypeFilters(item)}>
                          X
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="curate_gift_button"
                  style={{ pointerEvents: productLoading && "none" }}
                >
                  {filtersTag?.length > 0 ||
                  valuesTag.length > 0 ||
                  countryTag.length > 0 ||
                  collectionsTag.length > 0 ||
                  giftTypeTag.length > 0 ? (
                    <button onClick={clearAllTags}>Clear All</button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="curate_filter_container">
              {productLoading ? (
                <GiftLoader />
              ) : giftsData.length > 0 ? (
                <React.Fragment>
                  {giftsData?.map((item) => (
                    <FilterCard
                      key={item.id}
                      {...item}
                      setSearch={setSearch}
                      setShowGifts={setShowGifts}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <div className="curate_filter_data_not_found">
                  <p>No data found</p>
                </div>
              )}
            </div>
            <div className="gift_curation_wrapper">
              <p>Your Curation:</p>
              <div className="gift_curation">
                {giftAdded?.map((item) => (
                  <GiftCuration
                    key={item.id}
                    item={item}
                    setSearch={setSearch}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="curate_gifts_selected"></div>
    </React.Fragment>
  );
};

export default CurateGift;
