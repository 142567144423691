import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  backgroundImages: [],
  backgroundImage: [],

  backgroundColor: "#eee",
  envelopeColor: "#e75753",
  message: "Congratulations",
  cardFontFamily: "DM Sans, sans-serif",
  textSize: 18,
  alignment: "left",
  textColor: "#000",
  letterCasing: "",
  lineHeight: 1.2,
  letterSpacing: 1,

  card: [],
  newCard: [],
  showMessageSettings: false,
  dropdownSettings: false,
  showInfo: false,
  designLayoutTemplate: [],
  logoSelected: false,
  envelopeColorPicker: false,
  backgroundColorPicker: false,
  textColorPicker: false,
  backgroundDominantColor: "",
  previewLogo: []
};

const designCardSlice = createSlice({
  name: "designCard",
  initialState,
  reducers: {
    addPreviewLogo: (state, action) => {
      state.previewLogo = action.payload;
    },
    handlebackDomColor: (state, action) => {
      state.backgroundDominantColor = action.payload;
    },
    showEnvelopeColorPicker: (state, action) => {
      return {
        ...state,
        envelopeColorPicker: true
      };
    },
    hideEnvelopeColorPicker: (state, action) => {
      return {
        ...state,
        envelopeColorPicker: false
      };
    },
    showBackgroundColorPicker: (state, action) => {
      return {
        ...state,
        backgroundColorPicker: true
      };
    },
    hideBackgroundColorPicker: (state, action) => {
      return {
        ...state,
        backgroundColorPicker: false
      };
    },

    showTextColorPicker: (state, action) => {
      return {
        ...state,
        textColorPicker: true
      };
    },
    hideTextColorPicker: (state, action) => {
      return {
        ...state,
        textColorPicker: false
      };
    },

    addBackgroundImages: (state, action) => {
      const newImage = action.payload;
      state.backgroundImages = newImage;
    },
    addBackgroundImage: (state, action) => {
      const newImage = action.payload;
      state.backgroundImage = newImage;
    },
    addMessage: (state, action) => {
      state.message = action.payload;
    },

    addTextColor: (state, action) => {
      state.textColor = action.payload;
    },
    addEnvelopeColor: (state, action) => {
      state.envelopeColor = action.payload;
    },
    addBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    addCard: (state, action) => {
      state.card = action.payload;
    },
    addNewCard: (state, action) => {
      state.newCard = action.payload;
    },
    toggleInfo: (state, action) => {
      state.showInfo = !state.showInfo;
    },
    showToggleInfo: (state, action) => {
      return {
        ...state,
        showInfo: true
      };
    },
    hideToggleInfo: (state, action) => {
      return {
        ...state,
        showInfo: false
      };
    },
    addCardFontFamily: (state, action) => {
      state.cardFontFamily = action.payload;
    },

    increaseTextSize: (state, action) => {
      state.textSize += 1;
    },
    decreaseTextSize: (state, action) => {
      state.textSize -= 1;
    },
    changeTextSize: (state, action) => {
      state.textSize = parseInt(action.payload);
    },
    increaseLetterSpacing: (state, action) => {
      state.letterSpacing += 0.1;
    },
    decreaseLetterSpacing: (state, action) => {
      state.letterSpacing -= 0.1;
    },
    changeLetterSpacing: (state, action) => {
      state.letterSpacing = parseFloat(action.payload);
    },
    increaseLineHeight: (state, action) => {
      state.lineHeight += 0.1;
    },
    decreaseLineHeight: (state, action) => {
      state.lineHeight -= 0.1;
    },
    changeLineHeight: (state, action) => {
      state.lineHeight = parseFloat(action.payload);
    },
    setLineHeight: (state, action) => {
      state.lineHeight = action.payload;
    },
    changeAlignment: (state, action) => {
      state.alignment = action.payload;
    },
    changeLetterCasing: (state, action) => {
      state.letterCasing = action.payload;
    },
    addDesignLayoutTemplate: (state, action) => {
      state.designLayoutTemplate = action.payload;
    },

    resetSettings: (state, action) => {
      state.textSize = 18;
      state.alignment = "left";
      state.lineHeight = 1;
      state.letterSpacing = 1;
      state.letterCasing = "";
    },
    showSettings: (state, action) => {
      return {
        ...state,
        showMessageSettings: true
      };
    },
    showDropdownSettings: (state, action) => {
      state.dropdownSettings = !state.dropdownSettings;
    },
    hideSettings: (state, action) => {
      return {
        ...state,
        showMessageSettings: false
      };
    },

    toggleLogo: (state, action) => {
      state.logoSelected = !state.logoSelected;
    },
    enableLogo: (state, action) => {
      return {
        ...state,
        logoSelected: true
      };
    },
    disableLogo: (state, action) => {
      return {
        ...state,
        logoSelected: false
      };
    },
    selectLogo: (state, action) => {
      state.logoSelected = !state.logoSelected;
    },
    clearDesignCard: () => initialState
  }
});

export const {
  showTextColorPicker,
  hideTextColorPicker,
  showEnvelopeColorPicker,
  hideEnvelopeColorPicker,
  showBackgroundColorPicker,
  hideBackgroundColorPicker,
  addBackgroundColor,
  handlebackDomColor,
  toggleLogo,
  enableLogo,
  disableLogo,
  addPreviewLogo,

  addBackgroundImages,
  addBackgroundImage,
  addMessage,
  addTextColor,
  addEnvelopeColor,
  addCard,
  addNewCard,
  toggleInfo,
  showToggleInfo,
  hideToggleInfo,
  addCardFontFamily,
  increaseTextSize,
  decreaseTextSize,
  changeTextSize,
  increaseLetterSpacing,
  decreaseLetterSpacing,
  changeLetterSpacing,
  increaseLineHeight,
  decreaseLineHeight,
  changeLineHeight,
  setLineHeight,
  changeAlignment,
  changeLetterCasing,
  addDesignLayoutTemplate,
  resetSettings,
  clearDesignCard,
  selectLogo,
  showSettings,
  hideSettings,
  showDropdownSettings
} = designCardSlice.actions;
export default designCardSlice.reducer;
