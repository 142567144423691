import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { fontFam, messageColors } from "./DesignCardUtil/CardMessageUtil";
import SettingsIcon from "../../../assets/images/message_settings.svg";
import AddIcon from "../../../assets/images/outbound_template_add_circle.svg";

import {
  addTextColor,
  addCardFontFamily,
  showSettings,
  showTextColorPicker
} from "../../../store/designCardSlice";
import insertTextAtCursor from 'insert-text-at-cursor';
const CardMessage = ({ cardMessage, setCardMessage, messageRef }) => {
  const dispatch = useDispatch();
  const textColor = useSelector((state) => state.designCard.textColor);

  const handleToggleSettings = () => {
    dispatch(showSettings());
  };

  const handleTextColor = (item) => {
    dispatch(addTextColor(item.color));
  };

  const handleFontFamily = (item) => {
    dispatch(addCardFontFamily(item.font));
  };

  const handleShowColorPicker = () => {
    dispatch(showTextColorPicker());
  };

  const handleMessageUsername = () => {
    setCardMessage({
      ...cardMessage,
      message: messageRef?.current?.value.concat("{user_first_name}")
    });
    const el = document.getElementById('message');
    insertTextAtCursor(el,"{user_first_name}")
    //console.log(cardMessage);
  };

  return (
    <div className="design_card_message_container">
      <div className="design_card_toggle_settings_container">
        <div className="cm_add_first_name_wrapper">
          <div
            className="cm_add_first_name_container"
            onClick={handleMessageUsername}
          >
            <img src={AddIcon} alt="Add {user_first_name}" />
            <button className="cm_add_first_name">Add First Name</button>
          </div>
        </div>
        <div
          className="design_card_toggle_settings"
          onClick={handleToggleSettings}
        >
          <img src={SettingsIcon} alt="Toggle Settings" />
        </div>
      </div>
      <div className="design_card_message_font_family_container">
        {fontFam.map((currElem) => (
          <div
            className="design_card_message_font_family"
            key={currElem.id}
            onClick={() => handleFontFamily(currElem)}
          >
            <p
              className="design_card_message_font_family_title"
              style={{ fontFamily: currElem.font }}
            >
              {currElem.name}
            </p>
          </div>
        ))}
      </div>
      <div className="design_card_message_colors_container">
        {messageColors.map((currElem) => {
          return (
            <div
              className="design_card_message_colors"
              style={{ backgroundColor: currElem.color }}
              onClick={() => handleTextColor(currElem)}
            >
              {" "}
            </div>
          );
        })}
      </div>
      <div className="choose_text_color_picker">
        Color:{" "}
        <span
          style={{ backgroundColor: textColor }}
          onClick={handleShowColorPicker}
        ></span>
      </div>
    </div>
  );
};

export default CardMessage;
