import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import axios from "../../api_conf/BaseUrl";
import CloseOutboundTemplate from "../../assets/images/close_update_form.svg";

function TemplateEditor({ id, templateId, closeEditor }) {
  const [editorTemplateSubject, setEditorTemplateSubject] = useState("");
  const [editorTemplateReminder, setEditorTemplateReminder] = useState("");
  const [editorSenderName, setEditorSenderName] = useState("");


  const handleOutBoundTemplete = async () => {
    try {
      let res = await axios.get("templatebycampaign/", {
        params: {
          campaign_id: id
        }
      });

      if (res.data.success) {
        setEditorTemplateSubject(res?.data?.template_subject);
        setEditorTemplateReminder(res?.data?.template_subject_for_reminder);
        setEditorSenderName(res?.data?.sender_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      handleOutBoundTemplete(id);
    }
  }, [id]);

  const handleEditOutboundTemplate = async (val) => {
    try {
      let res = await axios.put("templatebycampaign/", {
        id: val,
        subject: editorTemplateSubject,
        subject_for_reminder: editorTemplateReminder,
        sender_name: editorSenderName,
        email_header: null,
        animation_id: null,
        message: null,
        html_template: null,
        email_logo_id: null,
        button_text: null,
        BtnColor: null,
        HeaderColor: null,
        TextColor: null,
      });

      if (res.data.success) {
        toast.success("Template edited successfully!");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  return (
    <div>
      <div className="editor_close_container">
        <button
          className="editor_close_btn"
          onClick={() => {
            closeEditor();
          }}
        >
          <img
            className="editor_close_icon"
            src={CloseOutboundTemplate}
            alt="Close editor"
          />
        </button>
      </div>
      <div className="editor_title">
        <h5>Add outbound template</h5>
      </div>
      <div className="scroll_content">
        <div className="input_group">
          <div className="input_control">
            <label htmlFor="senderName">Sender Name</label>
            <input
              id="senderName"
              name="senderName"
              value={editorSenderName}
              onChange={(e) => setEditorSenderName(e.target.value)}
              type="text"
              placeholder="Enter sender name"
              required
            />
            <p
              style={{
                display: "flex",
                fontSize: "0.9rem",
                color: "#646968",
                marginTop: "2px"
              }}
            >
              Please avoid using special characters
            </p>
          </div>
          <div className="inner_group">
            <div className="input_control">
              <label htmlFor="templateSubject">Subject Line</label>
              <input
                id="templateSubject"
                name="templateSubject"
                value={editorTemplateSubject}
                onChange={(e) => setEditorTemplateSubject(e.target.value)}
                type="text"
                placeholder="Enter Subject Line"
                required
              />
              <p
                style={{
                  display: "flex",
                  fontSize: "0.9rem",
                  color: "#646968",
                  marginTop: "2px"
                }}
              >
                Feel free to use {`{user_first_name}`} as a dynamic tag
              </p>
            </div>
            <div className="input_control">
              <label htmlFor="templateReminder">
                Subject Line For Reminder
              </label>
              <input
                id="templateReminder"
                name="templateReminder"
                value={editorTemplateReminder}
                onChange={(e) => setEditorTemplateReminder(e.target.value)}
                type="text"
                placeholder="Enter Subject Line For Reminder"
                required
              />
              <p
                style={{
                  display: "flex",
                  fontSize: "0.9rem",
                  color: "#646968",
                  marginTop: "2px"
                }}
              >
                Feel free to use {`{user_first_name}`} as a dynamic tag
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="editor_footer">
        <button
          onClick={() => {
            closeEditor();
          }}
        >
          Close
        </button>

        <button
          onClick={() => {
            handleEditOutboundTemplate(templateId);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default TemplateEditor;
